import React, { useMemo } from "react";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { Box } from "@material-ui/core";
import { ISearchEntityProps } from "core/interfaces/search-entity-props.interface";
import { getStudentFullName, IStudent } from "core/models/student/student.model";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useEntitySearch } from "core/hooks/use-entity-search.hook";
import { EntityType } from "core/enums/entity-type.enum";
import { Link, useLocation } from "react-router-dom";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { getLessonName } from "core/models/lesson/lesson.model";
import { ILesson } from "core/models/lesson/lesson.model";
import { getThemeName } from "core/models/theme/theme.model";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { IInstructor } from "core/models/instructor/instructor.model";

export type ISearchEntity = IStudent | ILesson | IInstructor;

export interface IEntitySearchResultsProps
    extends Pick<ISearchEntityProps<ISearchEntity>, "queryKey" | "queryFunction" | "type"> {
    searchQuery: string;
    loadingIndicator: string;
}

export const EntitySearchResults = ({
    searchQuery,
    queryFunction,
    queryKey,
    type,
    loadingIndicator,
}: IEntitySearchResultsProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const locationState: ILocationState = useMemo(() => ({
        from: { pathname },
    }), [pathname]);

    const {
        data,
        isLoading,
    } = useEntitySearch<ISearchEntity>({
        searchParams: {
            query: searchQuery,
        },
        queryFunction,
        queryKey,
        enabled: searchQuery !== "",
    });

    return (
        <div className={styles.searchResultsWrapper}>
            <div className={styles.searchResults}>
                {isLoading ? (
                    <Text>{loadingIndicator}</Text>
                ) : data?.items?.length > 0 ? (
                    <>
                        {data.items.map((item) => (
                            <div
                                key={item.id}
                                className={styles.searchResultsItem}
                            >
                                {renderEntityContent(item, type, locationState)}
                            </div>
                        ))}
                    </>
                ) : (
                    <Text>{t("no-data-available")}</Text>
                )}
            </div>
            <div className={styles.overlay} />
        </div>
    );
}

const renderEntityContent = (
    entity: ISearchEntity,
    type: EntityType,
    locationState: ILocationState,
) => {
    if (type === EntityType.STUDENT) {
        const student: IStudent = entity as IStudent;

        return (
            <Link
                to={{
                    pathname: pathBuilder(
                        ROUTES.STUDENT_PROFILE,
                        ":studentId",
                        student.id,
                    ),
                    state: locationState,
                }}
            >
                <Text>{getStudentFullName(student)}</Text>
            </Link>
        );
    }

    if (type === EntityType.LESSON) {
        const lesson: ILesson = entity as ILesson;

        return (
            <Link
                to={{
                    pathname: pathBuilder(
                        ROUTES.THEME_LESSON_DETAILS,
                        ":themeId/:lessonId",
                        `${lesson.theme.id}/${lesson.id}`
                    ),
                    state: locationState,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="4px"
                >
                    <Text>
                        {getLessonName(lesson.translations).title}
                    </Text>
                    <Text size="small" color="gray">
                        {lesson.theme
                            ? getThemeName(lesson.theme.translations)
                            : ""
                        }
                    </Text>
                </Box>
            </Link>
        );
    }

    if (type === EntityType.INSTRUCTOR) {
        const instructor: IInstructor = entity as IInstructor;

        return (
            <Link
                to={{
                    pathname: pathBuilder(
                        ROUTES.DRIVING_SCHOOL,
                        ":drivingSchoolId",
                        instructor.id,
                    ),
                    state: locationState,
                }}
            >
                <Text>{instructor.drivingSchool.name}</Text>
            </Link>
        );
    }

    return <></>;
}
