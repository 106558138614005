export interface IStudentAccountsCreated {
    accounts: number;
    month: string;
    year: string;
    week: string;
    startOfWeek: string;
}

export const createStudentAccountsCreated = (props: Partial<IStudentAccountsCreated>) => {
    return {
        accounts: null,
        month: "",
        year: "",
        week: "",
        startOfWeek: "",
        ...props,
    } as IStudentAccountsCreated;
}
