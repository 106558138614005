import { useContext } from "react";
import { SnackbarContext } from "core/context/SnackbarProvider";

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error("SnackbarContext must be within SnackbarProvider");
    }

    return context;
};
