import {
    IInstructorBillingInformation,
    createInstructorBillingInformation,
} from "./instructor-billing-information.model";

export interface IInstructorBillingInformationResponse {
    address: string;
    city: string;
    country: string;
    zip_code: number;
}

export const createInstructorBillingInformationFromResponse = ({
    zip_code,
    ...props
}: IInstructorBillingInformationResponse): IInstructorBillingInformation => {
    return createInstructorBillingInformation({
        zipCode: zip_code,
        ...props,
    });
};
