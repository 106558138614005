import { createThemeFromResponse, IThemeResponse } from "../theme/theme.response";
import { ILessonTranslation, ITranslations } from "../translation/translation.model";
import { createUserProgressFromResponse } from "../user/user-progress.response";
import { createQuestionFromResponse, IQuestionResponse } from "./lesson-question.response";
import { createTagFromResponse, ITagResponse } from "./lesson-tag.response";
import { createLesson, ILesson } from "./lesson.model";

export interface ILessonResponse extends Omit<
    IThemeResponse,
    "included_in_trial_version_only"
    | "number_of_lessons"
    | "translations"
    | "media"
> {
    media_duration: number;
    number_of_questions: number;
    translations: ITranslations<ILessonTranslation>;
    screen: string;
    theme?: IThemeResponse;
    questions?: Array<IQuestionResponse>;
    tags?: Array<ITagResponse>;
    total_questions?: number;
    video?: string;
}

export const createLessonFromResponse = ({
    id,
    media_duration,
    number_of_questions,
    user_progress,
    theme,
    questions,
    tags,
    total_questions,
    screen,
    ...props
}: ILessonResponse): ILesson => {
    return createLesson({
        id: id?.toString(),
        mediaDuration: media_duration,
        numberOfQuestions: number_of_questions,
        userProgress: user_progress ? createUserProgressFromResponse(user_progress) : null,
        theme: theme ? createThemeFromResponse(theme) : null,
        questions: questions?.map(createQuestionFromResponse) ?? [],
        tags: tags?.map(createTagFromResponse) ?? [],
        totalQuestions: total_questions,
        media: screen,
        ...props,
    });
};
