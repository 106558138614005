import React, { useMemo } from "react";
import Text, { TextColor } from "core/components/Text";
import { ButtonType } from "core/enums/button-type.enum";
import styles from "./styles.module.scss";
import { classNameFormatter } from "core/utils/css-classname-formatter";

export interface IButtonProps {
    label: string;
    onClick?: () => void;
    type?: ButtonType;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    textColor?: TextColor;
}

export function Button({
    label,
    onClick,
    type = ButtonType.BUTTON,
    disabled,
    style,
    className,
    textColor,
}: IButtonProps) {
    const defaultButtonStyles = useMemo(() => ({
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.5 : 1,
    }), [disabled]);

    return type === ButtonType.TEXT ? (
        <Text
            color={textColor || "black"}
            onClick={() => {
                if (disabled) return;
                onClick && onClick();
            }}
            style={{
                ...defaultButtonStyles,
                ...style,
                userSelect: "none",
            }}
            className={className}
        >
            {label}
        </Text>
    ) : (
        <button
            type={type}
            disabled={disabled}
            onClick={() => {
                onClick && onClick();
            }}
            className={classNameFormatter([styles.buttonContainer, className])}
            style={{
                ...defaultButtonStyles,
                ...style,
            }}
        >
            <Text color={textColor || "white"}>
                {label}
            </Text>
        </button>
    );
}
