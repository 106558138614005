import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import styles from "./styles.module.scss";

interface IProps {
    activeStudents: number;
}

export const StudentAccountsCreatedCard = ({
    activeStudents,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="grid"
            flexDirection="column"
            justifyContent="space-between"
            gap={1}
            mr="32px"
            className={styles.cardContainer}
        >
            <Box
                display="grid"
                flexDirection="column"
                gap={1}
            >
                <Text color="white" size="medium" bold>
                    {t("student-accounts-created")}
                </Text>
                <Text color={null} className={styles.cardSubTitle}>
                    {t("currently-active-students")}
                </Text>
            </Box>
            <Text color="white" size="large">
                {activeStudents}
            </Text>
        </Box>
    );
}
