import React, { FC } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";

export const StyledSwitch: FC = ({ children }) => {
    const theme = React.useMemo(() => {
        return createTheme({
            components: {
                MuiSwitch: {
                    styleOverrides: {
                        root: {
                            marginLeft: "-10px",
                        },
                        thumb: {
                            boxShadow: `0px 0px 2px 1px ${RGBAColors.grayWithOpacity(0.3)}`,
                            backgroundColor: `${colors.white} !important`,
                        },
                        track: {
                            boxShadow: `inset 0 1px 3px 0 ${RGBAColors.blackWithOpacity(0.5)}`,
                            backgroundColor: `${RGBAColors.grayWithOpacity(0.12)} !important`,
                        },
                        switchBase: {
                            "&:hover": {
                                backgroundColor: "transparent !important",
                            },
                            "&.Mui-checked": {
                                "& + .MuiSwitch-track": {
                                    backgroundColor: `${colors.green} !important`,
                                },
                            },
                        },
                    },
                }
            },
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
