import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Box } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import FormWrapper from "core/components/FormWrapper";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { ROUTES } from "modules/home/enums/routes.enum";
import { pathBuilder } from "core/utils/path-builder";
import { useInstructor } from "../../hooks/use-instructor.hook";
import { useEditInstructor } from "../../hooks/use-edit-instructor.hook";
import Text from "core/components/Text";
import { defaultEditInstructorFormValues, IEditInstructorFormModel } from "../../models/edit-instructor-form.model";
import { useEditInstructorFormValidationSchema } from "../../hooks/use-edit-instructor-form-validation-schema.hook";

export const EditDrivingSchool = () => {
    const { t } = useTranslation();
    const { state } = useLocation<ILocationState>();
    const { drivingSchoolId } = useParams<{ drivingSchoolId: string }>();

    const editInstructorFormValidationSchema = useEditInstructorFormValidationSchema();
    const {
        control,
        setValue,
        handleSubmit,
        formState: { isValid },
    } = useForm<IEditInstructorFormModel>({
        mode: "onChange",
        resolver: yupResolver(editInstructorFormValidationSchema),
        defaultValues: defaultEditInstructorFormValues,
    });

    const {
        data: instructor,
        isLoading: isInstructorLoading,
    } = useInstructor({ instructorId: drivingSchoolId });

    useEffect(() => {
        if (!instructor || isInstructorLoading) return;

        setValue("driving_school_name", instructor.drivingSchool?.name ?? "");
        setValue("address", instructor.drivingSchool?.address ?? "");
        setValue("zip_code", instructor.drivingSchool?.postalCode ?? "");
        setValue("city", instructor.drivingSchool?.city ?? "");
        setValue("phone", instructor.drivingSchool?.phone ?? "");
        setValue("email", instructor.email);
    }, [instructor, isInstructorLoading]);

    const {
        editInstructor,
        isLoading,
        serverErrorMessage,
    } = useEditInstructor({ instructorId: drivingSchoolId });

    const onSubmit = async (data: IEditInstructorFormModel) => editInstructor(data);

    return (
        <ContentContainer>
            <FormWrapper
                title={t("edit-entity", { entityName: t("driving-school") })}
                backToPath={(state?.from?.pathname ?? pathBuilder(
                    ROUTES.DRIVING_SCHOOL,
                    ":drivingSchoolId",
                    drivingSchoolId,
                )) as ROUTES}
                submitButtonProps={{
                    label: t("save-changes"),
                    onClick: handleSubmit(onSubmit),
                    disabled: !isValid || isLoading,
                    isLoading: isLoading || isInstructorLoading,
                }}
                error={serverErrorMessage}
            >
                <Box display="grid" flexDirection="column" gap={2}>
                    <Controller
                        name="driving_school_name"
                        control={control}
                        render={({ field: { ref,...restFieldProps }, fieldState: { error } }) => (
                            <FormInput<IEditInstructorFormModel>
                                inputRef={ref}
                                label={t("driving-school-name")}
                                type={FormInputType.TEXT}
                                placeholder={t("driving-school-name-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="address"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<IEditInstructorFormModel>
                                inputRef={ref}
                                label={t("address")}
                                type={FormInputType.TEXT}
                                placeholder={t("address-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        <Controller
                            name="zip_code"
                            control={control}
                            render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                                <FormInput<IEditInstructorFormModel>
                                    inputRef={ref}
                                    label={t("zip-code")}
                                    type={FormInputType.NUMBER}
                                    placeholder={t("zip-code-placeholder")}
                                    error={error?.message || !!serverErrorMessage}
                                    style={{flex: 1, minWidth: "150px", marginRight: "32px" }}
                                    {...restFieldProps}
                                />
                            )}
                        />
                        <Controller
                            name="city"
                            control={control}
                            render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                                <FormInput<IEditInstructorFormModel>
                                    inputRef={ref}
                                    label={t("city")}
                                    type={FormInputType.TEXT}
                                    placeholder={t("city-placeholder")}
                                    error={error?.message || !!serverErrorMessage}
                                    style={{flex: 1, minWidth: "150px"}}
                                    {...restFieldProps}
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        name="phone"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<IEditInstructorFormModel>
                                inputRef={ref}
                                label={t("phone-number")}
                                type={FormInputType.TEXT}
                                placeholder={t("phone-number-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({field: { ref, ...restFieldProps }}) => (
                            <FormInput<IEditInstructorFormModel>
                                inputRef={ref}
                                label={t("email-address")}
                                type={FormInputType.TEXT}
                                placeholder={t("email-address-placeholder")}
                                error={!!serverErrorMessage}
                                readOnly
                                {...restFieldProps}
                            />
                        )}
                    />
                    {instructor?.code && (
                        <Text size="small">
                            {`ID: ${instructor.code}`}
                        </Text>
                    )}
                </Box>
            </FormWrapper>
        </ContentContainer>
    );
}
