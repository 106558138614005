import React from "react";
import Box from "@material-ui/core/Box";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import styles from "./styles.module.scss";

interface IProps {
    isPartner: boolean;
}

export const StudentStatus = ({ isPartner }: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classNameFormatter([
                styles.studentStatusContainer,
                isPartner ? styles.active : styles.inactive,
            ])}
        >
            <Text
                color={isPartner ? "green" : "red"}
                tooltip={isPartner ? t("partner") : t("non-partner")}
                truncate
                style={{
                    lineHeight: "16px",
                    whiteSpace: "pre-wrap",
                }}
            >
                {isPartner ? t("partner") : t("non-partner")}
            </Text>
        </Box>
    );
}
