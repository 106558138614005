import { useLayoutEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { SHARED_PROGRESS_PATH } from "../enums/app-routes.enum";

export const useIsSharedRoute = () => {
    const { pathname } = useLocation();
    const [isSharedRoute, setIsSharedRoute] = useState(() => pathname.startsWith(SHARED_PROGRESS_PATH));

    useLayoutEffect(() => {
        setIsSharedRoute(pathname.startsWith(SHARED_PROGRESS_PATH));
    }, [pathname]);

    return { isSharedRoute };
}
