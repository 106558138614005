import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { TabsProvider } from "modules/sw-admin/context/TabsContextProvider";
import { PageContainer } from "modules/sw-admin/components/PageContainer";
import { PrivateRoute } from "core/components/Routes";
import { ROUTES } from "modules/home/enums/routes.enum";
import { IStudentTab, useStudentTabs } from "../hooks/use-student-tabs.hook";
import { studentRoutes } from "../constants/student-routes";

export const StudentsPage = () => {
    const tabs: Array<IStudentTab> = useStudentTabs();

    return (
        <TabsProvider tabs={tabs}>
            <PageContainer>
                <Switch>
                    {studentRoutes.map(({ path, exactPath, Component }) => (
                        <PrivateRoute
                            key={path}
                            exact={exactPath}
                            path={path}
                            component={Component}
                        />
                    ))}
                    <Redirect from="*" to={ROUTES.NOT_FOUND} />
                </Switch>
            </PageContainer>
        </TabsProvider>
    );
}
