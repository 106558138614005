import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Header } from "../Header";
import styles from "./styles.module.scss";

export const Layout: FC = ({ children }) => {
    return (
        <div className={styles.layoutContainer}>
            <div className={styles.headerContainer}>
                <Header />
            </div>
            <div className={styles.formContentContainer}>
                <Box width="448px">
                    {children}
                </Box>
            </div>
        </div>
    );
}
