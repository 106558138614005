import React from "react";
import {
    Popper,
    PopperProps,
} from "@material-ui/core";

export const PopperComponent = (props: PopperProps) => (
    <Popper
        modifiers={[
            {
                name: "offset",
                options: {
                    offset: [0, 4], // Moves Popper by 4px down on Y axis
                },
            },
        ]}
        {...props}
    />
);
