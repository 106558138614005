import React, { useMemo } from "react";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import { Box } from "@material-ui/core";
import Button from "core/components/Button";
import { ButtonType } from "core/enums/button-type.enum";
import { Notification } from "./Notification";
import { groupNotificationsByDate } from "./utils/group-notifications-by-date";
import { useNotifications } from "./hooks/use-notifications.hook";
import Loader from "core/components/Loader";
import { useReadNotification } from "./hooks/use-read-notification.hook";
import { getUnreadNotifications } from "core/models/notification/notification.model";
import { Toolbar } from "modules/home/components/Toolbar";

export const Notifications = () => {
    const { t } = useTranslation();

    const { data, isLoading } = useNotifications();
    const { notificationList, unreadNotifications } = useMemo(
        () => {
            if (!data || isLoading) return {};

            return {
                notificationList: groupNotificationsByDate(data.items),
                unreadNotifications: getUnreadNotifications(data.items),
            };
        },
        [data, isLoading]
    );

    const { readNotification } = useReadNotification();
    const onMarkNotificationsAsRead = async () => {
        await Promise.allSettled([
            ...unreadNotifications.map(({ id }) => readNotification(id))
        ]);
    };

    return (
        <>
            <Box mb="32px">
                <Toolbar
                    title={t("notifications")}
                    actions={
                        <Button
                            label={t("mark-all-as-read")}
                            type={ButtonType.TEXT}
                            onClick={onMarkNotificationsAsRead}
                            disabled={unreadNotifications?.length <= 0 || !notificationList}
                            textColor="violet"
                            style={{ fontWeight: 600 }}
                        />
                    }
                />
            </Box>

            {isLoading ? (
                <Loader />
            ) : notificationList ? (
                <Box
                    display="grid"
                    flexDirection="column"
                    gap={2}
                >
                    {notificationList.length > 0 ? notificationList.map(({ date, notifications }) => (
                        <div key={date}>
                            <Box pl="16px" mb="8px">
                                <Text bold>
                                    {date}
                                </Text>
                            </Box>

                            <Box
                                display="grid"
                                flexDirection="column"
                                gap="2px"
                            > 
                                {notifications.map((notification) => (
                                    <Notification
                                        key={notification.id}
                                        message={notification.message}
                                        isReadByUser={notification.readByUser}
                                    />
                                ))}
                            </Box>
                        </div>
                    )) : (
                        <Text>{t("no-new-notifications")}</Text>
                    )}
                </Box>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </>
    );
}
