import { useMemo } from "react";
import { ITab } from "core/components/Tabs";
import { useTranslation } from "core/hooks/use-translation.hook";
import { InstructorAccountType } from "../enums/instructor-account-type.enum";

export const useInstructorsTabs = () => {
    const { t } = useTranslation();
    const tabs: ITab[] = useMemo(() => ([
        {
            id: InstructorAccountType.OFFICIAL_ACCOUNTS,
            name: t("official-accounts"),
        },
        {
            id: InstructorAccountType.TRIAL_PERIOD,
            name: t("trial-period"),
        },
        {
            id: InstructorAccountType.UNVERIFIED,
            name: t("unverified"),
        },
        {
            id: InstructorAccountType.DECLINED,
            name: t("declined"),
        },
    ]), []);

    return tabs;
}
