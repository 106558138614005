import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import { PrivateRoute } from "core/components/Routes";
import { DrivingSchoolList } from "./DrivingSchoolList";
import { PageContainer } from "modules/sw-admin/components/PageContainer";
import { DrivingSchoolProfile } from "./DrivingSchoolProfile";
import { DrivingStudentsInformations } from "./DrivingStudentsInformations";
import { TabsProvider } from "modules/sw-admin/context/TabsContextProvider";
import { ITab } from "core/components/Tabs";
import { useInstructorsTabs } from "../hooks/use-instructors-tabs.hook";
import { EditDrivingSchool } from "./EditDrivingSchool";

export const DrivingSchoolsPage = () => {
    const tabs: ITab[] = useInstructorsTabs();

    return (
        <TabsProvider tabs={tabs}>
            <PageContainer>
                <Switch>
                    <PrivateRoute
                        exact
                        path={ROUTES.DRIVING_SCHOOLS}
                        component={DrivingSchoolList}
                    />
                    <PrivateRoute
                        exact
                        path={ROUTES.DRIVING_SCHOOL}
                        component={DrivingSchoolProfile}
                    />
                    <PrivateRoute
                        exact
                        path={ROUTES.DRIVING_SCHOOL_STUDENTS}
                        component={DrivingStudentsInformations}
                    />
                    <PrivateRoute
                        exact
                        path={ROUTES.EDIT_DRIVING_SCHOOL}
                        component={EditDrivingSchool}
                    />
                    <Redirect from="*" to={ROUTES.NOT_FOUND} />
                </Switch>
            </PageContainer>
        </TabsProvider>
    );
}
