import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import { CircularProgressBar } from "core/components/CircularProgressBar";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { ChevronRightIcon } from "assets/icons";
import { calculatePercentage } from "core/utils/calculate-percentage";
import { IStudent } from "core/models/student/student.model";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useWindowSize } from "core/hooks/use-window-size.hook";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { IStudentParams } from "core/interfaces/student-params.interface";

interface IProps {
    student: IStudent;
}

export const SimulatorProgressCard = ({ student }: IProps) => {
    const { t } = useTranslation();
    const { studentId, studentToken } = useParams<IStudentParams>();
    const { isSharedRoute } = useIsSharedRoute();
    const windowSize = useWindowSize();

    const {
        simulationMax,
        simulationPassed,
    } = student?.userProgress ?? {
        simulationMax: 0,
        simulationPassed: 0,
    }

    const simulatorPassedProgress = useMemo(
        () => calculatePercentage(simulationPassed, simulationMax),
        [simulationPassed, simulationMax]
    );

    return (
        <Link
            to={(isSharedRoute && studentToken) ? pathBuilder(
                SHARED_ROUTES.STUDENT_SIMULATOR_PROGRESS,
                ":studentToken",
                studentToken,
            ) : pathBuilder(
                ROUTES.STUDENT_SIMULATOR_PROGRESS,
                ":studentId",
                studentId,
            )}
            className={styles.simulatorProgressCardContainer}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                height={1}
                width={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    alignSelf="flex-start"
                    gap="12px"
                    height={1}
                >
                    <Text bold style={{ minWidth: "124px" }}>
                        {t("simulator-progress")}
                    </Text>
                    <CircularProgressBar
                        value={simulatorPassedProgress}
                        size={60}
                        styles={{
                            backgroundColor: RGBAColors.lightVioletWithOpacity(0.08),
                            pathColor: colors.violet,
                            trailColor: "transparent",
                        }}
                    >
                        <Text color="violet">
                            {`${simulationPassed}/${simulationMax}`}
                        </Text>
                    </CircularProgressBar>
                </Box>

                <Box minWidth="85px">
                    <Text
                        size="small"
                        style={{
                            color: RGBAColors.lightBlackWithOpacity(0.87),
                            wordBreak: windowSize.width > 1366 ? "normal" : "break-all",
                        }}
                    >
                        {t("pass-simulation-for-final-exam", { count: simulationMax })}
                    </Text>
                </Box>

                <ChevronRightIcon
                    color={colors.violet}
                    className={styles.chevronRightIcon}
                />
            </Box>
        </Link>
    );
}
