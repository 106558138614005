import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { classNameFormatter } from "core/utils/css-classname-formatter";

export type ITab<T = Record<string, unknown>> = T & {
    id: any;
    name: string;
    disabled?: boolean;
};

export interface ITabsProps<P> {
    tabs: Array<ITab<P>>;
    activeTab: ITab<P>;
    setActiveTab: (tab: ITab<P>) => void;
    joinTabs?: boolean;
}

export const Tabs = <P,>({
    tabs,
    activeTab,
    setActiveTab,
    joinTabs,
}: ITabsProps<P>) => {
    const onTabClick = (tab: ITab<P>) => {
        if (tab.disabled) return;

        setActiveTab(tab);
    }

    return (
        <Box
            display="flex"
            // gap={joinTabs ? "0px" : "12px"}
            flexWrap={joinTabs ? "nowrap" : "wrap"}
        >
            {tabs?.map((tab: ITab<P>) => (
                <div
                    key={tab.id}
                    className={classNameFormatter([
                        styles.tabContainer,
                        joinTabs ? styles.joined : styles.separated,
                        (tab.id === activeTab.id) && styles.activeTab,
                        tab.disabled && styles.disabledTab,
                    ])}
                    onClick={() => onTabClick(tab)}
                    style={{
                        cursor: tab.disabled ? "default" : "pointer",
                    }}
                >
                    <Text color="violet" size="medium">
                        {tab.name}
                    </Text>
                </div>
            )) ?? <></>}
        </Box>
    );
}
