import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import { StatusIndicator, TStatus } from "core/components/StatusIndicator";
import { useTranslation } from "core/hooks/use-translation.hook";
import { StudentStatus as EStudentStatus } from "modules/students/enums/student-status.enum";
import Text from "core/components/Text";

interface IProps {
    status: EStudentStatus;
}

export const StudentStatus = ({ status }: IProps) => {
    const { t } = useTranslation();

    const { statusIndicator, statusTranslation } = useMemo(() => {
        switch (status) {
            case EStudentStatus.ACTIVE: return {
                statusIndicator: "active",
                statusTranslation: t("active"),
            };
            case EStudentStatus.ACTIVATION_PENDING: return {
                statusIndicator: "inactive",
                statusTranslation: t("activation-pending"),
            };
            case EStudentStatus.INVITATION_PENDING: return {
                statusIndicator: "pending",
                statusTranslation: t("invitation-pending"),
            };
            case EStudentStatus.READY_FOR_EXAM: return {
                statusIndicator: "ready",
                statusTranslation: t("exam-ready"),
            };
            case EStudentStatus.PASSED: return {
                statusIndicator: "passed",
                statusTranslation: t("passed"),
            };
            default: return {};
        }
    }, [status]);

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            <StatusIndicator
                status={statusIndicator as TStatus}
                size={8}
            />
            <Text truncate>{statusTranslation}</Text>
        </Box>
    );
}
