export interface ITag {
    id: string;
    name: string;
}

export const createTag = ({ id, ...props }: Partial<ITag>) => {
    if (!id) {
        throw new Error('Unable to create a tag without an ID!');
    }

    return {
        id,
        name: null,
        ...props,
    } as ITag;
};
