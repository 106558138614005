import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import styles from "./styles.module.scss";
import { IInstructorBillingInformation } from "core/models/instructor/instructor-billing-information.model";

interface IProps extends IInstructorBillingInformation {
    code: string;
}

export const BillingInformationsCard = ({
    code,
    address,
    city,
    country,
    zipCode,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={1}
            className={styles.cardContainer}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                flexWrap="wrap"
            >
                <Text size="medium" bold>
                    {t("billing-informations")}
                </Text>
                {code && (
                    <Text size="small" color={null} className={styles.instructorCode}>
                        {`ID: ${code}`}
                    </Text>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap="2px"
            >
                <Text color={null} className={styles.cardSubTitle}>
                    {address}
                </Text>
                <Text color={null} className={styles.cardSubTitle}>
                    {city}
                </Text>
                <Text color={null} className={styles.cardSubTitle}>
                    {[zipCode, country].join(" ")}
                </Text>
            </Box>
        </Box>
    );
}
