import React, { useCallback, useMemo } from "react";
import { ITableHeadCell } from "core/components/Table/TableHead";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { Searchbar } from "modules/sw-admin/components/Searchbar";
import { useTranslation } from "core/hooks/use-translation.hook";
import { EntityType } from "core/enums/entity-type.enum";
import { Tabs } from "core/components/Tabs";
import { useTabs } from "../../../hooks/use-tabs.hook";
import { studentService } from "core/services/student.service";
import { IStudentTab } from "../../hooks/use-student-tabs.hook";
import { StudentStatus } from "./components/StudentStatus";
import { getStudentFullName, IStudent } from "core/models/student/student.model";
import { roundNumber } from "core/utils/round-number";
import { StudentAccountType } from "../../enums/student-account-type.enum";
import { OfficialAccountsTable } from "./components/OfficialAccountsTable";
import { TrialPeriodTable } from "./components/TrialPeriodTable";

const studentsTableHead: Array<ITableHeadCell> = [
    { translateKey: "driving-student" },
    { translateKey: "email-address" },
    { translateKey: "start-date" },
    { translateKey: "gender" },
    { translateKey: "date-of-birth" },
    { translateKey: "progress" },
    { translateKey: "driving-school" },
    { translateKey: "status" },
];

export interface IStudentsTableBody {
    id: string;
    drivingStudent: string;
    emailAddress: string;
    startDate: string;
    gender: string;
    dateOfBirth: string;
    progress: string;
    drivingSchool: string;
    status: React.ReactNode;
}

export const StudentsList = () => {
    const { t } = useTranslation();
    const {
        tabs,
        activeTab,
        setActiveTab,
    } = useTabs<IStudentTab>();

    const searchProps = useMemo(() => ({
        queryFunction: studentService.getStudents,
        queryKey: "drivingStudentsSearch",
        placeholder: t("search-students-placeholder"),
        loadingIndicator: t("loading-entity", { entityName: t("students") }),
        type: EntityType.STUDENT,
    }), []);

    const formatStudentTableBody = useCallback(
        (students: Array<IStudent>) => students.map((student) => ({
            id: student.id,
            drivingStudent: getStudentFullName(student),
            emailAddress: student.email,
            startDate: student.startedAt,
            gender: student.gender,
            dateOfBirth: student.dayOfBirth,
            progress: `${roundNumber(student.overallProgress * 100)}%`,
            drivingSchool: student.drivingSchool?.name,
            status: student.drivingSchool?.partner !== undefined
                ? <StudentStatus isPartner={student.drivingSchool.partner} />
                : <></>
        })),
        [],
    );

    const renderActiveTabTable = () => {
        switch (activeTab.id) {
            case StudentAccountType.OFFICIAL_ACCOUNTS: return (
                <OfficialAccountsTable
                    tableHead={studentsTableHead}
                    formatTableBody={formatStudentTableBody}
                />
            );
            case StudentAccountType.TRIAL_PERIOD: return (
                <TrialPeriodTable
                    tableHead={studentsTableHead}
                    formatTableBody={formatStudentTableBody}
                />
            );
            default: return <></>;
        }
    };

    return (
        <>
            <Searchbar
                title={t("driving-students")}
                searchProps={searchProps}
            />
            <ContentContainer>
                <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                {renderActiveTabTable()}
            </ContentContainer>
        </>
    );
}
