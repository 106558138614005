import React from "react";
import { Animate } from "react-move";

// An ease-out function that slows the count as it progresses
const easeOutQuad = (t: number) => t * (2 - t);

export interface IAnimateContentProps {
    valueStart: number;
    valueEnd: number;
    duration: number;
    children: (value: number) => React.ReactElement;
}

export const AnimateContent = ({
    valueStart,
    valueEnd,
    duration,
    children,
}: IAnimateContentProps) => (
    <Animate
        start={() => ({
            value: valueStart,
        })}
        update={() => ({
            value: [valueEnd],
            timing: {
                duration: duration,
                ease: easeOutQuad,
            },
        })}
    >
        {({ value }) => children(value)}
    </Animate>
);
