export interface IInstructorBillingInformation {
    address: string;
    city: string;
    country: string;
    zipCode: number;
}

export const createInstructorBillingInformation = ({...props }: Partial<IInstructorBillingInformation>) => {
    return {
        address: "",
        city: "",
        country: "",
        zipCode: null,
        ...props,
    } as IInstructorBillingInformation;
};
