import { ROUTES } from "modules/home/enums/routes.enum";
import { INavigationItem } from "modules/home/interfaces/navigation-item.interface";
import { AddStudent } from "../pages/AddStudent";
import { EditStudent } from "../pages/EditStudent";
import { StudentOverallProgress } from "../pages/StudentOverallProgress";
import { StudentProfile } from "../pages/StudentProfile";
import { StudentSimulatorProgress } from "../pages/StudentSimulatorProgress";
import { StudentsList } from "../pages/StudentsList";

export const studentRoutes: Pick<INavigationItem, "path" | "exactPath" | "Component">[] = [
    {
        path: ROUTES.STUDENTS,
        exactPath: true,
        Component: StudentsList,
    },
    {
        path: ROUTES.ADD_STUDENT,
        exactPath: true,
        Component: AddStudent,
    },
    {
        path: ROUTES.EDIT_STUDENT,
        exactPath: true,
        Component: EditStudent,
    },
    {
        path: ROUTES.STUDENT_PROFILE,
        exactPath: true,
        Component: StudentProfile,
    },
    {
        path: ROUTES.STUDENT_OVERALL_PROGRESS,
        exactPath: true,
        Component: StudentOverallProgress,
    },
    {
        path: ROUTES.STUDENT_SIMULATOR_PROGRESS,
        exactPath: false,
        Component: StudentSimulatorProgress,
    },
];
