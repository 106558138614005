import React from "react";
import { Control, Controller } from "react-hook-form";
import Box from "@material-ui/core/Box";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";

interface IProps {
    control: Control<any>;
    serverErrorMessage: string;
    hasErrors: boolean;
}

export const DateOfBirthController = ({
    control,
    serverErrorMessage,
    hasErrors,
}: IProps) => {
    const { t } = useTranslation();

    const handleDateOfBirthFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    ) => {
        if (isNaN(event.target.value as any)) return;

        onChange(event);
    }

    return (
        <Box display="grid" flexDirection="column" gap="4px">
            <Text size="small">{t("date-of-birth")}</Text>
            <Box display="flex" alignItems="center" flexWrap="wrap">
                <Controller
                    name="day"
                    control={control}
                    render={({field: { ref, onChange, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput
                            inputRef={ref}
                            type={FormInputType.TEXT}
                            placeholder="DD"
                            error={!!error?.message || !!serverErrorMessage}
                            maxLength={2}
                            style={{ width: "88px", marginRight: "16px" }}
                            onChange={(event) => handleDateOfBirthFieldChange(event, onChange)}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="month"
                    control={control}
                    render={({field: { ref, onChange, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput
                            inputRef={ref}
                            type={FormInputType.TEXT}
                            placeholder="MM"
                            error={!!error?.message || !!serverErrorMessage}
                            maxLength={2}
                            style={{ width: "88px", marginRight: "16px" }}
                            onChange={(event) => handleDateOfBirthFieldChange(event, onChange)}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="year"
                    control={control}
                    render={({field: { ref, onChange, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput
                            inputRef={ref}
                            type={FormInputType.TEXT}
                            placeholder="YYYY"
                            error={!!error?.message || !!serverErrorMessage}
                            maxLength={4}
                            style={{ flex: 1 }}
                            onChange={(event) => handleDateOfBirthFieldChange(event, onChange)}
                            {...restFieldProps}
                        />
                    )}
                />
            </Box>
            {hasErrors && (
                <Box mt="4px">
                    <Text size="small" color="red">{t("required-fields")}</Text>
                </Box>
            )}
        </Box>
    );
}
