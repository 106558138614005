/**
 * Duration in milliseconds
 */
 export enum TIME_DURATION {
    DAY = 8.64e+7,
    HALF_DAY = 4.32e+7,
    HOUR = 3.6e+6,
    MINUTE = 60000,
    SECOND = 1000,
}
