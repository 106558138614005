import { Autocomplete } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";

export const StyledAutocomplete = withStyles({
    root: {
        "& .MuiFormHelperText-root.Mui-error": {
            color: colors.red,
            padding: "0px",
            margin: "0px",
            marginTop: "4px",
            fontFamily: "NunitoSansRegular !important",
            fontSize: "12px !important",
        },
    },
    inputRoot: {
        minHeight: "43px",
        maxHeight: "43px",
        borderRadius: "8px",
        padding: "12px 16px !important",
        backgroundColor: colors.inputGray,

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: colors.red,
        },
        "& .MuiAutocomplete-endAdornment": {
            display: "none",
        },
    },
    input: {
        fontFamily: "NunitoSansRegular !important",
        fontSize: "14px !important",
        padding: "0px !important",
        height: "19px !important",
    },
    paper: {
        border: `1px solid ${RGBAColors.lightBlackWithOpacity(0.12)}`,
        borderRadius: "8px !important",

        "& .MuiAutocomplete-listbox": {
            padding: "0px",
            maxHeight: "220px !important",

            "& .MuiAutocomplete-option": {
                fontSize: "14px !important",
                fontFamily: "NunitoSansRegular !important",
                padding: "12px 16px !important",
                color: colors.lightBlack,

                "&:hover": {
                    backgroundColor: `${colors.inputGray} !important`,
                },
                "&[aria-selected='true']": {
                    backgroundColor: `${colors.inputGray} !important`,
                },
                "&[aria-selected='true']:hover": {
                    backgroundColor: `${colors.inputGray} !important`,
                },
                "&:not(:last-child)": {
                    borderBottom: `1px solid ${RGBAColors.lightBlackWithOpacity(0.12)}`,
                },
            },
        },
    }
})(Autocomplete);
