import { TIME_DURATION } from "core/enums/time-duration.enum";
import { IStudent } from "core/models/student/student.model";
import { studentService } from "core/services/student.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { useQuery, useQueryClient } from "react-query";

interface IUseStudent {
    studentId: string;
}

export const useStudent = ({ studentId }: IUseStudent) => {
    const queryClient = useQueryClient();
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery<IStudent, Error>(
        ["student", studentId],
        () => studentService.getStudent(studentId),
        {
            onError: (error: Error) => {
                console.log("Error on getting student - ", error);
            },
            initialData: () => queryClient.getQueryData(["student", studentId]),
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
