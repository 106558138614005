import React from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@material-ui/core/Box";
import FormInput from "core/components/FormInput";
import FormWrapper from "core/components/FormWrapper";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useAddStudentValidationSchema } from "modules/students/hooks/use-form-validation-schema.hook";
import { defaultAddStudentValues, IAddStudentCredentials } from "modules/students/models/add-student.model";
import { useAddStudent } from "modules/students/hooks/use-add-student.hook";
import { useSnackbar } from "core/hooks/use-snackbar.hook";
import { IStudent } from "core/models/student/student.model";
import {LicenseClassController} from "./components/LicenseClassController";

export const AddStudent = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const addStudentValidationSchema = useAddStudentValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        trigger,
        setValue,
        getValues
    } = useForm<IAddStudentCredentials>({
        mode: "onChange",
        resolver: yupResolver(addStudentValidationSchema),
        defaultValues: defaultAddStudentValues,
    });

    const aspiredLicenses = useWatch({control, name: 'aspired_licenses'});

    if (aspiredLicenses !== null) {
        if (getValues('acquired_licenses') === null) {
            setValue('acquired_licenses', ["Kein Führerschein vorhanden"]);
        }
    }

    const {
        addStudent,
        isLoading,
        serverErrorMessage,
    } = useAddStudent();
    const { setSnackbarProps, clearSnackbarProps } = useSnackbar();

    const onSubmit = async (data: IAddStudentCredentials) => {
        clearSnackbarProps();
        const student: IStudent = await addStudent(data);

        if (!student) return;

        push(ROUTES.STUDENTS);
        setSnackbarProps({
            open: true,
            message: t("invitation-sent"),
            alertType: "success",
            onClose: clearSnackbarProps,
        });
    };

    return (
        <FormWrapper
            title={t("add-new-student")}
            subTitle={t("fill-form-fields-to-add-student")}
            backToPath={ROUTES.STUDENTS}
            submitButtonProps={{
                label: t("send-invitation"),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid || isLoading,
                isLoading: isLoading,
            }}
            error={serverErrorMessage}
        >
            <Box display="grid" flexDirection="column" gap={2}>
                <Controller
                    name="first_name"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-last-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-last-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-email-address")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-email-address-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <LicenseClassController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    validate={(fieldName: string) => trigger(fieldName as keyof IAddStudentCredentials)}
                    type={"acquired-licenses"}
                />

                <LicenseClassController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    validate={(fieldName: string) => trigger(fieldName as keyof IAddStudentCredentials)}
                    type={"aspired-licenses"}
                />
            </Box>
        </FormWrapper>
    );
}
