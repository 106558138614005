import { LearningActivityType } from "modules/students/enums/learning-activity-type.enum";

export interface IStudentLearningActivity {
    answered: number;
    correct: number;
    incorrect: number;
    answerRate: number;
    correctRate: number;
    incorrectRate: number;
    forDate: string;
    type: LearningActivityType;
}

export const createStudentLearningActivity = ({ ...props }: Partial<IStudentLearningActivity>) => {
    return {
        answered: null,
        correct: null,
        incorrect: null,
        answerRate: null,
        correctRate: null,
        incorrectRate: null,
        forDate: "",
        type: "",
        ...props,
    } as IStudentLearningActivity;
};
