import { ISearch } from "../search/search.model";
import { createSearchModelFromResponse, ISearchResponse } from "../search/search.response";
import { createNotification, INotification } from "./notification.model";

export interface INotificationResponse {
    id: number;
    message: string;
    read_by_user: boolean;
    created_at: string;
    read_at: string;
}

export const createNotificationFromResponse = ({
    id,
    read_by_user,
    created_at,
    read_at,
    ...props
}: INotificationResponse): INotification => {
    return createNotification({
        id: id?.toString(),
        readByUser: read_by_user ?? false,
        createdAt: created_at ?? null,
        readAt: read_at ?? null,
        ...props,
    });
};

export const createNotificationsFromResponse = ({
    items,
    ...props
}: ISearchResponse<INotificationResponse>): ISearch<INotification> => {
    return createSearchModelFromResponse({
        items: items?.map(createNotificationFromResponse) ?? [],
        ...props,
    });
};
