import { useMutation, useQueryClient } from "react-query";
import { ISearch } from "core/models/search/search.model";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { useTabs } from "../../hooks/use-tabs.hook";
import { IInstructor } from "core/models/instructor/instructor.model";
import { userService } from "core/services/user.service";
import { UserStatus } from "core/enums/user-status.enum";

interface IChangeInstructorStatusParams {
    instructorId: string;
    status?: UserStatus;
    isActive?: boolean;
}

export const useChangeInstructorStatus = () => {
    const {
        activeTab,
        tabActivePage,
    } = useTabs();
    const activeTabQueryKey = ["instructors", activeTab.id, { page: tabActivePage }];

    const queryClient = useQueryClient();
    const {
        mutateAsync: changeInstructorStatus,
        isLoading,
        isError,
        error,
    } = useMutation(
        ({ instructorId, status, isActive }: IChangeInstructorStatusParams) => {
            if (isActive !== undefined) {
                return userService.toggleActiveUser(instructorId, isActive);
            }

            if (status) {
                return userService.changeUserStatus(instructorId, status);
            }
        },
        {
            onMutate: async ({ instructorId, status, isActive }) => {
                await queryClient.cancelQueries(activeTabQueryKey);

                const previousActiveInstructors: ISearch<IInstructor> = queryClient.getQueryData(activeTabQueryKey);
                if (previousActiveInstructors) {
                    queryClient.setQueryData(
                        activeTabQueryKey,
                        (previousData: ISearch<IInstructor>) => {
                            const { items, ...restPreviousData } = previousData;

                            return {
                                ...restPreviousData,
                                items: items.map((item) => {
                                    if (item.id === instructorId) {
                                        return {
                                            ...item,
                                            active: isActive !== undefined ? isActive : item.active,
                                            status: status ?? item.status,
                                        };
                                    }

                                    return item;
                                }),
                            };
                        },
                    );
                }

                return () => queryClient.setQueryData(activeTabQueryKey, previousActiveInstructors);
            },
            onError: (error, variables, rollback: CallableFunction) => {
                console.log("Error on instructor status change - ", error);

                rollback && rollback();
            },
            onSettled: (data, error, { status }) => {
                queryClient.invalidateQueries(activeTabQueryKey);

                if (status && status !== activeTab.id) {
                    queryClient.invalidateQueries(["instructors", status]);
                }
            },
        }
    );

    return {
        changeInstructorStatus,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
