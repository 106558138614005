import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Text, { TextColor } from "core/components/Text";
import styles from "./styles.module.scss";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { ROUTES } from "modules/home/enums/routes.enum";

interface IProps {
    title: string;
    entityCount: number | string;
    subTitle?: string;
    goTo?: ROUTES;
    style?: {
        backgroundColor?: string;
        titleColor?: "black" | "white";
        entityCountColor?: TextColor;
    };
}

export const InfoCard = ({
    title,
    entityCount,
    subTitle,
    goTo,
    style,
}: IProps) => {
    const {
        backgroundColor = colors.white,
        titleColor = "black",
        entityCountColor = "black",
    } = style ?? {
        backgroundColor: colors.white,
        titleColor: "black",
        entityCountColor: "black",
    };

    return (
        <InfoCardWrapper goTo={goTo}>
            <Box
                display="grid"
                flexDirection="column"
                justifyContent="space-between"
                gap={1}
                className={styles.cardContainer}
                style={{ backgroundColor }}
            >
                <Box
                    display="grid"
                    flexDirection="column"
                    gap={1}
                >
                    <Text color={titleColor} size="medium" bold>
                        {title}
                    </Text>
                    {subTitle && (
                        <Text
                            color={null}
                            style={{
                                color: titleColor === "black"
                                    ? RGBAColors.lightBlackWithOpacity(0.6)
                                    : RGBAColors.whiteWithOpacity(0.87)
                            }}
                        >
                            {subTitle}
                        </Text>
                    )}
                </Box>
                <Text color={entityCountColor} size="large">
                    {entityCount}
                </Text>
            </Box>
        </InfoCardWrapper>
    );
}

const InfoCardWrapper: FC<{ goTo: ROUTES; }> = ({ goTo, children }) => (
    <div className={styles.cardWrapper}>
        {goTo ? (
            <Link to={goTo}>
                {children}
            </Link>
        ) : (
            <>{children}</>
        )}
    </div>
);
