export enum FileExtension {
    CSV = "csv",
    XLSX = "xlsx",
}

export interface IExportFileOptions {
    filename?: string;
    extension?: FileExtension;
}

export const exportFile = (
    content: string,
    options?: IExportFileOptions,
) => {
    const {
        filename = "file",
        extension = FileExtension.CSV,
    } = options ?? {
        filename: "file",
        extension: FileExtension.CSV,
    };

    const blob = new Blob([content]);
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = downloadUrl;
    link.setAttribute("download", `${filename}.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    URL.revokeObjectURL(downloadUrl);
};
