import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, Box } from "@material-ui/core";
import Text from "core/components/Text";
import Button from "core/components/Button";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import { StreamwaysLogoImage, StreamwaysTextImage } from "assets/images";

export interface IVerifyEmailModalProps {
    openDialog: boolean;
    onClose: () => void;
}

export const VerifyEmailModal = ({
    openDialog,
    onClose,
}: IVerifyEmailModalProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(openDialog);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDialogClose = (event?: React.MouseEvent<typeof Dialog>, reason?: string) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        onClose();
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown
            onClose={onDialogClose}
            classes={{ paper: styles.dialogPaper }}
            BackdropProps={{
                classes: { root: styles.dialogBackdrop },
            }}
        >
            <DialogTitle
                classes={{ root: styles.dialogTitle }}
            >
                <Text size="large" className={styles.verifyEmailText}>
                    {t("verify-your-email-title")}
                </Text>
            </DialogTitle>
            <DialogContent
                classes={{ root: styles.dialogContent }}
            >
                <Text className={styles.verifyEmailText}>
                    {t("verify-your-email-description")}
                </Text>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="48px 0px"
                    gap="30px"
                >
                    <StreamwaysLogoImage style={{
                        height: "130px",
                        width: "100px",
                    }}/>
                    <StreamwaysTextImage style={{
                        height: "16px",
                        width: "120px",
                    }}/>
                </Box>
            </DialogContent>
            <DialogActions
                classes={{ root: styles.dialogActions }}
            >
                <Button
                    label={t("got-it")}
                    onClick={onDialogClose}
                />
            </DialogActions>
        </Dialog>
    );
}
