import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import themePlaceholderImage from 'assets/images/theme-placeholder.png';
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useThemes } from "modules/themes/hooks/use-themes.hook";
import Loader from "core/components/Loader";
import InfiniteScroll from "core/components/InfiniteScroll";
import { getThemeName } from "core/models/theme/theme.model";
import { Toolbar } from "modules/home/components/Toolbar";
import { CardList } from "modules/themes/components/CardList";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { CardSize } from "modules/themes/components/CardItem/CardItem";

export const ThemesList = () => {
    const { t } = useTranslation();

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useThemes({ key: "themes-list" });

    const formattedThemeResults = useMemo(
        () => {
            if (!data?.pages[0]?.totalResults) return;

            return data.pages
                .map(({ items }) => items)
                .flat()
                .map((theme) => ({
                    title: getThemeName(theme.translations),
                    description: t("lesson-count", { count: theme.numberOfLessons }),
                    imageURL: theme.media ?? themePlaceholderImage,
                    path: pathBuilder(
                        ROUTES.THEME_LESSONS,
                        ":themeId",
                        theme.id
                    ),
                    size: "small" as CardSize,
                }));
        },
        [data]
    );

    return (
        <>
            <Box mb="24px">
                <Toolbar title={t("themes")} />
            </Box>

            <InfiniteScroll
                hasMore={hasNextPage}
                isFetching={isFetchingNextPage}
                onFetchMore={fetchNextPage}
            >
                {isLoading ? (
                    <Loader />
                ) : formattedThemeResults ? (
                    <CardList items={formattedThemeResults} />
                ) : (
                    <Text>{t("no-data-available")}</Text>
                )}
            </InfiniteScroll>
        </>
    );
}
