import { createSearchModel, ISearch } from "./search.model";

export interface ISearchResponse<T = any> {
    total_results: number;
    total_pages: number;
    current_page: number;
    limit: number;
    offset: number;
    order_by: string;
    direction: string;
    items: Array<T>;
}

export const createSearchModelFromResponse = <T>({
    total_results,
    total_pages,
    current_page,
    limit,
    offset,
    order_by,
    ...props
}: ISearchResponse<T>): ISearch<T> => {
    return createSearchModel({
        totalResults: total_results ?? null,
        totalPages: total_pages ?? null,
        currentPage: current_page ?? null,
        limit: limit ?? null,
        offset: offset ?? null,
        orderBy: order_by ?? "",
        ...props,
    });
};
