import { createContext, useState } from "react";
import { ITab } from "core/components/Tabs";

export interface ITabsContext<T> {
    tabs: Array<T>;
    activeTab: T;
    setActiveTab: (tab: T) => void;
    tabActivePage: number;
    setTabActivePage: (page: ((previousPage: number) => number) | number) => void;
}

const createTabsContext = <T, >() => createContext<ITabsContext<T>>({} as ITabsContext<T>);

export const TabsContext = createTabsContext<any>();

export interface ITabsProvider<T> {
    tabs: Array<T>;
    children: React.ReactNode;
}

export const TabsProvider= <T extends ITab, >({ tabs, children }: ITabsProvider<T>) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [tabsActivePage, setTabsActivePage] = useState(() => tabs.map(({ id }) => ({ tabId: id, page: 1 })));

    const tabActivePage = tabsActivePage.find(({ tabId }) => tabId === activeTab.id).page;
    const setTabActivePage = (page: ((previousPage: number) => number) | number) => {
        let activePage = tabActivePage;

        if (typeof page === "number") {
            activePage = page;
        } else {
            activePage = page(activePage);
        }

        setTabsActivePage((prevTabs) => prevTabs.map((prevTab) => {
            if (prevTab.tabId === activeTab.id) {
                return {
                    ...prevTab,
                    page: activePage,
                };
            }

            return prevTab;
        }));
    }

    const value = {
        tabs,
        activeTab,
        setActiveTab,
        tabActivePage,
        setTabActivePage,
    };

    return (
        <TabsContext.Provider value={value}>
            {children}
        </TabsContext.Provider>
    );
};
