import React from "react";
import { Box } from "@material-ui/core";
import { TableHead, ITableHeadProps } from "./TableHead";
import { TableBody, ITableBodyProps } from "./TableBody";
import { Pagination, IPaginationProps } from "../Pagination";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import styles from "./styles.module.scss";

export interface ITableProps<T = Record<string, unknown>> {
    headProps: ITableHeadProps;
    bodyProps: ITableBodyProps<T>;
    paginationProps: IPaginationProps & {
        lastActivePage: number;
        pageSize: number;
        totalResults: number;
    };
    tableLayout?: "auto" | "fixed",
}

export const Table = <T,>({
    headProps,
    bodyProps,
    paginationProps,
    tableLayout = "fixed",
}: ITableProps<T>) => {
    const { t } = useTranslation();

    const {
        page,
        lastActivePage,
        pageSize,
        totalPages,
        totalResults,
        isFetchingNextPage,
        setPage,
    } = paginationProps;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flex="1"
            mt="32px"
            gap={8}
        >
            <div className={styles.tableWrapper}>
                <table data-table-layout={tableLayout}>
                    <TableHead {...headProps} />
                    <TableBody {...bodyProps} />
                </table>
                {isFetchingNextPage && (
                    <Box p="12px 0px">
                        <Text>{t("loading-page", { page })}</Text>
                    </Box>
                )}
            </div>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
            >
                <Text color={null} className={styles.paginationText}>
                    {t("showing-items", {
                        pageNumber: lastActivePage,
                        pageSize: pageSize,
                        totalItems: totalResults,
                    })}
                </Text>
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    isFetchingNextPage={isFetchingNextPage}
                />
            </Box>
        </Box>
    );
}
