import React from "react";
import { useTranslation } from "core/hooks/use-translation.hook";
import { Toolbar } from "modules/home/components/Toolbar";
import { useParams } from "react-router-dom";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import Box from "@material-ui/core/Box";
import { OverallProgressItem } from "./components/OverallProgressItem";
import { useThemes } from "modules/themes/hooks/use-themes.hook";
import Text from "core/components/Text";
import InfiniteScroll from "core/components/InfiniteScroll";
import Loader from "core/components/Loader";
import { getThemeName, ITheme } from "core/models/theme/theme.model";
import { calculateStudentProgress } from "modules/students/utils/calculate-student-progress";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { IStudentParams } from "core/interfaces/student-params.interface";

export const StudentOverallProgress = () => {
    const { t } = useTranslation();
    const { studentId, studentToken } = useParams<IStudentParams>();
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useThemes({
        key: `student-${studentId ?? studentToken}-overall-progress`,
        studentId,
        studentToken,
    });

    const renderOverallProgressItems = (themes: ITheme[]) => themes.map((theme: ITheme) => {
        const calculatedStudentProgress = calculateStudentProgress(theme.userProgress);

        return (
            <OverallProgressItem
                key={theme.id}
                themeName={getThemeName(theme.translations)}
                correctAnswers={calculatedStudentProgress.correctAnswers}
                wrongAnswers={calculatedStudentProgress.wrongAnswers}
            />
        );
    });

    return (
        <>
            <Box mb="24px">
                <Toolbar
                    title={t("overall-progress")}
                    backToPath={(isSharedRoute && studentToken) ? pathBuilder(
                        SHARED_ROUTES.STUDENT_PROFILE,
                        ":studentToken",
                        studentToken,
                    ) as SHARED_ROUTES : pathBuilder(
                        ROUTES.STUDENT_PROFILE,
                        ":studentId",
                        studentId,
                    ) as ROUTES}
                />
            </Box>

            <InfiniteScroll
                hasMore={hasNextPage}
                isFetching={isFetchingNextPage}
                onFetchMore={fetchNextPage}
            >
                {isLoading ? (
                    <Loader />
                ) : data?.pages[0]?.totalResults > 0 ? (
                    <Box
                        display="grid"
                        flexDirection="column"
                        gap={2}
                    >
                        {data.pages.map((page, index) => (
                            <React.Fragment key={index}>
                                {renderOverallProgressItems(page.items)}
                            </React.Fragment>
                        ))}
                    </Box>
                ) : (
                    <Text>{t("no-data-available")}</Text>
                )}
            </InfiniteScroll>
        </>
    );
}
