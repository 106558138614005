import { useMutation, useQueryClient } from "react-query";
import { INotification } from "core/models/notification/notification.model";
import { ISearch } from "core/models/search/search.model";
import { notificationService } from "core/services/notification.service";
import { handleErrorMessage } from "core/utils/handle-error-message";

export const useReadNotification = () => {
    const queryClient = useQueryClient();
    const {
        mutateAsync: readNotification,
        isLoading,
        isError,
        error,
    } = useMutation(
        (notificationId: string) => notificationService.readNotification(notificationId),
        {
            onMutate: async (notificationId: string) => {
                // Cancel any outgoing refetches so they don't overwrite optimistic (mutation) update
                await queryClient.cancelQueries(["notifications"]);

                const previousNotifications: ISearch<INotification> = queryClient.getQueryData(["notifications"]);

                if (previousNotifications) {
                    queryClient.setQueryData(
                        ["notifications"],
                        (previousData: ISearch<INotification>) => {
                            const { items, ...restPreviousData } = previousData;

                            return {
                                ...restPreviousData,
                                items: items.map((item) => {
                                    if (item.id === notificationId) {
                                        return {
                                            ...item,
                                            readByUser: true,
                                        }
                                    }

                                    return item;
                                })
                            };
                        },
                    );
                }

                return () => queryClient.setQueryData(["notifications"], previousNotifications);
            },
            onSettled: () => {
                queryClient.invalidateQueries(["notifications"]);
            },
            onError: (error: Error, notificationId: string, rollback: CallableFunction) => {
                console.log("Error on read notification - ", error);

                rollback && rollback();
            },
        }
    );

    return {
        readNotification,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
