import React from "react";
import { ValidTranslationKeys } from "react-i18next";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import { Box } from "@material-ui/core";
import { SortDirection } from "core/interfaces/search-params.interface";
import { SortArrowDownIcon, SortArrowUpIcon } from "assets/icons";
import colors from "styles/variables/colors.module.scss";

export interface ITableHeadCell {
    translateKey: keyof ValidTranslationKeys;
    sortable?: boolean;
    isActiveColumn?: boolean;
    sortDirection?: SortDirection;
    onClick?: () => void;
}

export interface ITableHeadProps {
    data: Array<ITableHeadCell>;
}

export const TableHead = ({ data }: ITableHeadProps) => {
    const { t } = useTranslation();

    return (
        <thead>
            <tr>
                {data.map(({
                    translateKey,
                    sortable,
                    isActiveColumn,
                    sortDirection,
                    onClick,
                }) => (
                    <th key={translateKey}>
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            onClick={() => {
                                if (!sortable) return;

                                onClick && onClick();
                            }}
                            style={{
                                cursor: sortable ? "pointer" : "default",
                                userSelect: "none",
                            }}
                        >
                            <Text size="small" color={null}>
                                {t(translateKey)}
                            </Text>
                            {sortable && (sortDirection === SortDirection.DESC
                                ? <SortArrowDownIcon
                                    color={isActiveColumn ? colors.violet : colors.lightBlack}
                                    style={{ minWidth: "24px" }}
                                />
                                : <SortArrowUpIcon
                                    color={isActiveColumn ? colors.violet : colors.lightBlack}
                                    style={{ minWidth: "24px" }}
                                />
                            )}
                        </Box>
                    </th>
                ))}
            </tr>
        </thead>
    );
}
