import { TIME_DURATION } from "core/enums/time-duration.enum";
import { IUser } from "core/models/user/user.model";
import { userService } from "core/services/user.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { useQuery, useQueryClient } from "react-query";

interface IUseUser {
    enabled: boolean;
}

export const useUser = (props?: IUseUser) => {
    const queryClient = useQueryClient();
    const { enabled } = props ?? { enabled: true };

    const {
        data: user,
        isLoading,
        isError,
        error,
    } = useQuery<IUser, Error>(
        "user",
        () => userService.getUser(),
        {
            initialData: () => queryClient.getQueryData("user"),
            onError: (err: Error) => {
                console.log("Error on getting user - ", err);
            },
            staleTime: TIME_DURATION.HOUR,
            enabled,
        },
    );

    return {
        user,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    }
};
