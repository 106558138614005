import React from "react";
import { RGBAColors } from "styles/variables/rgba-colors";

export const CustomizedXAxisTick = ({ x, y, payload }: any) => {
    const { value } = payload ?? {};
    const splittedValue = typeof value === "string" ? value.split(" ") : ["", ""];

    return (
        <g>
            <text
                x={x}
                y={y}
                textAnchor="middle"
                dominantBaseline="middle"
                fill={RGBAColors.lightBlackWithOpacity(0.38)}
                fontSize={12}
            >
                {splittedValue[0]}
            </text>
            <text
                x={x}
                y={y + 15}
                textAnchor="middle"
                dominantBaseline="middle"
                fill={RGBAColors.lightBlackWithOpacity(0.38)}
                fontSize={12}
            >
                {splittedValue[1]}
            </text>
        </g>
    );
}
