import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import {Box, Grid} from "@material-ui/core";
import Text from "core/components/Text";
import { getStudentFullName } from "core/models/student/student.model";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import { pathBuilder } from "core/utils/path-builder";
import Loader from "core/components/Loader";
import { Toolbar } from "modules/home/components/Toolbar";
import { OverallProgressCard } from "./components/OverallProgressCard";
import { SimulatorProgressCard } from "./components/SimulatorProgressCard";
import { DifficultLessonsCard } from "./components/DifficultLessonsCard";
import { StudentInfo } from "./components/StudentInfo";
import { LearningActivity } from "./components/LearningActivity";
import { useStudentProfile } from "modules/students/hooks/use-student-profile.hook";
import { LearningActivityType } from "modules/students/enums/learning-activity-type.enum";
import { ISelectChangeEvent, ISelectOption } from "core/components/FormSelect";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { IStudentParams } from "core/interfaces/student-params.interface";

const WEEKS_IN_MONTH = 4;
const LAST_ACTIVITY_DAYS = 7;
const LAST_ACTIVITY_MONTHS = 2;

export const StudentProfile = () => {
    const { t } = useTranslation();
    const { state } = useLocation<ILocationState>();
    const { studentId, studentToken } = useParams<IStudentParams>();
    const { isSharedRoute } = useIsSharedRoute();

    const learningActivityOptions: Array<ISelectOption & {
        type: LearningActivityType;
        period: number;
    }> = useMemo(
        () => [
            {
                name: t("last-days", { count: LAST_ACTIVITY_DAYS }),
                type: LearningActivityType.DAY,
                period: LAST_ACTIVITY_DAYS,
            },
            {
                name: t("last-months", { count: LAST_ACTIVITY_MONTHS }),
                type: LearningActivityType.WEEK,
                period: LAST_ACTIVITY_MONTHS * WEEKS_IN_MONTH,
            },
        ],
        []
    );

    const [selectedLearningActivity, setSelectedLearningActivity] = useState(learningActivityOptions[0]);
    const {
        isLoading,
        student,
        difficultLessons,
        learningActivity,
    } = useStudentProfile({
        studentToken,
        studentId,
        learningActivityType: selectedLearningActivity.type,
        learningActivityPeriod: selectedLearningActivity.period,
    });

    const handleLearningActivityChange = useCallback(
        (event: React.ChangeEvent<ISelectChangeEvent>) => {
            setSelectedLearningActivity(
                learningActivityOptions.find(
                    ({ name }) => name === event.target.value
                )
            );
        },
        [learningActivityOptions]
    );

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : student ? (
                <>
                    <Toolbar
                        title={getStudentFullName(student)}
                        backToPath={!isSharedRoute && (
                            (state?.from?.pathname as ROUTES) ?? ROUTES.STUDENTS
                        )}
                        actions={!isSharedRoute ? (
                            <Grid display="flex">
                                <Link to={pathBuilder(
                                    ROUTES.EDIT_STUDENT,
                                    ":studentId",
                                    studentId,
                                )}>
                                    <Button
                                        label={t("edit-entity", { entityName: t("student") })}
                                        style={{ width: "fit-content" }}
                                    />
                                </Link>
                            </Grid>
                        ) : <></>}
                    />

                    <Box
                        mt="24px"
                        mb="48px"
                        display="flex"
                        alignItems="center"
                        // gap={4}
                        width={1}
                    > 
                        <OverallProgressCard student={student} />
                        <SimulatorProgressCard student={student} />
                        <DifficultLessonsCard difficultLessons={difficultLessons} />
                    </Box>

                    <Box
                        display="flex"
                        // gap={4}
                        width={1}
                    > 
                        <StudentInfo student={student} />
                        <LearningActivity
                            chartData={learningActivity}
                            value={selectedLearningActivity.name}
                            options={learningActivityOptions}
                            onChange={handleLearningActivityChange}
                        />
                    </Box>
                </>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </>
    );
}
