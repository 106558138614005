import { useQueries } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { instructorService } from "core/services/instructor.service";
import { IInstructor } from "core/models/instructor/instructor.model";
import { IStudentAccountsCreated } from "core/models/instructor/student-accounts-created.model";
import { DateGroupTypeEnum } from "core/components/StudentAccountsCreatedChart/enums/date-group-type.enum";

interface IUseInstructorProfile {
    instructorId: string;
    studentAccountsCreatedParams: {
        type: DateGroupTypeEnum;
        start: string;
        end: string;
    },
}

export const useInstructorProfile = ({
    instructorId,
    studentAccountsCreatedParams: {
        type,
        start,
        end,
    },
}: IUseInstructorProfile) => {
    const queries = useQueries([
        {
            queryKey: ["instructor", instructorId],
            queryFn: () => instructorService.getInstructor(instructorId),
            onError: (error: any) => {
                console.log("Error on getting instructor - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        },
        {
            queryKey: ["student-accounts-created", instructorId, {
                type,
                start,
                end,
            }],
            queryFn: () => instructorService.getStudentAccountsCreated(
                instructorId,
                {
                    group_type: type,
                    start,
                    end,
                },
            ),
            onError: (error: any) => {
                console.log("Error on getting student accounts created data - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
            placeholderData: [], // Used to avoid loading state on type change
        },
    ]);

    return {
        isLoading: queries.some(({ isLoading }) => isLoading),
        instructor: queries[0]?.data as IInstructor,
        studentAccountsCreated: queries[1]?.data as Array<IStudentAccountsCreated>,
    };
}
