import { format, isAfter, isToday, isValid, isYesterday } from "date-fns";
import { t } from "core/utils/multi-language-support";
import { DateFormat } from "core/enums/date-format.enum";

export function formatStudentActivity(lastActiveDate: string) {
    if (!lastActiveDate) return "-";

    const studentActivityDate = new Date(lastActiveDate);
    if (
        !isValid(studentActivityDate)
        || isAfter(studentActivityDate, new Date())
    ) return "-";

    if (isToday(studentActivityDate)) return t("today");
    if (isYesterday(studentActivityDate)) return t("yesterday");

    return format(
        studentActivityDate,
        DateFormat.ddMMyyyy,
    );
}
