import { useInfiniteQuery } from "react-query";
import { themeService } from "core/services/theme.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { sharedStudentService } from "core/pages/SharedStudentProfile/service/shared-student.service";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IUseThemes {
    key: string;
    studentId?: string;
    studentToken?: string;
}

export const useThemes = ({
    key,
    studentId,
    studentToken,
}: IUseThemes) => {
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
        isError,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["themes", key],
        ({ pageParam = 1 }) => {
            if (pageParam === undefined) return; // No prev or next page

            return studentToken && isSharedRoute
                ? sharedStudentService.getThemes({
                    page: pageParam,
                    limit: 20,
                    studentToken,
                }) : themeService.getThemes({
                    page: pageParam,
                    limit: 20,
                    studentId,
                });
        },
        {
            onError: (err: Error) => {
                console.log("Error on getting themes - ", err);
            },
            getPreviousPageParam: (firstPage) => {
                if (firstPage.currentPage > 1) {
                    return firstPage.currentPage - 1;
                }

                return undefined;
            },
            getNextPageParam: (lastPage) => {
                if (lastPage.currentPage < lastPage.totalPages) {
                    return lastPage.currentPage + 1;
                }

                return undefined;
            },
            keepPreviousData: true,
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    };
}
