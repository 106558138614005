import { AvailableLanguageKeys } from "core/enums/available-language-keys.enum";
import { getCurrentLanguage } from "core/utils/multi-language-support";
import { IThemeTranslation, ITranslations } from "../translation/translation.model";
import { IUserProgress } from "../user/user-progress.model";

export interface ITheme {
    id: string;
    translations: ITranslations<IThemeTranslation>;
    media: string;
    numberOfLessons: number;
    includedInTrialVersionOnly: boolean;
    userProgress: IUserProgress;
}

export const createTheme = ({ id, ...props }: Partial<ITheme>) => {
    if (!id) {
        throw new Error('Unable to create a theme without an ID!');
    }

    return {
        id,
        translations: null,
        media: "",
        numberOfLessons: null,
        includedInTrialVersionOnly: false,
        userProgress: null,
        ...props,
    } as ITheme;
};

export const getThemeName = (translations: ITranslations<IThemeTranslation>): string => {
    const appLang = getCurrentLanguage();

    switch (appLang) {
        case AvailableLanguageKeys.EN: return translations.en.name;
        case AvailableLanguageKeys.DE: return translations.de.name;
        default: return "";
    }
};
