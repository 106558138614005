import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import { PageContainer } from "modules/sw-admin/components/PageContainer";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { Toolbar } from "modules/home/components/Toolbar";
import { useExportData, ExportEntityType } from "../hooks/use-export-data.hook";
import Loader from "core/components/Loader";
import { useSnackbar } from "core/hooks/use-snackbar.hook";

export const DashboardPage = () => {
    const { t } = useTranslation();
    const {
        exportData,
        isLoading,
        serverErrorMessage,
    } = useExportData();

    const { setSnackbarProps, clearSnackbarProps } = useSnackbar();
    useEffect(() => {
        if (!serverErrorMessage) return;

        setSnackbarProps({
            open: true,
            message: serverErrorMessage,
            alertType: "error",
            onClose: clearSnackbarProps,
        });

        return () => clearSnackbarProps();
    }, [serverErrorMessage])

    return (
        <PageContainer>
            <ContentContainer>
                <Box mb="32px">
                    <Toolbar
                        title={t("export-data")}
                    />
                </Box>

                <Box
                    display="grid"
                    flexDirection="column"
                    gap={2}
                    width="fit-content"
                >
                    <Button
                        label={t("export-instructors")}
                        onClick={() => exportData(ExportEntityType.INSTRUCTORS)}
                        disabled={isLoading}
                    />
                    <Button
                        label={t("export-driving-schools")}
                        onClick={() => exportData(ExportEntityType.DRIVING_SCHOOLS)}
                        disabled={isLoading}
                    />
                    <Button
                        label={t("export-students")}
                        onClick={() => exportData(ExportEntityType.STUDENTS)}
                        disabled={isLoading}
                    />
                    <Button
                        label={t("export-watched-videos")}
                        onClick={() => exportData(ExportEntityType.WATCHED_VIDEOS)}
                        disabled={isLoading}
                    />
                </Box>
            </ContentContainer>
            {isLoading && <Loader />}
        </PageContainer>
    );
}
