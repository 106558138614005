import { useMemo } from "react";
import { ITab } from "core/components/Tabs";
import { AppVersion } from "core/enums/app-version.enum";
import { StudentAccountType } from "../enums/student-account-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";

export interface IStudentTab extends ITab {
    appVersion: AppVersion;
}

export const useStudentTabs = () => {
    const { t } = useTranslation();
    const tabs: Array<IStudentTab> = useMemo(() => ([
        {
            id: StudentAccountType.OFFICIAL_ACCOUNTS,
            name: t("official-accounts"),
            appVersion: AppVersion.FULL,
        },
        {
            id: StudentAccountType.TRIAL_PERIOD,
            name: t("trial-period"),
            appVersion: AppVersion.TRIAL,
        },
    ]), []);

    return tabs;
}
