import { useMutation, useQueryClient } from "react-query";
import { format, isValid } from "date-fns";
import { DateFormat } from "core/enums/date-format.enum";
import { ISearch } from "core/models/search/search.model";
import { createEditStudentCommand, IEditStudentCredentialsCommand } from "core/models/student/student.command";
import { IStudent } from "core/models/student/student.model";
import { studentService } from "core/services/student.service";
import { createDate } from "core/utils/date-handler";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { IEditStudentCredentials } from "../models/edit-student.model";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { getItemFromLocalStorage } from "core/utils/local-storage-handler";

interface IUseEditStudent {
    studentId: string;
}

export const useEditStudent = ({ studentId }: IUseEditStudent) => {
    const studentsQueryKey = getItemFromLocalStorage(LocalStorageKeys.studentsQueryKey);

    const queryClient = useQueryClient();
    const {
        mutateAsync: editStudent,
        isError,
        error,
        isLoading,
    } = useMutation(
        (credentials: IEditStudentCredentials) => {
            const commandCredentials: IEditStudentCredentialsCommand = createEditStudentCommand(credentials);

            return studentService.editStudent(studentId, commandCredentials);
        },
        {
            onMutate: async (mutatedStudent: IEditStudentCredentials) => {
                await queryClient.cancelQueries(studentsQueryKey);

                const dateOfBirth = createDate(
                    mutatedStudent.day,
                    mutatedStudent.month,
                    mutatedStudent.year,
                );

                const updatedStudent = {
                    name: mutatedStudent.first_name,
                    familyName: mutatedStudent.last_name,
                    dayOfBirth: isValid(dateOfBirth) ? format(
                        dateOfBirth,
                        DateFormat.ddMMyyyy
                    ) : "",
                    gender: mutatedStudent.custom_gender,
                };

                const previousStudent: IStudent = queryClient.getQueryData(["student", studentId]);
                if (previousStudent) {
                    queryClient.setQueryData(
                        ["student", studentId],
                        (previousData: IStudent) => {
                            return {
                                ...previousData,
                                ...updatedStudent,
                            };
                        }
                    );
                }

                const previousStudents: ISearch<IStudent> = queryClient.getQueryData(studentsQueryKey);
                if (previousStudents) {
                    queryClient.setQueryData(
                        studentsQueryKey,
                        (previousData: ISearch<IStudent>) => {
                            const { items, ...restPreviousData } = previousData;

                            return {
                                ...restPreviousData,
                                items: items.map((item) => {
                                    if (item.id === studentId) {
                                        return {
                                            ...item,
                                            ...updatedStudent,
                                        };
                                    }

                                    return item;
                                }),
                            };
                        }
                    );
                }

                return () => {
                    queryClient.setQueryData(["student", studentId], previousStudent);
                    queryClient.setQueryData(studentsQueryKey, previousStudents);
                };
            },
            onError: (error: Error, mutatedStudent: IEditStudentCredentials, rollback: CallableFunction) => {
                console.log("Error on editing student - ", error);

                rollback && rollback();
            },
            onSettled: () => {
                queryClient.invalidateQueries(studentsQueryKey);
            },
        }
    );

    return {
        editStudent,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
