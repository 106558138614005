import React from "react";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { RGBAColors } from "styles/variables/rgba-colors";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "assets/icons";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";

interface IProps {
    isPassed: boolean;
    startedAt: string;
    studentId: string;
    studentToken: string;
    simulationId: string;
}

export const SimulatorProgressItem = ({
    isPassed,
    startedAt,
    studentId,
    studentToken,
    simulationId,
}: IProps) => {
    const { t } = useTranslation();
    const { isSharedRoute } = useIsSharedRoute();

    return (
        <Link
            to={(isSharedRoute && studentToken) ? pathBuilder(
                SHARED_ROUTES.STUDENT_SIMULATOR_PROGRESS_DETAILS,
                ":studentToken/simulator-progress/:simulationId",
                `${studentToken}/simulator-progress/${simulationId}`,
            ) as SHARED_ROUTES : pathBuilder(
                ROUTES.STUDENT_SIMULATOR_PROGRESS_DETAILS,
                ":studentId/simulator-progress/:simulationId",
                `${studentId}/simulator-progress/${simulationId}`,
            ) as ROUTES}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                className={styles.simulatorProgressItemContainer}
            >
                <Box
                    display="grid"
                    flexDirection="column"
                    gap="4px"
                >
                    <Text
                        color={isPassed ? "green" : "red"}
                        bold
                    >
                        {(isPassed ? t("passed") : t("failed")).toUpperCase()}
                    </Text>
                    <Text
                        size="small"
                        style={{ color: RGBAColors.lightBlackWithOpacity(0.6) }}
                    >
                        {startedAt}
                    </Text>
                </Box>
                <ChevronRightIcon />
            </Box>
        </Link>
    );
}
