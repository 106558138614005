import React from "react";
import "./loader-styles.scss";

const TOTAL_LOADER_CIRCLES = 12;

export const Loader = () => {
    return (
        <div className="overlay">
            <div className="wrapper">
                <div className="circle">
                    {[...new Array(TOTAL_LOADER_CIRCLES).fill(null).map((_, index) => (
                        <div key={index} className={`circle${index + 1} circle-child`} />
                    ))]}
                </div>
            </div>
        </div>
    );
}
