import { ROUTES } from "modules/home/enums/routes.enum";
import { INavigationItem } from "../interfaces/navigation-item.interface";
import {
    DashboardIcon,
    StudentsIcon,
    ThemesIcon,
    NotificationsIcon,
} from "assets/icons/navigation-bar";
import { DashboardPage } from "modules/dashboard/pages";
import { StudentsPage } from "modules/students/pages";
import { ThemesPage } from "modules/themes/pages";
import { Notifications } from "core/components/Notifications";
import PageContainer from "../components/PageContainer";

export const instructorNavigationItems: INavigationItem[] = [
    {
        path: ROUTES.DASHBOARD,
        exactPath: true,
        translateKey: "dashboard",
        Icon: DashboardIcon,
        Component: DashboardPage,
        enabled: true,
    },
    {
        path: ROUTES.STUDENTS,
        exactPath: false,
        translateKey: "students",
        Icon: StudentsIcon,
        Component: StudentsPage,
        enabled: true,
    },
    {
        path: ROUTES.THEMES,
        exactPath: false,
        translateKey: "themes",
        Icon: ThemesIcon,
        Component: ThemesPage,
        enabled: true,
    },
    {
        path: ROUTES.NOTIFICATIONS,
        exactPath: true,
        translateKey: "notifications",
        Icon: NotificationsIcon,
        Component: () => (
            <PageContainer>
                <Notifications />
            </PageContainer>
        ),
        enabled: true,
    },
];
