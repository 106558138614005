/**
 * Returns abbreviate number for some huge number.
 * example:
 *      1234 => 1.2K
 *      1234567 => 1.2M
 *
 * Taken from:
 *      https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn/32638472
 */
 export function abbreviateNumber(num: any, fixed = 0) {
    if (!num || num === 0) return "0";

    const suffixes = ["", "K", "M", "B", "T"];
    const precision = fixed < 0 ? 0 : fixed; // number of decimal places to show

    const b = num.toPrecision(2).split("e"); // get power
    const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
    const c = k < 1
        ? num.toFixed(0 + precision)
        : (num / Math.pow(10, k * 3)).toFixed(1 + precision); // divide by power
    const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0

    const abbreviatedNumber = d + suffixes[k];

    return abbreviatedNumber;
}
