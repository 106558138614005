import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { FormSelect, IFormSelectProps } from "core/components/FormSelect";
import { LearningActivityChart } from "./LearningActivityChart";
import { IStudentLearningActivity } from "core/models/student/student-learning-activity.model";

interface IProps extends IFormSelectProps {
    chartData: Array<IStudentLearningActivity>;
}

export const LearningActivity = ({
    chartData,
    options,
    value,
    onChange,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="grid"
            flexDirection="column"
            gap="38px"
            width="65%"
            maxWidth="765px"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={4}
                width={1}
            >
                <Box width="30%">
                    <Text size="large">
                        {t("learning-activity")}
                    </Text>
                </Box>

                <Box
                    width="70%"
                    minWidth="228px"
                    maxWidth="448px"
                >
                    <FormSelect
                        name="learning_activity"
                        value={value}
                        options={options}
                        onChange={onChange}
                    />
                </Box>
            </Box>

            <LearningActivityChart data={chartData} />
        </Box>
    );
}
