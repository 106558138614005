import React from "react";
import { useRouteQueryParams } from "core/hooks/use-route-query-params.hook";
import { Layout } from "modules/login/components/Layout";
import { CreateNewPassword } from "./CreateNewPassword";
import { RequestResetPassword } from "./RequestResetPassword";

export const ResetPasswordPage = () => {
    const routeQueryParams = useRouteQueryParams();
    const code = routeQueryParams.get("code");

    return (
        <Layout>
            {code ? (
                <CreateNewPassword code={code} />
            ) : (
                <RequestResetPassword />
            )}
        </Layout>
    );
}
