import { useMemo } from "react";
import { object } from "yup";
import {
    stringValidationSchema,
    emailValidationSchema,
    passwordValidationSchema,
    confirmPasswordValidationSchema,
} from "core/utils/form-validation-schema";

export function useSignUpValidationSchema() {
    return useMemo(
        () =>
            object().shape({
                driving_school_name: stringValidationSchema(),
                address: stringValidationSchema(),
                zip_code: stringValidationSchema(),
                city: stringValidationSchema(),
                email: emailValidationSchema(),
                password: passwordValidationSchema(),
                confirm_password: confirmPasswordValidationSchema("password"),
            }),
        []
    );
}

export function useSignInValidationSchema() {
    return useMemo(
        () =>
            object().shape({
                email: emailValidationSchema(),
                password: passwordValidationSchema(false),
            }),
        []
    );
}

export function useResetPasswordValidationSchema() {
    return useMemo(
        () =>
            object().shape({
                email: emailValidationSchema(),
            }),
        []
    );
}

export function useCreateNewPasswordValidationSchema() {
    return useMemo(
        () =>
            object().shape({
                email: emailValidationSchema(),
                password: passwordValidationSchema(),
                confirm_password: confirmPasswordValidationSchema("password"),
            }),
        []
    );
}
