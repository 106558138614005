import { roundNumber } from "./round-number";

export const calculatePercentage = (value: number, total: number) => {
    if (total <= 0) {
        return 0;
    }

    const result = (value / total) * 100;

    return roundNumber(result);
}
