import React, { FC, useMemo } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";

export interface IStyledFormSelectProps {
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
}

export const StyledFormSelect: FC<IStyledFormSelectProps> = ({
    backgroundColor,
    borderColor,
    textColor,
    children,
}) => {
    const theme = useMemo(() => {
        const baseTheme = createTheme({
            typography: {
                fontFamily: "NunitoSansRegular",
            },
        });

        return createTheme(baseTheme, {
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            fontSize: "14px",
                            borderRadius: "8px",
                            backgroundColor: backgroundColor ?? colors.inputGray,
                            minHeight: "43px",
                            maxHeight: "43px",
                            width: "100%",

                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: borderColor ?? "transparent",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: borderColor ?? "transparent",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "1px",
                                borderColor: borderColor ?? "transparent",
                            },
                        },
                        input: {
                            minHeight: "unset !important",
                            color: textColor ?? colors.lightBlack,
                            padding: "14px !important",
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        icon: {
                            right: "16px",
                            top: "unset",
                            width: "24px",
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            borderRadius: "8px",
                            border: `1px solid ${RGBAColors.lightBlackWithOpacity(0.12)}`,
                            boxShadow: "unset",
                            maxHeight: "220px !important",
                        },
                    },
                },
                MuiList: {
                    styleOverrides: {
                        root: {
                            padding: "0px",
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            fontSize: "14px",
                            color: colors.lightBlack,
                            padding: "12px 16px",

                            "&:hover": {
                                backgroundColor: `${colors.inputGray} !important`,
                            },
                            "&.Mui-selected": {
                                backgroundColor: `${colors.inputGray} !important`,
                            },
                            "&.Mui-selected:hover": {
                                backgroundColor: `${colors.inputGray} !important`,
                            },
                            "&:not(:last-child)": {
                                borderBottom: `1px solid ${RGBAColors.lightBlackWithOpacity(0.12)}`,
                            },
                        },
                    },
                },
            },
        });
    }, [
        backgroundColor,
        borderColor,
        textColor,
    ]);

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
