import React, { useMemo } from "react";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

export type CardSize = "small" | "large";

export interface ICardItemProps {
    title: string;
    description: string;
    imageURL: string;
    path: string;
    size: CardSize;
}

const DESCRIPTION_CONTAINER_MIN_HEIGHT = 70;

export const CardItem = ({
    title,
    description,
    imageURL,
    path,
    size,
}: ICardItemProps) => {
    const { height } = useMemo(() => {
        switch (size) {
            case "small": return {
                height: 250,
            };
            case "large": return {
                height: 410,
            };
            default: return {
                height: 300,
            };
        }
    }, [size]);

    return (
        <Link
            to={path}
            className={styles.cardItemContainer}
            style={{
                minHeight: `${height}px`,
            }}
        >
            <img
                src={imageURL}
                alt={`${title} image`}
                loading="lazy"
                className={styles.imageContainer}
                style={{
                    minHeight: `${height - DESCRIPTION_CONTAINER_MIN_HEIGHT}px`,
                    maxHeight: `${height - DESCRIPTION_CONTAINER_MIN_HEIGHT}px`,
                }}
            />
            <div className={styles.descriptionContainer}>
                <Text>{title}</Text>
                <Text size="small" color="gray">
                    {description}
                </Text>
            </div>
        </Link>
    );
}
