import { DateFormat } from "core/enums/date-format.enum";
import { format } from "date-fns";
import { LearningActivityType } from "modules/students/enums/learning-activity-type.enum";
import { createStudentLearningActivity, IStudentLearningActivity } from "./student-learning-activity.model";

export interface IStudentLearningActivityResponse {
    answered: number;
    correct: number;
    incorrect: number;
    answer_rate: number;
    correct_rate: number;
    incorrect_rate: number;
    for_date: string;
    type: string;
}

export const createStudentLearningActivityFromResponse = ({
    answer_rate,
    correct_rate,
    incorrect_rate,
    for_date,
    type,
    ...props
}: IStudentLearningActivityResponse): IStudentLearningActivity => {
    return createStudentLearningActivity({
        answerRate: answer_rate ?? null,
        correctRate: correct_rate ?? null,
        incorrectRate: incorrect_rate ?? null,
        forDate: for_date ? format(new Date(for_date), DateFormat.ddMMM) : "",
        type: type as LearningActivityType,
        ...props,
    });
};
