import React, { useEffect, useMemo } from "react";
import { Redirect, Switch, useParams } from "react-router-dom";
import PageContainer from "modules/home/components/PageContainer";
import { ROUTES } from "modules/home/enums/routes.enum";
import { PublicRoute } from "core/components/Routes";
import { StudentProfile } from "modules/students/pages/StudentProfile";
import { StudentOverallProgress } from "modules/students/pages/StudentOverallProgress";
import { SimulatorProgressItemDetails } from "modules/students/pages/StudentSimulatorProgress/components/SimulatorProgressItemDetails";
import { SimulatorProgressList } from "modules/students/pages/StudentSimulatorProgress/components/SimulatorProgressList";
import { SHARED_PROGRESS_PATH, SHARED_ROUTES } from "./enums/app-routes.enum";
import { instructorNavigationItems } from "modules/home/constants/navigation-items";
import { IStudentParams } from "core/interfaces/student-params.interface";
import { getItemFromLocalStorage, removeItemFromLocalStorage } from "core/utils/local-storage-handler";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import NavigationBar from "modules/home/components/NavigationBar";
import homePageStyles from "modules/home/pages/styles.module.scss";

/**
 * This component is used as a public route so driving schools can share their students profile without login.
 *
 * ! Page visitors can only READ the data, other actions are forbidden!
 */
export const SharedStudentProfile = () => {
    const { studentToken } = useParams<IStudentParams>();
    const formattedNavigationItems = useMemo(() => {
        return instructorNavigationItems
            .map((item) => {
                if (item.path === ROUTES.STUDENTS) {
                    return {
                        ...item,
                        path: (`${SHARED_PROGRESS_PATH}/${studentToken}` as unknown) as SHARED_ROUTES,
                    };
                }

                return { ...item, enabled: false };
            });
    }, []);

    useEffect(() => {
        const user = getItemFromLocalStorage(LocalStorageKeys.user);
        user && removeItemFromLocalStorage(LocalStorageKeys.user);
    }, []);

    return (
        <main className={homePageStyles.mainContainer}>
            <div className={homePageStyles.navigationBarWrapper}>
                <NavigationBar navigationItems={formattedNavigationItems} />
            </div>
            <div className={homePageStyles.pageContentWrapper}>
                <PageContainer fetchHeaderData={false}>
                    <Switch>
                        <PublicRoute
                            exact
                            path={SHARED_ROUTES.STUDENT_PROFILE}
                            component={StudentProfile}
                        />
                        <PublicRoute
                            exact
                            path={SHARED_ROUTES.STUDENT_OVERALL_PROGRESS}
                            component={StudentOverallProgress}
                        />
                        <Switch>
                            <PublicRoute
                                exact
                                path={SHARED_ROUTES.STUDENT_SIMULATOR_PROGRESS}
                                component={SimulatorProgressList}
                            />
                            <PublicRoute
                                exact
                                path={SHARED_ROUTES.STUDENT_SIMULATOR_PROGRESS_DETAILS}
                                component={SimulatorProgressItemDetails}
                            />
                            <Redirect from="*" to={ROUTES.NOT_FOUND} />
                        </Switch>
                        <Redirect from="*" to={ROUTES.NOT_FOUND} />
                    </Switch>
                </PageContainer>
            </div>
        </main>
    );
}
