import { createLessonFromResponse, ILessonResponse } from "../lesson/lesson.response";
import { createThemeLessons, IThemeLessons } from "./theme-lessons.model";
import { IThemeResponse } from "./theme.response";

export interface IThemeLessonsResponse extends Pick<IThemeResponse, "id" | "number_of_lessons" | "translations"> {
    lessons: Array<ILessonResponse>;
}

export const createThemeLessonsFromResponse = ({
    id,
    lessons,
    number_of_lessons,
    ...props
}: IThemeLessonsResponse): IThemeLessons => {
    return createThemeLessons({
        id: id?.toString(),
        numberOfLessons: number_of_lessons,
        lessons: lessons?.map(createLessonFromResponse) ?? [],
        ...props,
    });
};
