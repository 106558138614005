export interface IQuizAccessData {
    createdAt: string;
    expiresAt: string;
    token: string;
    used: boolean;
}

export const createQuizAccessData = ({ ...props }: Partial<IQuizAccessData>) => {
    return {
        createdAt: "",
        expiresAt: "",
        token: "",
        used: undefined,
        ...props,
    } as IQuizAccessData;
};
