export enum API_ROUTES {
    ME = '/me',
    TOKEN = '/token',
    REFRESH_TOKEN = '/token/refresh/{refreshToken}',
    REGISTER = '/register',
    REQUEST_PASSWORD_RESET = '/reset-password',
    RESET_PASSWORD = '/reset-password/{code}',
    DRIVING_SCHOOLS_SEARCH = '/driving-schools/search',
    STUDENTS = '/students',
    STUDENT = '/students/{studentId}',
    STUDENT_LEARNING_ACTIVITY_PROGRESS = '/user-progress/chart/{studentId}',
    STUDENT_DIFFICULT_LESSONS = '/practice-mores/difficult-lessons/{studentId}',
    THEMES = '/themes',
    THEME_LESSONS = '/themes/{themeId}',
    LESSONS_TAGS_SEARCH = '/tags-search',
    LESSON = '/lessons/{lessonId}',
    TOTAL_LESSONS = '/lessons/total',
    NOTIFICATIONS = '/notifications',
    READ_NOTIFICATION = '/notifications/read/{notificationId}',
    SIMULATIONS = '/simulations',
    SIMULATION = '/simulationsV2/{simulationId}',
    INSTRUCTORS = '/instructors',
    INSTRUCTOR = '/instructors/{instructorId}',
    STUDENT_ACCOUNTS_CREATED = '/instructors/accounts-created-chart/{instructorId}',
    CHANGE_INSTRUCTOR_STATUS = '/instructors/change-status/{instructorId}/{status}',
    TOGGLE_ACTIVE_USER = '/users/toggle-active/{userId}/{isActive}',
    CHANGE_USER_STATUS = '/users/change-status/{userId}/{status}',
    REACTIVATE_STUDENT = '/students/reactivate/{studentId}',
    EXPORT_DRIVING_SCHOOLS = '/export/driving-schools',
    EXPORT_INSTRUCTORS = '/export/instructors',
    EXPORT_STUDENTS = '/export/students',
    EXPORT_WATCHED_LESSONS = '/export/watched-lessons',
    ADMIN_QUIZ_HASH = '/admin-quiz/hash/{lessonId}',
}
