import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { ISearchParams, SortDirection } from "core/interfaces/search-params.interface";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { InstructorAccountType } from "../enums/instructor-account-type.enum";
import { instructorService } from "core/services/instructor.service";

interface IUseInstructors {
    type: InstructorAccountType;
    searchParams?: ISearchParams;
}

export const useInstructors = ({
    type,
    searchParams: {
        page = 1,
        limit = 10,
        direction = SortDirection.DESC,
    },
}: IUseInstructors) => {
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
        isPreviousData,
    } = useQuery(
        ["instructors", type, { page }],
        () => instructorService.getInstructors({
            page,
            limit,
            direction,
        }, type),
        {
            onError: (error: Error) => {
                console.log(`Error on getting ${type} instructors - `, error);
            },
            keepPreviousData: true,
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        isFetchingNextPage: isFetching && isPreviousData,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
