import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { TUser } from "core/models/user/user.model";
import { SHARED_PROGRESS_PATH } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { clearLocalStorage, setItemToLocalStorage } from "core/utils/local-storage-handler";
import { ROUTES } from "modules/home/enums/routes.enum";
import { userService } from "./user.service";

export function initializeApiInterceptors(httpClient: AxiosInstance): void {
    interceptRequest(httpClient);
    interceptResponse(httpClient);
}

function appendToken(config: AxiosRequestConfig, token: string | null): void {
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
}

function interceptRequest(httpClient: AxiosInstance): void {
    httpClient.interceptors.request.use(
        (config) => {
            const { token } = userService.getToken();

            appendToken(config, token);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
}

function interceptResponse(httpClient: AxiosInstance): void {
    httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response) {
                if (error.message === 'Network Error') {
                    console.error('Network error - server is probably down');
                    throw error;
                } else {
                    console.error('Unknown error', error);
                    throw error;
                }
            } else {
                const { refreshToken } = userService.getToken();

                switch (error.response.status) {
                    case 401: // Unauthorized
                    case 403: // Forbidden
                        if ([ROUTES.SIGN_IN, ROUTES.SIGN_UP, ROUTES.RESET_PASSWORD].includes(
                            window.location.pathname as ROUTES
                        ) || window.location.pathname.startsWith(SHARED_PROGRESS_PATH)) {
                            return error.response;
                        }

                        if (!refreshToken) {
                            clearLocalStorage();
                            window.location.href = ROUTES.SIGN_IN;
                        } else {
                            return userService
                                .refreshToken(refreshToken)
                                .then(({ token, refreshToken: newRefreshToken, user }: TUser) => {
                                    setItemToLocalStorage(
                                        LocalStorageKeys.user,
                                        {
                                            token,
                                            refreshToken: newRefreshToken,
                                            role: user.roles[0],
                                        }
                                    );

                                    return token;
                                })
                                .then((token: string) => {
                                    appendToken(
                                        error.config,
                                        token,
                                    );

                                    return axios.request(error.config);
                                })
                                .catch(() => {
                                    clearLocalStorage();
                                    window.location.href = ROUTES.SIGN_IN;
                                });
                        }
                        break;
                }
            }

            return error.response;
        }
    );
}
