import { ISearchParams } from "core/interfaces/search-params.interface";
import { createLessonFromResponse } from "core/models/lesson/lesson.response";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { ISimulation } from "core/models/simulation/simulation.model";
import {
    createSimulationFromResponse,
    createSimulationsFromResponse,
    ISimulationResponse,
} from "core/models/simulation/simulation.response";
import { IStudentDifficultLesson } from "core/models/student/student-difficult-lesson.model";
import { IStudentDifficultLessonResponse } from "core/models/student/student-difficult-lesson.response";
import { IStudentLearningActivity } from "core/models/student/student-learning-activity.model";
import {
    createStudentLearningActivityFromResponse,
    IStudentLearningActivityResponse,
} from "core/models/student/student-learning-activity.response";
import { IStudent } from "core/models/student/student.model";
import { createStudentFromResponse, IStudentResponse } from "core/models/student/student.response";
import { ITheme } from "core/models/theme/theme.model";
import { createThemesFromResponse, IThemeResponse } from "core/models/theme/theme.response";
import { pathBuilder } from "core/utils/path-builder";
import { LearningActivityType } from "modules/students/enums/learning-activity-type.enum";
import { apiService } from "../../../services/api-service";
import { SHARED_API_ROUTES } from "../enums/api-routes.enum";

class SharedStudentService {
    async getStudent(studentToken: string): Promise<IStudent> {
        const response = apiService.responseHandler(
            await apiService.get<IStudentResponse>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT,
                    "{token}",
                    studentToken
                ),
            )
        );

        return createStudentFromResponse(response);
    }

    async getLearningActivityProgress(
        studentToken: string,
        type: LearningActivityType,
        pastPeriod: number,
    ): Promise<Array<IStudentLearningActivity>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IStudentLearningActivityResponse>>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT_LEARNING_ACTIVITY_PROGRESS,
                    "{token}",
                    studentToken
                ),
                {
                    params: {
                        type,
                        past_period: pastPeriod,
                    },
                },
            )
        );

        return response?.map(createStudentLearningActivityFromResponse) ?? [];
    }

    async getDifficultLessons(
        studentToken: string,
        searchParams?: ISearchParams,
    ): Promise<Array<IStudentDifficultLesson>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IStudentDifficultLessonResponse>>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT_DIFFICULT_LESSONS,
                    "{token}",
                    studentToken
                ),
                {
                    params: { ...searchParams },
                },
            )
        );

        return response?.map(({ incorrect_questions_rate, lesson }) => ({
            incorrectQuestionsRate: incorrect_questions_rate,
            lesson: createLessonFromResponse(lesson),
        }));
    }

    async getThemes({
        studentToken,
        ...searchParams
    }: ISearchParams & { studentToken: string }): Promise<ISearch<ITheme>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<IThemeResponse>>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT_THEMES,
                    "{token}",
                    studentToken
                ),
                {
                    params: { ...searchParams },
                }
            )
        );

        return createThemesFromResponse(response);
    }

    async getSimulations({
        studentToken,
        ...searchParams
    }: ISearchParams & { studentToken: string }): Promise<ISearch<ISimulation>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<ISimulationResponse>>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT_SIMULATIONS,
                    "{token}",
                    studentToken
                ),
                {
                    params: { ...searchParams },
                }
            )
        );

        return createSimulationsFromResponse(response);
    }

    async getSimulation(
        simulationId: string,
        studentToken: string,
    ): Promise<ISimulation> {
        const response = apiService.responseHandler(
            await apiService.get<ISimulationResponse>(
                pathBuilder(
                    SHARED_API_ROUTES.STUDENT_SIMULATION,
                    "{simulationId}/{token}",
                    `${simulationId}/${studentToken}`
                ),
            )
        );

        return createSimulationFromResponse(response);
    }
}

export const sharedStudentService = new SharedStudentService();
