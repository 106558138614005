export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export interface ISearchParams {
    query?: string;
    orderBy?: string;
    direction?: SortDirection;
    limit?: number;
    page?: number;
}
