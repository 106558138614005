import { API_ROUTES } from "core/enums/api-routes.enum";
import { ROUTES } from "modules/home/enums/routes.enum";
import { SHARED_API_ROUTES } from "core/pages/SharedStudentProfile/enums/api-routes.enum";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";

type Path = API_ROUTES | SHARED_API_ROUTES | ROUTES | SHARED_ROUTES;

export function pathBuilder(
    path: Path,
    paramToReplace: string,
    paramValue: string
): string {
    return path.replace(paramToReplace, paramValue);
}
