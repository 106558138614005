import React from "react";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import styles from "./styles.module.scss";
import { useToggleActiveStudent } from "modules/students/hooks/use-toggle-active-student.hook";
import { useDialog } from "core/hooks/use-dialog.hook";
import { useConfirmationDialog } from "core/hooks/use-confirmation-dialog.hook";

interface IProps {
    studentId: string;
    disabled: boolean;
}

export const DeleteStudentButton = ({
    studentId,
    disabled,
}: IProps) => {
    const { t } = useTranslation();
    const { toggleActiveStudent } = useToggleActiveStudent();
    const { setDialogProps } = useDialog();

    const deleteDialogProps = useConfirmationDialog({
        type: "error",
        title: t("delete-student-title"),
        description: t("delete-student-description"),
        actionButtonTitle: t("delete-student"),
        onConfirm: () => {
            toggleActiveStudent({
                studentId: studentId,
                isActive: false,
            });
        },
    });

    return (
        <Button
            label={t("delete-student")}
            className={styles.deleteButton}
            onClick={() => setDialogProps({ ...deleteDialogProps })}
            disabled={disabled}
        />
    );
}
