import { AvailableLanguageKeys } from "core/enums/available-language-keys.enum";
import { getCurrentLanguage } from "core/utils/multi-language-support";
import { ITheme } from "../theme/theme.model";
import { ILessonTranslation, ITranslations } from "../translation/translation.model";
import { IQuestion } from "./lesson-question.model";
import { ITag } from "./lesson-tag.model";

export interface ILesson extends Omit<ITheme, "includedInTrialVersionOnly" | "numberOfLessons" | "translations"> {
    mediaDuration: number;
    numberOfQuestions: number;
    translations: ITranslations<ILessonTranslation>;
    theme?: ITheme;
    questions?: Array<IQuestion>;
    tags?: Array<ITag>;
    totalQuestions?: number;
    video?: string;
}

export const createLesson = ({ id, ...props }: Partial<ILesson>) => {
    if (!id) {
        throw new Error('Unable to create a lesson without an ID!');
    }

    return {
        id,
        translations: null,
        media: "",
        mediaDuration: null,
        numberOfQuestions: null,
        userProgress: null,
        theme: null,
        questions: [],
        tags: [],
        totalQuestions: null,
        video: null,
        ...props,
    } as ILesson;
};

export const getLessonName = (
    translations: ITranslations<ILessonTranslation>,
    language?: AvailableLanguageKeys,
): ILessonTranslation => {
    if (!language) {
        language = getCurrentLanguage();
    }

    switch (language) {
        case AvailableLanguageKeys.EN: return translations.en;
        case AvailableLanguageKeys.DE: return translations.de;
        default: return {} as ILessonTranslation;
    }
};
