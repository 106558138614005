import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import FormWrapper from "core/components/FormWrapper";
import { useUser } from "modules/home/hooks/use-user.hook";
import { useUserProfileFormValidationSchema } from "modules/home/hooks/use-user-profile-form-validation-schema.hook";
import { defaultUserProfileFormValues, IUserProfileFormModel } from "modules/home/models/user-profile-form.model";
import Text from "core/components/Text";

export const UserProfileForm = () => {
    const { user, isLoading: isUserLoading } = useUser();
    const { t } = useTranslation();

    const userFormValidationSchema = useUserProfileFormValidationSchema();
    const {
        control,
        setValue,
    } = useForm<IUserProfileFormModel>({
        mode: "onChange",
        resolver: yupResolver(userFormValidationSchema),
        defaultValues: defaultUserProfileFormValues,
    });

    useEffect(() => {
        if (!user || isUserLoading) return;

        setValue("driving_school_name", user.drivingSchool?.name ?? "");
        setValue("address", user.drivingSchool?.address ?? "");
        setValue("zip_code", user.drivingSchool?.postalCode ?? "");
        setValue("city", user.drivingSchool?.city ?? "");
        setValue("email", user.email);
    }, [user, isUserLoading]);

    return (
        <FormWrapper
            title={t("my-profile")}
            submitButtonProps={{
                label: t("save-changes"),
                disabled: true,
                isLoading: isUserLoading,
            }}
        >
            <Box display="grid" flexDirection="column" gap={2}>
                <Controller
                    name="driving_school_name"
                    control={control}
                    render={({ field: { ref,...restFieldProps }, fieldState: { error } }) => (
                        <FormInput<IUserProfileFormModel>
                            inputRef={ref}
                            label={t("driving-school-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("driving-school-name-placeholder")}
                            error={error?.message}
                            readOnly
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="address"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IUserProfileFormModel>
                            inputRef={ref}
                            label={t("address")}
                            type={FormInputType.TEXT}
                            placeholder={t("address-placeholder")}
                            error={error?.message}
                            readOnly
                            {...restFieldProps}
                        />
                    )}
                />
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Controller
                        name="zip_code"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<IUserProfileFormModel>
                                inputRef={ref}
                                label={t("zip-code")}
                                type={FormInputType.NUMBER}
                                placeholder={t("zip-code-placeholder")}
                                error={error?.message}
                                readOnly
                                style={{flex: 1, minWidth: "150px", marginRight: "32px" }}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="city"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<IUserProfileFormModel>
                                inputRef={ref}
                                label={t("city")}
                                type={FormInputType.TEXT}
                                placeholder={t("city-placeholder")}
                                error={error?.message}
                                readOnly
                                style={{flex: 1, minWidth: "150px"}}
                                {...restFieldProps}
                            />
                        )}
                    />
                </Box>
                <Controller
                    name="email"
                    control={control}
                    render={({field: { ref, ...restFieldProps }}) => (
                        <FormInput<IUserProfileFormModel>
                            inputRef={ref}
                            label={t("email-address")}
                            type={FormInputType.TEXT}
                            placeholder={t("email-address-placeholder")}
                            readOnly
                            {...restFieldProps}
                        />
                    )}
                />
                {user?.code && (
                    <Text size="small">
                        {`ID: ${user.code}`}
                    </Text>
                )}
            </Box>
        </FormWrapper>
    );
}
