import { IDrivingSchoolResponse } from "../driving-school/driving-school.response";
import { createInstructorDrivingSchool, IInstructorDrivingSchool } from "./instructor-driving-school.model";

export interface IInstructorDrivingSchoolResponse extends Omit<IDrivingSchoolResponse, "id" | "partner"> {
    phone: string;
    state: string;
    students_last_month: number;
    students_this_month: number;
    total_students: number;
    active_students: number;
}

export const createInstructorDrivingSchoolFromResponse = ({
    students_last_month,
    students_this_month,
    total_students,
    active_students,
    postal_code,
    ...props
}: IInstructorDrivingSchoolResponse): IInstructorDrivingSchool => {
    return createInstructorDrivingSchool({
        studentsLastMonth: students_last_month,
        studentsThisMonth: students_this_month,
        totalStudents: total_students,
        activeStudents: active_students,
        postalCode: postal_code?.toString(),
        ...props,
    });
};
