import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { IQuizAccessData } from "core/models/lesson/lesson-quiz-access-data.model";
import { createQuizAccessDataFromResponse, IQuizAccessDataResponse } from "core/models/lesson/lesson-quiz-access-data.response";
import { ILesson } from "core/models/lesson/lesson.model";
import { createLessonFromResponse, ILessonResponse } from "core/models/lesson/lesson.response";
import { ISearch } from "core/models/search/search.model";
import { pathBuilder } from "core/utils/path-builder";
import { apiService } from "./api-service";

class LessonService {
    async getLessonsByTag(
        searchParams?: ISearchParams,
    ): Promise<ISearch<ILesson>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<ILessonResponse>>(
                API_ROUTES.LESSONS_TAGS_SEARCH,
                {
                    params: { ...searchParams },
                },
            )
        );

        return {
            items: response
                ?.map(createLessonFromResponse)
                ?.slice(0, searchParams.limit) ?? [],
        } as ISearch<ILesson>;
    }

    async getLesson(
        lessonId: string,
    ): Promise<ILesson> {
        const response = apiService.responseHandler(
            await apiService.get<ILessonResponse>(
                pathBuilder(
                    API_ROUTES.LESSON,
                    "{lessonId}",
                    lessonId
                ),
            )
        );

        return createLessonFromResponse(response);
    }

    async getTotalLessons(): Promise<number> {
        const response = apiService.responseHandler(
            await apiService.get<{ total_lessons: number }>(API_ROUTES.TOTAL_LESSONS)
        );

        return response.total_lessons;
    }

    async getLessonQuizAccessData(
        lessonId: string,
    ): Promise<IQuizAccessData> {
        const response = apiService.responseHandler(
            await apiService.post<IQuizAccessDataResponse>(
                pathBuilder(
                    API_ROUTES.ADMIN_QUIZ_HASH,
                    "{lessonId}",
                    lessonId,
                ),
                {},
            )
        );

        return createQuizAccessDataFromResponse(response);
    }
}

export const lessonService = new LessonService();
