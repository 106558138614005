import { ILesson } from "../lesson/lesson.model";
import { ITheme } from "./theme.model";

export interface IThemeLessons extends Pick<ITheme, "id" | "numberOfLessons" | "translations"> {
    lessons: Array<ILesson>;
}

export const createThemeLessons = ({ id, ...props }: Partial<IThemeLessons>) => {
    if (!id) {
        throw new Error('Unable to create a theme lessons without an ID!');
    }

    return {
        id,
        lessons: [],
        numberOfLessons: null,
        translations: null,
        ...props,
    } as IThemeLessons;
};
