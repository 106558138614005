import { createContext, FC, useReducer } from "react";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { ISnackbarProps } from "../components/Snackbar/Snackbar";

const initialSnackbarProps: ISnackbarProps = {
    open: false,
    message: "",
    autoHideDuration: TIME_DURATION.SECOND * 3,
    alertType: "info",
    onClose: () => null,
};

const reducer = (
    state: ISnackbarProps,
    action: {
        type: "set" | "clear";
        props?: ISnackbarProps;
    }
) => {
    switch (action.type) {
        case "set":
            return {
                ...initialSnackbarProps,
                ...action.props,
            };
        case "clear":
            return initialSnackbarProps;
        default:
            return initialSnackbarProps;
    }
}

interface ISnackbarContext {
    snackbarProps: ISnackbarProps;
    setSnackbarProps: (props: ISnackbarProps) => void;
    clearSnackbarProps: () => void;
}

export const SnackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);

export const SnackbarProvider: FC = ({ children }) => {
    const [props, dispatch] = useReducer(
        reducer,
        initialSnackbarProps
    );

    const setSnackbarProps = (
        props: ISnackbarProps
    ) => {
        dispatch({ type: "set", props });
    };

    const clearSnackbarProps = () => {
        dispatch({ type: "clear" });
    };

    const value: ISnackbarContext = {
        snackbarProps: props,
        setSnackbarProps,
        clearSnackbarProps,
    };

    return (
        <SnackbarContext.Provider value={value}>
            {children}
        </SnackbarContext.Provider>
    );
};
