import React, { FC } from "react";
import { Box } from "@material-ui/core";

interface IProps {
    backgroundColor?: string;
}

export const HeaderContainer: FC<IProps> = ({
    backgroundColor = "transparent",
    children,
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            height="75px"
            p="16px 24px"
            bgcolor={backgroundColor}
        >
            {children}
        </Box>
    );
}
