import { ISignUpCredentials } from "modules/login/models/sign-up.model";

export type IUserProfileFormModel = Omit<ISignUpCredentials, "password" | "confirm_password">;

export const defaultUserProfileFormValues: IUserProfileFormModel = {
    driving_school_name: "",
    address: "",
    zip_code: "",
    city: "",
    email: "",
}
