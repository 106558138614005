import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { ISimulation } from "core/models/simulation/simulation.model";
import {
    createSimulationFromResponse,
    createSimulationsFromResponse,
    ISimulationResponse,
} from "core/models/simulation/simulation.response";
import { pathBuilder } from "core/utils/path-builder";
import { apiService } from "./api-service";

class SimulationService {
    async getSimulations({
        studentId,
        ...searchParams
    }: ISearchParams & { studentId?: string }): Promise<ISearch<ISimulation>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<ISimulationResponse>>(
                API_ROUTES.SIMULATIONS,
                {
                    params: {
                        ...searchParams,
                        for_student: studentId,
                    },
                }
            )
        );

        return createSimulationsFromResponse(response);
    }

    async getSimulation(
        simulationId: string,
    ): Promise<ISimulation> {
        const response = apiService.responseHandler(
            await apiService.get<ISimulationResponse>(
                pathBuilder(
                    API_ROUTES.SIMULATION,
                    "{simulationId}",
                    simulationId
                ),
            )
        );

        return createSimulationFromResponse(response);
    }
}

export const simulationService = new SimulationService();
