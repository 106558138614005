import { ROUTES } from "modules/home/enums/routes.enum";
import { INavigationItem } from "modules/home/interfaces/navigation-item.interface";
import { EditStudent } from "modules/students/pages/EditStudent";
import { StudentOverallProgress } from "modules/students/pages/StudentOverallProgress";
import { StudentProfile } from "modules/students/pages/StudentProfile";
import { StudentSimulatorProgress } from "modules/students/pages/StudentSimulatorProgress";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { StudentsList } from "../pages/StudentsList";

export const studentRoutes: Pick<INavigationItem, "path" | "exactPath" | "Component">[] = [
    {
        path: ROUTES.STUDENTS,
        exactPath: true,
        Component: StudentsList,
    },
    {
        path: ROUTES.STUDENT_PROFILE,
        exactPath: true,
        Component: () => (
            <ContentContainer>
                <StudentProfile />
            </ContentContainer>
        ),
    },
    {
        path: ROUTES.EDIT_STUDENT,
        exactPath: true,
        Component: () => (
            <ContentContainer>
                <EditStudent />
            </ContentContainer>
        ),
    },
    {
        path: ROUTES.STUDENT_OVERALL_PROGRESS,
        exactPath: true,
        Component: () => (
            <ContentContainer>
                <StudentOverallProgress />
            </ContentContainer>
        ),
    },
    {
        path: ROUTES.STUDENT_SIMULATOR_PROGRESS,
        exactPath: false,
        Component: () => (
            <ContentContainer>
                <StudentSimulatorProgress />
            </ContentContainer>
        ),
    },
];
