import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Table } from "core/components/Table";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useStudents } from "modules/students/hooks/use-students.hook";
import { useTabs } from "modules/sw-admin/hooks/use-tabs.hook";
import { IStudentTab } from "modules/sw-admin/students/hooks/use-student-tabs.hook";
import { IStudentsTableBody } from "../StudentsList";
import { ITableHeadCell } from "core/components/Table/TableHead";
import { IStudent } from "core/models/student/student.model";
import { AppVersion } from "core/enums/app-version.enum";
import { StudentAccountType } from "modules/sw-admin/students/enums/student-account-type.enum";
import { ROUTES } from "modules/home/enums/routes.enum";
import { pathBuilder } from "core/utils/path-builder";

interface IProps {
    tableHead: Array<ITableHeadCell>;
    formatTableBody: (students: Array<IStudent>) => Array<IStudentsTableBody>;
}

export const OfficialAccountsTable = ({
    tableHead,
    formatTableBody,
}: IProps) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const {
        tabActivePage,
        setTabActivePage,
    } = useTabs<IStudentTab>();

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useStudents({
        page: tabActivePage,
        appVersion: AppVersion.FULL,
        queryKey: ["students", StudentAccountType.OFFICIAL_ACCOUNTS, { page: tabActivePage }]
    });

    const studentsTableBody = useMemo(() => {
        if (!data?.items?.length) return [];

        return formatTableBody(data.items);
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : studentsTableBody.length > 0 ? (
                <Table<IStudentsTableBody>
                    headProps={{
                        data: tableHead,
                    }}
                    bodyProps={{
                        data: studentsTableBody,
                        onRowClick: (id: string) => {
                            push(
                                pathBuilder(
                                    ROUTES.STUDENT_PROFILE,
                                    ":studentId",
                                    id,
                                ),
                            );
                        },
                    }}
                    paginationProps={{
                        page: tabActivePage,
                        lastActivePage: data.currentPage,
                        pageSize: data.items.length,
                        totalPages: data.totalPages,
                        totalResults: data.totalResults,
                        isFetchingNextPage,
                        setPage: setTabActivePage,
                    }}
                />
            ) : (
                <Box mt="32px">
                    <Text>{t("no-data-available")}</Text>
                </Box>
            )}
        </>
    );
}
