import React, { useMemo } from "react";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import styles from "./styles.module.scss";

export type TStatus = "active" | "inactive" | "pending" | "ready" | "passed";

export interface IStatusIndicatorProps {
    status: TStatus;
    size?: number;
}

export const StatusIndicator = ({
    status,
    size,
}: IStatusIndicatorProps) => {
    const statusIndicatorClassName: string = useMemo(
        () => {
            switch (status) {
                case "active": return styles.active;
                case "inactive": return styles.inactive;
                case "pending": return styles.pending;
                case "ready": return styles.ready;
                case "passed": return styles.passed;
                default: return;
            }
        },
        [status]
    );

    return (
        <div
            className={classNameFormatter([
                styles.statusDot,
                statusIndicatorClassName,
            ])}
            style={{
                minWidth: size ? `${size}px` : "10px",
                maxWidth: size ? `${size}px` : "10px",
                height: size ? `${size}px` : "10px",
            }}
        />
    );
}
