import { useMutation } from "react-query";
import { userService } from "core/services/user.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { ICreateNewPasswordCredentials } from "../models/create-new-password.model";

export const useCreatePassword = () => {
    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (data: {
            code: string,
            credentials: ICreateNewPasswordCredentials,
        }) => {
            const { code, credentials } = data;

            return userService.resetPassword(code, credentials);
        }, {
            onError: (error: Error) => {
                console.log("Error on create new password - ", error);
            },
        },
    );

    return {
        resetPassword: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
