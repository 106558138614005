import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { INotification } from "core/models/notification/notification.model";
import {
    createNotificationFromResponse,
    createNotificationsFromResponse,
    INotificationResponse,
} from "core/models/notification/notification.response";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { apiService } from "./api-service";
import { pathBuilder } from "core/utils/path-builder";

class NotificationService {
    async getNotifications(
        searchParams?: ISearchParams
    ): Promise<ISearch<INotification>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<INotificationResponse>>(
                API_ROUTES.NOTIFICATIONS,
                {
                    params: { ...searchParams },
                }
            )
        );

        return createNotificationsFromResponse(response);
    }

    async readNotification(
        notificationId: string,
    ): Promise<INotification> {
        const response = apiService.responseHandler(
            await apiService.put<INotificationResponse>(
                pathBuilder(
                    API_ROUTES.READ_NOTIFICATION,
                    "{notificationId}",
                    notificationId,
                ),
                {},
            )
        );

        return createNotificationFromResponse(response);
    }
}

export const notificationService = new NotificationService();
