import React from "react";
import {
    Paper,
    PaperProps,
} from "@material-ui/core";

export const PaperComponent = (props: PaperProps) => {
    // Hides Paper popup if no children found
    return !(props.children as Array<React.ReactNode>)?.every((child) => !child)
        ? <Paper {...props} />
        : <></>;
}
