import React, { useMemo } from "react";
import { IStudentLearningActivity } from "core/models/student/student-learning-activity.model";
import { roundNumber } from "core/utils/round-number";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { CustomizedXAxisTick } from "./components/CustomizedXAxisTick";

const yAxisTicks: Array<number> = new Array(11).fill(null).map((_, index) => index * 10);

interface IProps {
    data: Array<IStudentLearningActivity>;
}

export const LearningActivityChart = ({ data }: IProps) => {
    const formattedData = useMemo(() => {
        if (!data?.length) return [];

        return data.map((item) => ({
            ...item,
            forDate: item.forDate,
            correctRate: roundNumber(item.correctRate * 100),
            incorrectRate: roundNumber(item.incorrectRate * 100),
        }));
    }, [data]);

    return (
        <ResponsiveContainer width={"100%"} height={450}>
            <BarChart
                data={formattedData}
                margin={{
                    top: 10,
                    bottom: 10,
                    left: 10,
                }}
            >
                <CartesianGrid
                    strokeDasharray="3 6"
                    vertical={false}
                />
                <XAxis
                    dataKey="forDate"
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    tick={<CustomizedXAxisTick />}
                    domain={[0, formattedData.length]}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    ticks={yAxisTicks}
                    domain={[0, 100]}
                    tickFormatter={(value) => `${value}%`}
                    tickMargin={10}
                    tick={{
                        fill: RGBAColors.lightBlackWithOpacity(0.38),
                        fontSize: "12px",
                    }}
                    width={40}
                />
                <Bar
                    dataKey="correctRate"
                    stackId="bar"
                    fill={colors.green}
                    barSize={18}
                    radius={[0, 0, 6, 6]}
                />
                <Bar
                    dataKey="incorrectRate"
                    stackId="bar"
                    fill={colors.lightRed}
                    barSize={18}
                    radius={[6, 6, 0, 0]}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}
