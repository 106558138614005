import React from "react";
import MUISwitch from '@material-ui/core/Switch';
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { StyledSwitch } from "./components/StyledSwitch";
import { useTranslation } from "core/hooks/use-translation.hook";

export interface ISwitchProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const Switch = ({
    checked,
    onChange,
    disabled,
}: ISwitchProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            onClick={(event) => event.stopPropagation()}
            style={{ cursor: "default" }}
        >
            <StyledSwitch>
                <MUISwitch
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    disableRipple
                />
            </StyledSwitch>
            <Text>
                {checked ? t("active") : t("inactive")}
            </Text>
        </Box>
    );
}
