import { useMutation } from "react-query";
import { UserRole } from "core/enums/user-roles.enum";
import { userService } from "core/services/user.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { ISignUpCredentials } from "../models/sign-up.model";
import { createRegisterCredentialsCommand, IRegisterCredentials } from "core/models/user/user.command";

export const useRegister = () => {
    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (credentials: ISignUpCredentials) => {
            const registerCredentials: IRegisterCredentials = createRegisterCredentialsCommand(credentials, [
                UserRole.ROLE_INSTRUCTOR,
            ]);

            return userService.register(registerCredentials);
        }, {
            onError: (error: Error) => {
                console.log("Error on register user - ", error);
            },
        },
    );

    return {
        register: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
