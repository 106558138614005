import { format } from "date-fns";
import { groupBy } from "core/utils/group-by";
import { INotification } from "core/models/notification/notification.model";
import { DateFormat } from "core/enums/date-format.enum";

export interface INotificationGroups {
    date: string;
    notifications: Array<INotification>;
}

export const groupNotificationsByDate = (
    notifications: Array<INotification>,
): Array<INotificationGroups> => {
    const mappedNotifications = [...notifications]
        .map((notification: INotification) => ({
            ...notification,
            createdAt: format(
                new Date(notification.createdAt),
                DateFormat.mmmDDyyyy
            ),
        }));

    return groupBy<INotification>(mappedNotifications, "createdAt")
        .map(({ key, values }) => ({
            date: key,
            notifications: values,
        }));
}
