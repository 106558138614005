import React from "react";
import { ITag } from "core/models/lesson/lesson-tag.model";
import styles from "./styles.module.scss";
import Text from "core/components/Text";
import Box from "@material-ui/core/Box";

interface IProps {
    tags: Array<ITag>;
}

export const LessonTags = ({ tags }: IProps) => {
    return !tags?.length ? <></> : (
        <Box
            display="flex"
            // gap="4px"
            flexWrap="wrap"
        >
            {tags.map((tag) => (
                <div
                    key={tag.id}
                    className={styles.tagContainer}
                >
                    <Text color="violet">{tag.name}</Text>
                </div>
            ))}
        </Box>
    );
}
