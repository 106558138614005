import { useMutation } from "react-query";
import { userService } from "core/services/user.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { IResetPasswordCredentials } from "../models/reset-password.model";

export const useResetPassword = () => {
    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (credentials: IResetPasswordCredentials) => {
            return userService.requestPasswordReset(credentials);
        }, {
            onError: (error: Error) => {
                console.log("Error on reset password request - ", error);
            },
        },
    );

    return {
        requestPasswordReset: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
