import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { IStudentAccountsCreated } from "core/models/instructor/student-accounts-created.model";
import { IChartTab } from "./interfaces/chart-tab.interface";
import { ITab, Tabs } from "../Tabs";
import { Chart } from "./components/Chart";

interface IProps {
    tabs: Array<ITab<IChartTab>>;
    selectedTab: ITab<IChartTab>;
    setSelectedTab: (tab: ITab<IChartTab>) => void;
    data: Array<IStudentAccountsCreated>;
}

export const StudentAccountsCreatedChart = ({
    tabs,
    selectedTab,
    setSelectedTab,
    data,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="grid"
            flexDirection="column"
            gap="48px"
            width={1}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                flexWrap="wrap"
            >
                <Text size="large">
                    {t("student-accounts-created")}
                </Text>

                <Tabs<IChartTab>
                    tabs={tabs}
                    activeTab={selectedTab}
                    setActiveTab={setSelectedTab}
                    joinTabs
                />
            </Box>
            <Chart
                data={data}
                type={selectedTab.type}
            />
        </Box>
    );
}
