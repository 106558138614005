import React from "react";
import { ILessonsParams } from "modules/themes/interfaces/lessons-params.interface";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import { pathBuilder } from "core/utils/path-builder";
import { useLesson } from "modules/themes/hooks/use-lesson.hook";
import Box from "@material-ui/core/Box";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import Loader from "core/components/Loader";
import { getLessonName } from "core/models/lesson/lesson.model";
import { AvailableLanguageKeys } from "core/enums/available-language-keys.enum";
import { LessonTags } from "./LessonTags";
import { LessonDescription } from "./LessonDescription";
import { LessonVideo } from "./LessonVideo";
import { LessonVideoDetails } from "./LessonVideo/LessonVideoDetails";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { formatVideoDuration } from "modules/themes/utils/format-video-duration";
import { Toolbar } from "modules/home/components/Toolbar";
import { useLessonQuizAccessData } from "modules/themes/hooks/use-lesson-quiz-access-data.hook";

export const LessonDetails = () => {
    const { themeId, lessonId } = useParams<ILessonsParams>();
    const { t } = useTranslation();
    const { state } = useLocation<ILocationState>();

    const { data, isLoading } = useLesson({ lessonId });
    const {
        data: lessonQuizAccessData,
        isLoading: isQuizAccessDataLoading,
    } = useLessonQuizAccessData(lessonId);

    return (
        <>
            <Toolbar
                backToPath={(state?.from?.pathname ?? pathBuilder(
                    ROUTES.THEME_LESSONS,
                    ":themeId",
                    themeId
                )) as ROUTES}
            />

            <Box
                mt="24px"
                display="flex"
                // gap={4}
            > 
                {isLoading || isQuizAccessDataLoading ? (
                    <Loader />
                ) : data ? (
                    <>
                        <Box
                            display="grid"
                            flexDirection="column"
                            flex="1"
                            minWidth="528px"
                            maxWidth="688px"
                            gap={3}
                        >
                            <LessonVideo
                                videoURL={data.video}
                                posterURL={data.media}
                            />

                            <LessonVideoDetails
                                lessonName={getLessonName(data.translations).title}
                                videoDuration={data.mediaDuration
                                    ? formatVideoDuration(data.mediaDuration)
                                    : "00:00 min"
                                }
                                quizAccessToken={lessonQuizAccessData?.token}
                            />
                        </Box>

                        <Box
                            display="grid"
                            flexDirection="column"
                            flex="1"
                            maxWidth="448px"
                            mt="-8px"
                            ml="32px"
                            gap={3}
                        >
                            <Text size="large">
                                {t("what-can-you-expect-to-learn")}
                            </Text>

                            <LessonTags tags={data.tags} />

                            <LessonDescription
                                isLoading={isLoading}
                                description={
                                    getLessonName(
                                        data.translations,
                                        AvailableLanguageKeys.DE
                                    ).description
                                }
                            />
                        </Box>
                    </>
                ) : (
                    <Text>{t("no-data-available")}</Text>
                )}
            </Box>
        </>
    );
}
