import React from "react";
import { IUseConfirmationDialog, useConfirmationDialog } from "core/hooks/use-confirmation-dialog.hook";
import { Switch } from "core/components/Switch";
import { useDialog } from "core/hooks/use-dialog.hook";
import { useChangeInstructorStatus } from "modules/sw-admin/driving-schools/hooks/use-change-instructor-status.hook";
import { UserStatus } from "core/enums/user-status.enum";

interface IProps {
    instructorId: string;
    checked: boolean;
    confirmationDialogProps: IUseConfirmationDialog;
    status?: UserStatus;
    isActive?: boolean;
}

export const SwitchAccountStatus = ({
    instructorId,
    checked,
    confirmationDialogProps,
    status,
    isActive,
}: IProps) => {
    const { changeInstructorStatus } = useChangeInstructorStatus();
    const { setDialogProps } = useDialog();
    const dialogProps = useConfirmationDialog({
        ...confirmationDialogProps,
        onConfirm: () => changeInstructorStatus({
            instructorId,
            status,
            isActive,
        }),
    });

    return (
        <Switch
            checked={checked}
            onChange={() => setDialogProps({ ...dialogProps })}
        />
    );
}
