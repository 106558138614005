import { API_ROUTES } from "core/enums/api-routes.enum";
import { AppSource } from "core/enums/app-source.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { IDrivingSchool } from "core/models/driving-school/driving-school.model";
import {
    createDrivingSchoolFromResponse,
    IDrivingSchoolResponse,
} from "core/models/driving-school/driving-school.response";
import { apiService } from "./api-service";

class DrivingSchoolService {
    async getDrivingSchools(
        searchParams?: ISearchParams
    ): Promise<Array<IDrivingSchool>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IDrivingSchoolResponse>>(
                API_ROUTES.DRIVING_SCHOOLS_SEARCH,
                {
                    params: {
                        source: AppSource.ADMIN,
                        ...searchParams,
                    },
                }
            )
        );

        return response?.map(createDrivingSchoolFromResponse);
    }
}

export const drivingSchoolService = new DrivingSchoolService();
