import { useMutation } from "react-query";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { exportDataService } from "core/services/export-data.service";

export enum ExportEntityType {
    INSTRUCTORS = "Instructors",
    DRIVING_SCHOOLS = "Driving schools",
    STUDENTS = "Students",
    WATCHED_VIDEOS = "Watched videos",
};

export const useExportData = () => {
    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (exportEntity: ExportEntityType) => {
            switch (exportEntity) {
                case ExportEntityType.INSTRUCTORS:
                    return exportDataService.exportInstructors();
                case ExportEntityType.DRIVING_SCHOOLS:
                    return exportDataService.exportDrivingSchools();
                case ExportEntityType.STUDENTS:
                    return exportDataService.exportStudents();
                case ExportEntityType.WATCHED_VIDEOS:
                    return exportDataService.exportWatchedLessons();
                default: return;
            }
        },
        {
            onError: (error: Error, exportEntity: ExportEntityType) => {
                console.log(`Error on ${exportEntity} data export - `, error);
            },
        },
    );

    return {
        exportData: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
};
