export interface IStudentProgress {
    overallTotal: number;
    overallCorrect: number;
    overallIncorrect: number;
    overallSuccessRate: number;
    simulationFailed: number;
    simulationMax: number;
    simulationPassed: number;
    simulationSuccessRate: number;
    simulationTotal: number;
}

export const createStudentProgress = ({ ...props }: Partial<IStudentProgress>) => {
    return {
        overallTotal: null,
        overallCorrect: null,
        overallIncorrect: null,
        overallSuccessRate: null,
        simulationFailed: null,
        simulationMax: null,
        simulationPassed: null,
        simulationSuccessRate: null,
        simulationTotal: null,
        ...props,
    } as IStudentProgress;
};
