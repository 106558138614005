import { createQuizAccessData, IQuizAccessData } from "./lesson-quiz-access-data.model";

export interface IQuizAccessDataResponse {
    created_at: string;
    expires_at: string;
    token: string;
    used: boolean;
}

export const createQuizAccessDataFromResponse = ({
    created_at,
    expires_at,
    ...props
}: IQuizAccessDataResponse): IQuizAccessData => {
    return createQuizAccessData({
        createdAt: created_at,
        expiresAt: expires_at,
        ...props,
    });
};
