import { useMutation, useQueryClient } from "react-query";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { UserStatus } from "core/enums/user-status.enum";
import { userService } from "core/services/user.service";
import { IStudent } from "core/models/student/student.model";
import { StudentStatus } from "../enums/student-status.enum";
import { ISearch } from "core/models/search/search.model";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { getItemFromLocalStorage } from "core/utils/local-storage-handler";

interface IUseReactivateStudent {
    studentId: string;
}

export const useReactivateStudent = ({ studentId }: IUseReactivateStudent) => {
    const studentsQueryKey = getItemFromLocalStorage(LocalStorageKeys.studentsQueryKey);

    const queryClient = useQueryClient();
    const {
        mutateAsync: reactivateStudent,
        isLoading,
        isError,
        error,
    } = useMutation(
        () => userService.reactivateStudent(studentId),
        {
            onMutate: async () => {
                await queryClient.cancelQueries(["student", studentId]);
                await queryClient.cancelQueries(studentsQueryKey);

                const previousStudent: IStudent = queryClient.getQueryData(["student", studentId]);
                if (previousStudent) {
                    queryClient.setQueryData(
                        ["student", studentId],
                        (previousData: IStudent) => {
                            return {
                                ...previousData,
                                status: (status as unknown) as StudentStatus,
                            };
                        }
                    );
                }

                const previousStudents: ISearch<IStudent> = queryClient.getQueryData(studentsQueryKey);
                if (previousStudents) {
                    queryClient.setQueryData(
                        studentsQueryKey,
                        (previousData: ISearch<IStudent>) => {
                            const { items, ...restPreviousData } = previousData;

                            return {
                                ...restPreviousData,
                                items: items.map((item) => {
                                    if (item.id === studentId) {
                                        return {
                                            ...item,
                                            status: (status as unknown) as StudentStatus,
                                        };
                                    }

                                    return item;
                                }),
                            };
                        }
                    );
                }

                return () => {
                    queryClient.setQueryData(["student", studentId], previousStudent);
                    queryClient.setQueryData(studentsQueryKey, previousStudents);
                };
            },
            onError: (error: Error, rollback?: CallableFunction) => {
                console.log("Error on changing student status - ", error);

                rollback && rollback();
            },
            onSettled: () => {
                queryClient.invalidateQueries(["student", studentId]);
                queryClient.invalidateQueries(studentsQueryKey);
            },
        }
    );

    return {
        reactivateStudent,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
