import React from "react";
import { ROUTES } from "modules/home/enums/routes.enum";
import { Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "core/components/Routes";
import { SimulatorProgressList } from "./components/SimulatorProgressList";
import { SimulatorProgressItemDetails } from "./components/SimulatorProgressItemDetails";

export const StudentSimulatorProgress = () => (
    <Switch>
        <PrivateRoute
            exact
            path={ROUTES.STUDENT_SIMULATOR_PROGRESS}
            component={SimulatorProgressList}
        />
        <PrivateRoute
            exact
            path={ROUTES.STUDENT_SIMULATOR_PROGRESS_DETAILS}
            component={SimulatorProgressItemDetails}
        />
        <Redirect from="*" to={ROUTES.NOT_FOUND} />
    </Switch>
);
