import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { ISearchParams, SortDirection } from "core/interfaces/search-params.interface";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { ISearchEntityProps } from "core/interfaces/search-entity-props.interface";

interface IUseEntitySearch<T> extends Pick<ISearchEntityProps<T>, "queryKey" | "queryFunction"> {
    searchParams: ISearchParams;
    enabled: boolean;
}

export const useEntitySearch = <T>({
    searchParams,
    queryKey,
    queryFunction,
    enabled,
}: IUseEntitySearch<T>) => {
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery(
        [queryKey, searchParams?.query ?? ""],
        () => queryFunction({
            limit: 10,
            direction: SortDirection.DESC,
            ...searchParams,
        }),
        {
            onError: (error: Error) => {
                console.log(`Error on searching ${queryKey} - `, error);
            },
            staleTime: TIME_DURATION.MINUTE,
            enabled,
        },
    );

    return {
        data,
        isLoading,
        isFetching,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
