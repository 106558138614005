import React from "react";

export interface ITableBodyProps<B = Record<string, unknown>> {
    data: Array<{ id: string } & B>;
    onRowClick?: (id: string) => void;
    rowStyles?: React.CSSProperties;
}

export const TableBody = <B,>({
    data,
    onRowClick,
    rowStyles,
}: ITableBodyProps<B>) => (
    <tbody>
        {data.map(({ id, ...restProps }) => (
            <tr
                key={id}
                /**
                 * NOTE:
                 * We should use LINK router component,
                 * but table elements (<tr>, <td>) doesn't behave good when wrapped with <a> element!
                 */
                onClick={() => {
                    // { id: "NaN" } is setted as dummy value on data mutation
                    if (id === "NaN") return;

                    onRowClick && onRowClick(id);
                }}
                style={{
                    cursor: id === "NaN" || !onRowClick ? "default" : "pointer",
                    opacity: id === "NaN" ? 0.5 : 1,
                    ...rowStyles,
                }}
            >
                {Object.entries(restProps).map(([key, value]) => (
                    <td key={key}>
                        {value}
                    </td>
                ))}
            </tr>
        ))}
    </tbody>
);
