import { useContext } from "react";
import { ITab } from "core/components/Tabs";
import { ITabsContext, TabsContext } from "../context/TabsContextProvider";

export const useTabs = <T = ITab>() => {
    const context = useContext<ITabsContext<T>>(TabsContext);

    if (context === undefined) {
        throw new Error("TabsContext must be within TabsProvider");
    }

    return context;
};
