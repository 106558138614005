import { useMutation, useQueryClient } from "react-query";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { createEditInstructorCommand, IEditInstructorCommand } from "core/models/instructor/instructor.command";
import { instructorService } from "core/services/instructor.service";
import { IInstructor } from "core/models/instructor/instructor.model";
import { IEditInstructorFormModel } from "../models/edit-instructor-form.model";

interface IUseEditInstructor {
    instructorId: string;
}

export const useEditInstructor = ({ instructorId }: IUseEditInstructor) => {
    const queryClient = useQueryClient();
    const {
        mutate: editInstructor,
        isError,
        error,
        isLoading,
    } = useMutation(
        (data: IEditInstructorFormModel) => {
            const commandData: IEditInstructorCommand = createEditInstructorCommand(data);

            return instructorService.editInstructor(instructorId, commandData);
        },
        {
            onMutate: async ({
                driving_school_name,
                address,
                city,
                zip_code,
                phone,
            }: IEditInstructorFormModel) => {
                await queryClient.cancelQueries(["instructor", instructorId]);

                const previousInstructor: IInstructor = queryClient.getQueryData(["instructor", instructorId]);
                if (previousInstructor) {
                    queryClient.setQueryData(
                        ["instructor", instructorId],
                        (previousData: IInstructor) => ({
                            ...previousData,
                            drivingSchool: {
                                ...previousData.drivingSchool,
                                name: driving_school_name,
                                postalCode: zip_code as string,
                                address,
                                city,
                                phone,
                            },
                        }),
                    );
                }

                return () => queryClient.setQueryData(["instructor", instructorId], previousInstructor);
            },
            onError: (error, variables, rollback: CallableFunction) => {
                console.log("Error on editing instructor - ", error);

                rollback && rollback();
            },
            onSettled: () => {
                queryClient.invalidateQueries(["instructor", instructorId]);
                queryClient.invalidateQueries(["instructors"]);
            },
        }
    );

    return {
        editInstructor,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
