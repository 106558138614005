import { createContext, FC, useReducer } from "react";
import { IDialogProps } from "core/components/Dialog";
import { DialogType } from "core/enums/dialog-type.enum";

const initialDialogProps: IDialogProps = {
    open: false,
    title: "",
    header: <></>,
    content: <></>,
    actions: <></>,
    type: DialogType.ALERT,
    onClose: () => null,
};

const reducer = (
    state: IDialogProps,
    action: {
        type: "set" | "clear";
        props?: IDialogProps;
    }
) => {
    switch (action.type) {
        case "set":
            return {
                ...initialDialogProps,
                ...action.props,
            };
        case "clear":
            return initialDialogProps;
        default:
            return initialDialogProps;
    }
}

interface IDialogContext {
    dialogProps: IDialogProps;
    setDialogProps: (props: IDialogProps) => void;
    clearDialogProps: () => void;
}

export const DialogContext = createContext<IDialogContext>({} as IDialogContext);

export const DialogProvider: FC = ({ children }) => {
    const [props, dispatch] = useReducer(
        reducer,
        initialDialogProps
    );

    const setDialogProps = (
        props: IDialogProps
    ) => {
        dispatch({ type: "set", props });
    };

    const clearDialogProps = () => {
        dispatch({ type: "clear" });
    };

    const value: IDialogContext = {
        dialogProps: props,
        setDialogProps,
        clearDialogProps,
    };

    return (
        <DialogContext.Provider value={value}>
            {children}
        </DialogContext.Provider>
    );
};
