import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import { RGBAColors } from "styles/variables/rgba-colors";
import colors from "styles/variables/colors.module.scss";
import { CircularProgressBar } from "core/components/CircularProgressBar";
import { calculatePercentage } from "core/utils/calculate-percentage";

interface IProps {
    title: string;
    answeredQuestions: number;
    totalQuestions: number;
    errorPoints: number;
}

export const QuestionMaterialCard = ({
    title,
    answeredQuestions,
    totalQuestions,
    errorPoints,
}: IProps) => {
    const { t } = useTranslation();

    const progressValue = useMemo(
        () => calculatePercentage(answeredQuestions, totalQuestions),
        [answeredQuestions, totalQuestions]
    );

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={styles.questionsInfoCardContainer}
            gap={2}
        >
            <CircularProgressBar
                value={progressValue}
                size={60}
                styles={{
                    backgroundColor: RGBAColors.greenWithOpacity(0.08),
                    pathColor: colors.green,
                    trailColor: "transparent",
                }}
            >
                <Text color="green">
                    {`${answeredQuestions}/${totalQuestions}`}
                </Text>
            </CircularProgressBar>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="4px"
            >
                <Text bold>
                    {title}
                </Text>
                <Text
                    size="small"
                    style={{
                        color: RGBAColors.lightBlackWithOpacity(0.6),
                    }}
                >
                    {t("error-points-count", { count: errorPoints })}
                </Text>
            </Box>
        </Box>
    );
}
