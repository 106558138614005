import { addLeadingZero } from "core/utils/number-leading-zero";

/**
 * Converts durationInMinutes into MM:SS format.
 *
 * Example: 1.5 min => 01:30 min
 */
export function formatVideoDuration(durationInMinutes: number) {
    const minutes: number = Math.floor(durationInMinutes);
    const seconds: number = Math.round((durationInMinutes - minutes) * 60);

    return `${addLeadingZero(minutes)}:${addLeadingZero(seconds)} min`;
}
