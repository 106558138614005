import { apiService } from "./api-service";
import { ISignInCredentials } from "modules/login/models/sign-in.model";
import { API_ROUTES } from "core/enums/api-routes.enum";
import { ILoginResponse } from "core/interfaces/login-response.interface";
import { UserType } from "core/enums/user-type.enum";
import { IResetPasswordCredentials } from "modules/login/models/reset-password.model";
import { ICreateNewPasswordCredentials } from "modules/login/models/create-new-password.model";
import { pathBuilder } from "core/utils/path-builder";
import { IRefreshTokenResponse, IToken } from "core/interfaces/token-response.interface";
import { IUser, TUser } from "core/models/user/user.model";
import { createUserFromResponse, IUserResponse } from "core/models/user/user.response";
import { IRegisterCredentials } from "core/models/user/user.command";
import { clearLocalStorage, getItemFromLocalStorage, setItemToLocalStorage } from "core/utils/local-storage-handler";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { AppSource } from "core/enums/app-source.enum";
import { getCurrentLanguage } from "core/utils/multi-language-support";
import { UserStatus } from "core/enums/user-status.enum";
import { queryClient } from "index";

class UserService {
    async getUser(): Promise<IUser> {
        const response = apiService.responseHandler(
            await apiService.get<IUserResponse>(API_ROUTES.ME)
        );

        return createUserFromResponse(response);
    }

    async login(
        credentials: ISignInCredentials,
    ): Promise<TUser> {
        const {
            token,
            refreshToken: refresh_token,
            user,
        } = apiService.responseHandler(
            await apiService.post<ILoginResponse, ISignInCredentials>(
                API_ROUTES.TOKEN,
                credentials,
            )
        );

        return {
            token,
            refreshToken: refresh_token,
            user: createUserFromResponse(user),
        };
    }

    async register(
        credentials: IRegisterCredentials,
        userType: UserType = UserType.INSTRUCTOR,
    ): Promise<IUser> {
        const userResponse = apiService.responseHandler(
            await apiService.post<IUserResponse, IRegisterCredentials & { source: AppSource }>(
                API_ROUTES.REGISTER,
                {
                    ...credentials,
                    source: AppSource.ADMIN,
                },
                {
                    params: {
                        type: userType,
                    },
                }
            )
        );

        return createUserFromResponse(userResponse);
    }

    async requestPasswordReset(
        credentials: IResetPasswordCredentials,
    ): Promise<any> {
        return await apiService.post<any, IResetPasswordCredentials & { source: AppSource }>(
            API_ROUTES.REQUEST_PASSWORD_RESET,
            {
                ...credentials,
                /**
                 * This value is used to tell backend which LINK for reset password (admin | web | mobile),
                 * will be returned in E-mail.
                 */
                source: AppSource.ADMIN,
            }
        );
    }

    async resetPassword(
        code: string,
        credentials: ICreateNewPasswordCredentials,
    ): Promise<any> {
        return await apiService.put<any, ICreateNewPasswordCredentials>(
            pathBuilder(
                API_ROUTES.RESET_PASSWORD,
                "{code}",
                code,
            ),
            credentials
        );
    }

    async refreshToken(
        refreshToken: string
    ): Promise<TUser> {
        const {
            AccessToken: access_token,
            user,
        } = apiService.responseHandler(
            await apiService.get<IRefreshTokenResponse>(
                pathBuilder(
                    API_ROUTES.REFRESH_TOKEN,
                    "{refreshToken}",
                    refreshToken,
                ),
            )
        );

        return {
            token: access_token,
            /**
             * It's not possible to get new refreshToken from Cognito, so we just set it to empty string
             *
             * Explanation: https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-refresh-token.html
             */
            refreshToken: "",
            user: createUserFromResponse(user),
        };
    }

    async toggleActiveUser(
        userId: string,
        isActive: boolean,
    ): Promise<[]> {
        return apiService.responseHandler(
            await apiService.put(
                pathBuilder(
                    API_ROUTES.TOGGLE_ACTIVE_USER,
                    "{userId}/{isActive}",
                    `${userId}/${isActive}`
                ),
                {
                    user: userId,
                    active: isActive,
                },
            )
        );
    }

    async changeUserStatus(
        userId: string,
        status: UserStatus,
    ): Promise<[]> {
        return apiService.responseHandler(
            await apiService.put(
                pathBuilder(
                    API_ROUTES.CHANGE_USER_STATUS,
                    "{userId}/{status}",
                    `${userId}/${status}`
                ),
                {
                    user: userId,
                    status,
                },
            )
        );
    }

    async reactivateStudent(
        studentId: string
    ): Promise<[]> {
        return apiService.responseHandler(
            await apiService.put(
                pathBuilder(
                    API_ROUTES.REACTIVATE_STUDENT,
                    "{studentId}",
                    `${studentId}`
                ),
                {
                    studentId: studentId,
                },
            )
        );
    }

    logout(): void {
        queryClient.clear();
        clearLocalStorage();
        setItemToLocalStorage(
            LocalStorageKeys.appLang,
            getCurrentLanguage()
        );
    }

    getToken(): IToken {
        const { token, refreshToken } = getItemFromLocalStorage(LocalStorageKeys.user) ?? {
            token: null,
            refreshToken: null,
        };

        return { token, refreshToken };
    }
}

export const userService = new UserService();
