import { createUserProgress, IUserProgress } from "./user-progress.model";

export interface IUserProgressResponse {
    total: number;
    correct: number;
    incorrect: number;
    success_rate: number;
}

export const createUserProgressFromResponse = ({
    success_rate,
    ...props
}: Partial<IUserProgressResponse>): IUserProgress => {
    return createUserProgress({
        successRate: success_rate ?? null,
        ...props,
    });
};
