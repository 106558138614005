/**
 * Used for accessing app RGBA colors inside JS/TS files.
 */
export const RGBAColors = {
    whiteWithOpacity: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,
    blackWithOpacity: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,
    grayWithOpacity: (opacity: number) => `rgba(118, 118, 128, ${opacity})`,
    greenWithOpacity: (opacity: number) => `rgba(0, 203, 133, ${opacity})`,
    orangeWithOpacity: (opacity: number) => `rgba(244, 149, 37, ${opacity})`,
    lightBlackWithOpacity: (opacity: number) => `rgba(26, 26, 26, ${opacity})`,
    lightRedWithOpacity: (opacity: number) => `rgba(239, 77, 94, ${opacity})`,
    lightVioletWithOpacity: (opacity: number) => `rgba(122, 80, 238, ${opacity})`,
};
