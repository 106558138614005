import { ROUTES } from "modules/home/enums/routes.enum";
import { INavigationItem } from "modules/home/interfaces/navigation-item.interface";
import {
    DashboardIcon,
    DrivingSchoolIcon,
    StudentsIcon,
    NotificationsIcon,
} from "assets/icons/navigation-bar";
import { DrivingSchoolsPage } from "modules/sw-admin/driving-schools/pages";
import { DashboardPage } from "modules/sw-admin/dashboard/pages";
import { StudentsPage } from "modules/sw-admin/students/pages";
import { Notifications } from "core/components/Notifications";
import { ContentContainer } from "../components/ContentContainer";

export const adminNavigationItems: INavigationItem[] = [
    {
        path: ROUTES.DASHBOARD,
        exactPath: true,
        translateKey: "dashboard",
        Icon: DashboardIcon,
        Component: DashboardPage,
        enabled: true,
    },
    {
        path: ROUTES.DRIVING_SCHOOLS,
        exactPath: false,
        translateKey: "driving-schools",
        Icon: DrivingSchoolIcon,
        Component: DrivingSchoolsPage,
        enabled: true,
    },
    {
        path: ROUTES.STUDENTS,
        exactPath: false,
        translateKey: "students",
        Icon: StudentsIcon,
        Component: StudentsPage,
        enabled: true,
    },
    {
        path: ROUTES.NOTIFICATIONS,
        exactPath: true,
        translateKey: "notifications",
        Icon: NotificationsIcon,
        Component: () => (
            <ContentContainer>
                <Notifications />
            </ContentContainer>
        ),
        enabled: true,
    },
];
