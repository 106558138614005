import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { initializeApiInterceptors } from "./axios-interceptors";
import { createCancelTokenHandler, ICancelTokenHandler } from "./cancel-token-handler";
import { HttpClient } from "./http-client";

export type ResponseHandler<T = any, R = T> = (response: AxiosResponse<T>) => R;

class ApiService {
    private _httpClient: AxiosInstance;
    private _cancelTokenHandler: ICancelTokenHandler;

    constructor() {
        this._httpClient = HttpClient.getInstance();
        this._cancelTokenHandler = createCancelTokenHandler();

        initializeApiInterceptors(this._httpClient);
    }

    responseChecker<T>(response: AxiosResponse<T>) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        throw response.data;
    }

    responseHandler<T = any>({ data }: AxiosResponse<T>) {
        return data;
    }

    async get<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.responseChecker(
            await this._httpClient.get<T>(url, {
                ...config,
                // cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async post<T = any, B = any>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.responseChecker(
            await this._httpClient.post<T>(url, body, {
                ...config,
                // cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async put<T = any, B = any>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return this.responseChecker(
            await this._httpClient.put<T>(url, body, {
                ...config,
                // cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async delete(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<null>> {
        return this.responseChecker(
            await this._httpClient.delete(url, {
                ...config,
                // cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }
}

export const apiService = new ApiService();
