import { createDrivingSchool, IDrivingSchool } from "./driving-school.model";

export interface IDrivingSchoolResponse {
    id: number;
    name: string;
    address: string;
    city: string;
    postal_code: number;
    partner: boolean;
    phone: string;
    active_students?: number;
    students_pending_invitation?: number;
    students_passed?: number;
    students_ready_for_exam?: number;
    total_students?: number;
}

export const createDrivingSchoolFromResponse = ({
    id,
    postal_code,
    active_students,
    students_pending_invitation,
    students_passed,
    students_ready_for_exam,
    total_students,
    ...props
}: IDrivingSchoolResponse): IDrivingSchool => {
    return createDrivingSchool({
        id: id?.toString(),
        postalCode: postal_code?.toString() ?? "",
        activeStudents: active_students,
        studentsPendingInvitation: students_pending_invitation,
        studentsPassed: students_passed,
        studentsReadyForExam: students_ready_for_exam,
        totalStudents: total_students,
        ...props,
    });
};
