import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import { ROUTES } from "modules/home/enums/routes.enum";
import { userService } from "core/services/user.service";
import NavigationItem from "./NavigationItem";
import { LogoutIcon } from "assets/icons/navigation-bar";
import { StreamwaysLogoTextImage } from "assets/images";
import { getUnreadNotifications } from "core/models/notification/notification.model";
import styles from "./styles.module.scss";
import { INavigationItem } from "modules/home/interfaces/navigation-item.interface";
import { useNotifications } from "core/components/Notifications/hooks/use-notifications.hook";
import { ArrowForwardIcon } from "assets/icons";
import colors from "styles/variables/colors.module.scss";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IProps {
    navigationItems: Array<INavigationItem>;
}

export const NavigationBar = ({ navigationItems }: IProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isSharedRoute } = useIsSharedRoute();

    const { data, isLoading } = useNotifications({ enabled: !isSharedRoute });
    const unreadNotificationsCount: number = useMemo(() => {
        if (!data || isLoading) return;

        return getUnreadNotifications(data.items).length;
    }, [data, isLoading])

    return (
        <div className={styles.navigationBarContainer}>
            <div className={styles.navBarLogoContainer}>
                <StreamwaysLogoTextImage />
            </div>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flex="1"
            >
                <div>
                    {navigationItems.map((navItem) => (
                        <NavigationItem
                            key={navItem.path}
                            item={navItem}
                            unreadNotificationsCount={unreadNotificationsCount}
                            isActive={pathname.startsWith(navItem.path)}
                        />
                    ))}
                </div>

                {isSharedRoute ? (
                    <Link
                        to={ROUTES.SIGN_IN}
                        className={styles.buttonLinkContainer}
                    >
                        <div className={styles.loginIconWrapper}>
                            <ArrowForwardIcon color={colors.green} />
                        </div>
                        <Text size="medium">
                            {t("log-in")}
                        </Text>
                    </Link>
                ) : (
                    <Link
                        to={ROUTES.SIGN_IN}
                        onClick={userService.logout}
                        className={styles.buttonLinkContainer}
                    >
                        <LogoutIcon className={styles.logoutIcon}/>
                        <Text size="medium">
                            {t("logout")}
                        </Text>
                    </Link>
                )}
            </Box>
        </div>
    );
}
