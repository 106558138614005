import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { INavigationItem } from "modules/home/interfaces/navigation-item.interface";
import Text from "core/components/Text";
import { ROUTES } from "modules/home/enums/routes.enum";
import styles from "./styles.module.scss";
import { NavLinkWrapper } from "./NavLinkWrapper";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { abbreviateNumber } from "core/utils/abbreviate-number";

interface IProps {
    item: INavigationItem;
    unreadNotificationsCount: number;
    isActive: boolean;
}

export const NavigationItem = ({
    item,
    unreadNotificationsCount,
    isActive,
}: IProps) => {
    const { t } = useTranslation();
    const { Icon, translateKey, path, enabled } = item;

    const navLinkColor = useMemo(
        () => isActive
            ? colors.violet
            : enabled
            ? RGBAColors.lightBlackWithOpacity(0.38)
            : colors.inputGray,
        [isActive, enabled]
    );

    return (
        <NavLinkWrapper to={path} enabled={enabled}>
            <Box
                display="flex"
                alignItems="center"
                height="48px"
                // gap="12px"
                p="12px 24px"
            >
                {path === ROUTES.NOTIFICATIONS ? (
                    <div className={styles.notificationsCounterContainer}>
                        <Icon color={navLinkColor} className={styles.navigationIcon} />
                        {unreadNotificationsCount > 0 && (
                            <div className={styles.notificationsCounter}>
                                <Text size="small" color="white">
                                    {abbreviateNumber(unreadNotificationsCount)}
                                </Text>
                            </div>
                        )}
                    </div>
                ) : (
                    <Icon color={navLinkColor} className={styles.navigationIcon} />
                )}
                <Text
                    size="medium"
                    style={{ color: navLinkColor }}
                >
                    {t(translateKey)}
                </Text>
            </Box>
        </NavLinkWrapper>
    );
}
