import { IDrivingSchool } from "../driving-school/driving-school.model";

export interface IInstructorDrivingSchool extends Omit<IDrivingSchool, "id" | "partner"> {
    phone: string;
    state: string;
    studentsLastMonth: number;
    studentsThisMonth: number;
    totalStudents: number;
    activeStudents: number;
}

export const createInstructorDrivingSchool = ({ ...props }: Partial<IInstructorDrivingSchool>) => {
    return {
        name: "",
        phone: "",
        state: "",
        studentsLastMonth: null,
        studentsThisMonth: null,
        totalStudents: null,
        activeStudents: null,
        city: "",
        postalCode: "",
        address: "",
        ...props,
    } as IInstructorDrivingSchool;
};
