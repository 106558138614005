import { useMemo, useState } from "react";
import { useTranslation } from "core/hooks/use-translation.hook";
import { DateGroupTypeEnum } from "../enums/date-group-type.enum";
import { IChartTab } from "../interfaces/chart-tab.interface";
import { getStartAndEndDate } from "../utils/get-start-end-date";

export const useStudentAccountsCreatedTabs = () => {
    const { t } = useTranslation();
    const tabs = useMemo(() => ([
        {
            id: 1,
            name: t("current-year"),
            type: DateGroupTypeEnum.MONTH,
            ...getStartAndEndDate("currentYear"),
        },
        {
            id: 2,
            name: t("current-month"),
            type: DateGroupTypeEnum.WEEK,
            ...getStartAndEndDate("currentMonth"),
        },
        {
            id: 3,
            name: t("previous-month"),
            type: DateGroupTypeEnum.WEEK,
            ...getStartAndEndDate("previousMonth"),
        },
        {
            id: 4,
            name: t("last-year"),
            type: DateGroupTypeEnum.MONTH,
            ...getStartAndEndDate("previousYear"),
        },
    ] as Array<IChartTab>), []);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return {
        tabs,
        selectedTab,
        setSelectedTab,
    };
}
