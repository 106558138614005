import React from "react";
import { ROUTES } from "modules/home/enums/routes.enum";
import Box from "@material-ui/core/Box";
import Text from "core/components/Text";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "assets/icons";
import styles from "./styles.module.scss";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";

type ToolbarContentDirection = "row" | "column";

export interface IToolbarProps {
    title?: string;
    backToPath?: ROUTES | SHARED_ROUTES;
    actions?: React.ReactNode;
    direction?: ToolbarContentDirection;
}

export const Toolbar = ({
    title,
    backToPath,
    actions,
    direction = "row",
}: IToolbarProps) => (
    <Box
        display="flex"
        alignItems={direction === "row" ? "center" : "flex-start"}
        justifyContent="space-between"
        gap={2}
        minHeight="43px"
        width={1}
    >
        <Box
            display="flex"
            flexDirection={direction}
            alignItems={direction === "row" ? "center" : "flex-start"}
            gap="24px"
        >
            {backToPath && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={styles.chevronLeftContainer}
                >
                    <Link
                        to={backToPath}
                        className={styles.chevronLeftLink}
                    >
                        <ChevronLeftIcon />
                    </Link>
                </Box>
            )}
            {title && (
                <Text size="large">{title}</Text>
            )}
        </Box>
        {actions ?? <></>}
    </Box>
);
