import { useContext } from "react";
import { MultiLanguageSupportContext } from "core/context/MultiLanguageSupportProvider";

export const useTranslation = () => {
    const context = useContext(MultiLanguageSupportContext);

    if (context === undefined) {
        throw new Error("MultiLanguageSupportContext must be within MultiLanguageSupportProvider");
    }

    return context;
};
