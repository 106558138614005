import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { SearchIcon } from "assets/icons";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { ISearchEntityProps } from "core/interfaces/search-entity-props.interface";
import { ISearchEntity } from "core/components/EntitySearchResults";
import { HeaderWrapper } from "core/components/HeaderWrapper";
import { HeaderContainer } from "../HeaderContainer";
import colors from "styles/variables/colors.module.scss";

export interface ISearchbarProps {
    title: string;
    searchProps: ISearchEntityProps<ISearchEntity>;
}

export const Searchbar = ({
    title,
    searchProps,
}: ISearchbarProps) => {
    const [openSearchInput, setOpenSearchInput] = useState(false);

    const onSearchIconClick = () => {
        setOpenSearchInput(true);
    }

    const searchInputRef = useRef<HTMLInputElement>();
    useEffect(() => {
        if (!openSearchInput) return;

        searchInputRef.current?.focus();
    }, [openSearchInput]);

    return (
        <HeaderWrapper searchProps={searchProps}>
            {({
                onInputChange,
                inputPlaceholder,
                searchQuery,
                debouncedSearchQuery,
                queryKey,
            }) => (
                <HeaderContainer backgroundColor={
                    searchQuery && debouncedSearchQuery?.trim()
                        ? colors.white
                        : "transparent"
                }>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                        width={1}
                    >
                        <Text size="large">{title}</Text>
                        {!openSearchInput ? (
                            <SearchIcon
                                className={styles.searchIcon}
                                onClick={onSearchIconClick}
                            />
                        ) : (
                            <Box width="448px" height="43px">
                                <FormInput
                                    inputRef={searchInputRef}
                                    name={queryKey}
                                    value={searchQuery}
                                    type={FormInputType.TEXT}
                                    placeholder={inputPlaceholder}
                                    onChange={onInputChange}
                                    startAdornment={{
                                        showAdornment: true,
                                        AdornmentIcon: <SearchIcon />,
                                    }}
                                    autoComplete="off"
                                />
                            </Box>
                        )}
                    </Box>
                </HeaderContainer>
            )}
        </HeaderWrapper>
    );
}
