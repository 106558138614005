import { apiService } from "./api-service";
import { API_ROUTES } from "core/enums/api-routes.enum";
import { exportFile } from "core/utils/export-file";

class ExportDataService {
    async exportInstructors(): Promise<void> {
        const response = apiService.responseHandler(
            await apiService.get<string>(
                API_ROUTES.EXPORT_INSTRUCTORS,
                { responseType: "blob" },
            )
        );

        exportFile(response, {
            filename: "instructors",
        });
    }

    async exportDrivingSchools(): Promise<void> {
        const response = apiService.responseHandler(
            await apiService.get<string>(
                API_ROUTES.EXPORT_DRIVING_SCHOOLS,
                { responseType: "blob" },
            )
        );

        exportFile(response, {
            filename: "driving-schools",
        });
    }

    async exportStudents(): Promise<void> {
        const response = apiService.responseHandler(
            await apiService.get<string>(
                API_ROUTES.EXPORT_STUDENTS,
                { responseType: "blob" },
            )
        );

        exportFile(response, {
            filename: "students",
        });
    }

    async exportWatchedLessons(): Promise<void> {
        const response = apiService.responseHandler(
            await apiService.get<string>(
                API_ROUTES.EXPORT_WATCHED_LESSONS,
                { responseType: "blob" },
            )
        );

        exportFile(response, {
            filename: "watched-videos",
        });
    }
}

export const exportDataService = new ExportDataService();
