import { createStudentAccountsCreated, IStudentAccountsCreated } from "./student-accounts-created.model";

export interface IStudentAccountsCreatedResponse {
    accounts: number;
    month: string;
    year: string;
    week: string;
    start_of_week: string;
}

export const createStudentAccountsCreatedFromResponse = ({
    start_of_week,
    ...props
}: IStudentAccountsCreatedResponse): IStudentAccountsCreated => {
    return createStudentAccountsCreated({
        startOfWeek: start_of_week,
        ...props,
    });
}
