import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";

export function getItemFromLocalStorage(key: LocalStorageKeys) {
    try {
        const item = window.localStorage.getItem(key);

        return item ? JSON.parse(item) : null;
    } catch (error) {
        console.error(`Can not get item with key ${key} from local storage - `, error);
    }
}

export function setItemToLocalStorage(key: LocalStorageKeys, value: any) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(`Can not set item with key ${key} to local storage - `, error);
    }
}

export function removeItemFromLocalStorage(key: LocalStorageKeys) {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.error(`Can not remove item with key ${key} from local storage - `, error);
    }
}

export function clearLocalStorage() {
    try {
        window.localStorage.clear();
    } catch (error) {
        console.error("Error on local storage clear function - ", error);
    }
}
