import React, { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styles from "./styles.module.scss";

interface IProps extends NavLinkProps {
    enabled: boolean;
}

export const NavLinkWrapper: FC<IProps> = ({
    enabled,
    children,
    ...navLinkProps
}) => {
    if (!enabled) {
        return (
            <>
                {children}
            </>
        );
    };

    return (
        <NavLink
            {...navLinkProps}
            activeClassName={styles.active}
        >
            {children}
        </NavLink>
    );
}
