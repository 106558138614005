import { useContext } from "react";
import { StudentsContext } from "../context/StudentsProvider";

export const useStudentsContext = () => {
    const context = useContext(StudentsContext);

    if (context === undefined) {
        throw new Error("StudentsContext must be within StudentsProvider");
    }

    return context;
};
