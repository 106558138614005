import { createContext, FC, useState } from "react";

interface IStudentsContext {
    activePage: number;
    setActivePage: (page: ((previousPage: number) => number) | number) => void;
}

export const StudentsContext = createContext<IStudentsContext>({} as IStudentsContext);

export const StudentsProvider: FC = ({ children }) => {
    const [activePage, setActivePage] = useState(1);

    const value = {
        activePage,
        setActivePage,
    };

    return (
        <StudentsContext.Provider value={value}>
            {children}
        </StudentsContext.Provider>
    );
};
