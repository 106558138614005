import { useQuery } from "react-query";
import { themeService } from "core/services/theme.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { ILessonsParams } from "../interfaces/lessons-params.interface";

export const useThemeLessons = ({ themeId }: Pick<ILessonsParams, "themeId">) => {
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["themeLessons", themeId],
        () => themeService.getThemeLessons(themeId),
        {
            onError: (error: Error) => {
                console.log("Error on getting theme lessons - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
