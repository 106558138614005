import { useMemo } from "react";
import { object } from "yup";
import {
    stringValidationSchema,
    emailValidationSchema,
} from "core/utils/form-validation-schema";

export function useAddStudentValidationSchema() {
    return useMemo(
        () => object().shape({
            first_name: stringValidationSchema(),
            last_name: stringValidationSchema(),
            email: emailValidationSchema(),
        }),
        []
    );
}

export function useEditStudentValidationSchema() {
    return useMemo(
        () => object().shape({
            first_name: stringValidationSchema(),
            last_name: stringValidationSchema(),
        }),
        []
    );
}
