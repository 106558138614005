import { useQuery, useQueryClient } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { IInstructor } from "core/models/instructor/instructor.model";
import { instructorService } from "core/services/instructor.service";
import { handleErrorMessage } from "core/utils/handle-error-message";

interface IUseInstructor {
    instructorId: string;
}

export const useInstructor = ({ instructorId }: IUseInstructor) => {
    const queryClient = useQueryClient();
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery<IInstructor, Error>(
        ["instructor", instructorId],
        () => instructorService.getInstructor(instructorId),
        {
            onError: (error: Error) => {
                console.log("Error on getting instructor - ", error);
            },
            initialData: () => queryClient.getQueryData(["instructor", instructorId]),
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
