import { IUserCredentials } from "core/interfaces/user-credentials.interface";

export interface ISignUpCredentials extends IUserCredentials {
    driving_school_name: string;
    address: string;
    zip_code: number | string;
    city: string;
}

export const defaultSignUpValues: ISignUpCredentials = {
    driving_school_name: "",
    address: "",
    zip_code: "",
    city: "",
    email: "",
    password: "",
    confirm_password: "",
}
