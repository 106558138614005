import { useInfiniteQuery } from "react-query";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { simulationService } from "core/services/simulation.service";
import { sharedStudentService } from "core/pages/SharedStudentProfile/service/shared-student.service";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IUseSimulations {
    studentId: string;
    studentToken: string;
}

export const useSimulations = ({ studentId, studentToken }: IUseSimulations) => {
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
        isError,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["simulations", studentId ?? studentToken],
        ({ pageParam = 1 }) => {
            if (pageParam === undefined) return; // No prev or next page

            return studentToken && isSharedRoute
                ? sharedStudentService.getSimulations({
                    page: pageParam,
                    limit: 12,
                    studentToken,
                }) : simulationService.getSimulations({
                    page: pageParam,
                    limit: 12,
                    studentId,
                });
        },
        {
            onError: (err: Error) => {
                console.log("Error on getting simulations - ", err);
            },
            getPreviousPageParam: (firstPage) => {
                if (firstPage.currentPage > 1) {
                    return firstPage.currentPage - 1;
                }

                return undefined;
            },
            getNextPageParam: (lastPage) => {
                if (lastPage.currentPage < lastPage.totalPages) {
                    return lastPage.currentPage + 1;
                }

                return undefined;
            },
            keepPreviousData: true,
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    };
}
