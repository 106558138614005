import { IUserProgress } from "core/models/user/user-progress.model";
import { calculatePercentage } from "core/utils/calculate-percentage";
import { roundNumber } from "core/utils/round-number";

/**
 * Returns student progress values in percentages.
 */
export function calculateStudentProgress(studentProgress: Omit<IUserProgress, "successRate">) {
    const {
        total,
        correct,
        incorrect,
    } = studentProgress ?? {};

    const calculatedCorrectAnswers: number = calculatePercentage(correct, total);
    const calculatedIncorrectAnswers: number = calculatePercentage(incorrect, total);

    return {
        correctAnswers: calculatedCorrectAnswers,
        wrongAnswers: calculatedIncorrectAnswers,
        totalAnswers: roundNumber(calculatedCorrectAnswers + calculatedIncorrectAnswers),
    };
}
