export interface IQuestion {
    id: string;
    position: number;
}

export const createQuestion = ({ id, ...props }: Partial<IQuestion>) => {
    if (!id) {
        throw new Error('Unable to create a question without an ID!');
    }

    return {
        id,
        position: null,
        ...props,
    } as IQuestion;
};
