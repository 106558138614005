import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { SearchIcon } from "assets/icons";
import { HeaderWrapper, IHeaderWrapperChildrenProps } from "core/components/HeaderWrapper";
import { getUserNameBasedOnRole } from "core/models/user/user.model";
import { useUser } from "modules/home/hooks/use-user.hook";
import Text from "core/components/Text";
import { ISearchEntityProps } from "core/interfaces/search-entity-props.interface";
import { ISearchEntity } from "core/components/EntitySearchResults";
import styles from "./styles.module.scss";
import { ROUTES } from "modules/home/enums/routes.enum";

export interface IPageContainerProps {
    headerSearchProps?: ISearchEntityProps<ISearchEntity>;
    fetchHeaderData?: boolean;
}

export const PageContainer: FC<IPageContainerProps> = ({
    headerSearchProps,
    fetchHeaderData = true,
    children,
}) => {
    const { user, isLoading } = useUser({ enabled: fetchHeaderData });

    const userName = useMemo(() => {
        if (!user || isLoading) return "";

        return getUserNameBasedOnRole(user);
    }, [user, isLoading]);

    return (
        <div className={styles.pageContainer}>
            <HeaderWrapper searchProps={headerSearchProps}>
                {({
                    onInputChange,
                    inputPlaceholder,
                    hasSearchProps,
                    searchQuery,
                    queryKey,
                }: IHeaderWrapperChildrenProps) => (
                    <div className={styles.headerContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={hasSearchProps ? "space-between" : "flex-end"}
                            height="24px"
                        >
                            {hasSearchProps && (
                                <div className={styles.searchContainer}>
                                    <SearchIcon />
                                    <input
                                        name={queryKey}
                                        value={searchQuery}
                                        onChange={onInputChange}
                                        placeholder={inputPlaceholder}
                                        spellCheck="false"
                                        autoComplete="off"
                                        className={styles.searchInput}
                                    />
                                </div>
                            )}
                            <Link to={ROUTES.USER_PROFILE}>
                                <Text bold>
                                    {userName}
                                </Text>
                            </Link>
                        </Box>
                    </div>
                )}
            </HeaderWrapper>
            <div className={styles.contentContainer}>
                {children}
            </div>
        </div>
    );
}
