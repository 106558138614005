import React, { createContext, FC, useEffect } from "react";
import { useTranslation, ValidTranslationKeys } from "react-i18next";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { setItemToLocalStorage } from "core/utils/local-storage-handler";

interface MultiLanguageSupportProps {
    language: string;
    setLanguage: (lang: string) => void;
    t: (key: keyof ValidTranslationKeys, ...args: any) => string;
}

export const MultiLanguageSupportContext = createContext<MultiLanguageSupportProps>({
    language: "",
    setLanguage: () => null,
    t: () => null,
});

export const MultiLanguageSupportProvider: FC = ({ children }) => {
    const [ t, i18n ] = useTranslation();

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    }

    useEffect(() => {
        setLanguage(i18n.language);
        setItemToLocalStorage(LocalStorageKeys.appLang, i18n.language);
    }, [i18n.language]);

    return (
        <MultiLanguageSupportContext.Provider
            value={{
                language: i18n.language,
                setLanguage,
                t: (key: keyof ValidTranslationKeys, ...args: any) => t(key, ...args),
            }}
        >
            {children}
        </MultiLanguageSupportContext.Provider>
    );
};
