import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import { ButtonType } from "core/enums/button-type.enum";

interface IProps {
    isLoading: boolean;
    description: string;
}

export const LessonDescription = ({
    isLoading,
    description,
}: IProps) => {
    const { t } = useTranslation();

    const [showMore, setShowMore] = useState<boolean | undefined>(undefined);
    const lessonDescriptionContainerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (isLoading || !lessonDescriptionContainerRef.current) return;

        const { clientHeight, scrollHeight } = lessonDescriptionContainerRef.current;

        if (clientHeight === scrollHeight) return;

        setShowMore(scrollHeight > clientHeight);
    }, [isLoading, lessonDescriptionContainerRef.current])

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <Text bold size="medium">
                {t("more-about-the-lesson")}
            </Text>
            <div
                ref={lessonDescriptionContainerRef}
                className={styles.lessonDescriptionContainer}
                style={{
                    maxHeight: showMore !== false ? "320px" : "unset",
                    overflow: showMore !== false ? "hidden" : "unset",
                    marginBottom: showMore === undefined ? "0px" : "24px",
                }}
            >
                <Text style={{ whiteSpace: "pre-wrap" }}>
                    {description}
                </Text>
            </div>
            {showMore === true ? (
                <Button
                    label={t("see-more")}
                    type={ButtonType.TEXT}
                    className={styles.seeMoreLessButton}
                    textColor="violet"
                    onClick={() => setShowMore(false)}
                />
            ) : showMore === false ? (
                <Button
                    label={t("see-less")}
                    type={ButtonType.TEXT}
                    className={styles.seeMoreLessButton}
                    textColor="violet"
                    onClick={() => setShowMore(true)}
                />
            ) : <></>}
        </Box>
    );
}
