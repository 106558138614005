import React from "react";
import { Box } from "@material-ui/core";
import { UserProfileForm } from "./components/UserProfileForm";
import PageContainer from "modules/home/components/PageContainer";
// import { ChooseAppLanguage } from "./components/ChooseAppLanguage";

/**
 * ! App language switcher is not needed at the moment, so it's temporarily removed.
 */
export const UserProfilePage = () => (
    <PageContainer>
        <Box display="flex">
            <Box width="448px" marginRight="112px">
                <UserProfileForm />
            </Box>
            {/* <Box mt="66px" width={1} maxWidth="448px">
                <ChooseAppLanguage />
            </Box> */}
        </Box>
    </PageContainer>
);
