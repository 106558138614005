import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { ISearchEntityProps } from "core/interfaces/search-entity-props.interface";
import { useDebounce } from "core/hooks/use-debounce.hook";
import { EntitySearchResults, ISearchEntity } from "core/components/EntitySearchResults";

export interface IHeaderWrapperChildrenProps {
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputPlaceholder: string;
    hasSearchProps: boolean;
    searchQuery: string;
    debouncedSearchQuery: string;
    queryKey: string;
}

export interface IHeaderWrapperProps {
    searchProps?: ISearchEntityProps<ISearchEntity>;
    children: ({
        onInputChange,
        inputPlaceholder,
        hasSearchProps,
        searchQuery,
        debouncedSearchQuery,
        queryKey,
    }: IHeaderWrapperChildrenProps) => React.ReactNode;
}

export const HeaderWrapper = ({
    searchProps,
    children,
}: IHeaderWrapperProps) => {
    const {
        placeholder,
        queryFunction,
        queryKey,
        loadingIndicator,
        type,
    } = searchProps ?? {};

    const hasSearchProps: boolean = useMemo(
        () => !!(searchProps && Object.keys(searchProps).length > 0),
        [searchProps]
    );

    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }

    const pageContentWrapperElement: HTMLElement = document.getElementById("pageContentWrapperElement");
    const { pathname } = useLocation();

    useEffect(() => {
        // Hide search results container on page change
        searchQuery && setSearchQuery("");
    }, [pathname]);

    useEffect(() => {
        if (!pageContentWrapperElement || !searchQuery) return;

        // Block page scrolling when in search mode
        pageContentWrapperElement.style.overflowY = "hidden";

        return () => {
            pageContentWrapperElement.style.overflowY = "auto";
        };
    }, [searchQuery]);

    return (
        <>
            <Box zIndex={2000}>
                {children({
                    onInputChange,
                    inputPlaceholder: placeholder,
                    hasSearchProps,
                    searchQuery,
                    debouncedSearchQuery,
                    queryKey,
                })}
            </Box>
            {hasSearchProps && searchQuery && debouncedSearchQuery?.trim() && (
                <EntitySearchResults
                    searchQuery={debouncedSearchQuery}
                    queryFunction={queryFunction}
                    queryKey={queryKey}
                    type={type}
                    loadingIndicator={loadingIndicator}
                />
            )}
        </>
    );
}

HeaderWrapper.propTypes = {
    children: PropTypes.func.isRequired,
};
