export interface INotification {
    id: string;
    message: string;
    readByUser: boolean;
    createdAt: string;
    readAt: string;
}

export const createNotification = ({ id, ...props }: Partial<INotification>) => {
    if (!id) {
        throw new Error('Unable to create a notification without an ID!');
    }

    return {
        id,
        message: "",
        readByUser: false,
        createdAt: null,
        readAt: null,
        ...props,
    } as INotification;
};

export const getUnreadNotifications = (notifications: Array<INotification>) => {
    return notifications?.filter(({ readByUser }) => !readByUser) ?? [];
}
