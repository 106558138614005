import { createTag, ITag } from "./lesson-tag.model";

export interface ITagResponse {
    id: number;
    name: string;
}

export const createTagFromResponse = ({
    id,
    ...props
}: ITagResponse): ITag => {
    return createTag({
        id: id?.toString(),
        ...props,
    });
};
