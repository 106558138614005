import { string, ref } from "yup";
import { t } from "core/utils/multi-language-support";

const passwordRegex = /^(?=.*[A-Z])(?!.*[\s])(?=.*[0-9])(?=.*[!#"$%&\\/()*\\+@?^:,."'|{}\\[\]\-~_`]).{8,}$/gm;

export const stringValidationSchema = (shouldTrim = true) => {
    const requiredStringField = string().required(t("required-field"));

    return shouldTrim
        ? requiredStringField.trim()
        : requiredStringField;
};

export const emailValidationSchema = () => stringValidationSchema().email(t("invalid-email-format"));

export const passwordValidationSchema = (usePasswordRegex = true) => {
    return usePasswordRegex
        ? stringValidationSchema(false).matches(passwordRegex, t("invalid-password-format"))
        : stringValidationSchema(false);
}

export const confirmPasswordValidationSchema = (passwordKey: string) => stringValidationSchema(false)
    .oneOf([ref(passwordKey)], t("password-mismatch"));

export const dateValidationSchema = () => {
    return stringValidationSchema().nullable().test(
        "is-valid-date",
        t("invalid-date-format"),
        (value: any) => {
            if (["Invalid Date", null, undefined].includes(value)) {
                return false;
            }

            return true;
        },
    );
}
