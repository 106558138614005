import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { IThemeLessons } from "core/models/theme/theme-lessons.model";
import { createThemeLessonsFromResponse, IThemeLessonsResponse } from "core/models/theme/theme-lessons.response";
import { ITheme } from "core/models/theme/theme.model";
import { createThemesFromResponse, IThemeResponse } from "core/models/theme/theme.response";
import { pathBuilder } from "core/utils/path-builder";
import { apiService } from "./api-service";

class ThemeService {
    async getThemes({
        studentId,
        ...searchParams
    }: ISearchParams & { studentId?: string }): Promise<ISearch<ITheme>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<IThemeResponse>>(
                API_ROUTES.THEMES,
                {
                    params: {
                        ...searchParams,
                        for_student: studentId,
                    },
                }
            )
        );

        return createThemesFromResponse(response);
    }

    async getThemeLessons(
        themeId: string,
    ): Promise<IThemeLessons> {
        const response = apiService.responseHandler(
            await apiService.get<IThemeLessonsResponse>(
                pathBuilder(
                    API_ROUTES.THEME_LESSONS,
                    "{themeId}",
                    themeId,
                )
            )
        );

        return createThemeLessonsFromResponse(response);
    }
}

export const themeService = new ThemeService();
