import { ISearch } from "../search/search.model";
import { createSearchModelFromResponse, ISearchResponse } from "../search/search.response";
import { IThemeTranslation, ITranslations } from "../translation/translation.model";
import { createUserProgressFromResponse, IUserProgressResponse } from "../user/user-progress.response";
import { createTheme, ITheme } from "./theme.model";

export interface IThemeResponse {
    id: number;
    translations: ITranslations<IThemeTranslation>;
    media: string;
    number_of_lessons: number;
    included_in_trial_version_only: boolean;
    user_progress: IUserProgressResponse;
}

export const createThemeFromResponse = ({
    id,
    number_of_lessons,
    included_in_trial_version_only,
    user_progress,
    ...props
}: IThemeResponse): ITheme => {
    return createTheme({
        id: id?.toString(),
        numberOfLessons: number_of_lessons,
        includedInTrialVersionOnly: included_in_trial_version_only,
        userProgress: user_progress ? createUserProgressFromResponse(user_progress) : null,
        ...props,
    });
};

export const createThemesFromResponse = ({
    items,
    ...props
}: ISearchResponse<IThemeResponse>): ISearch<ITheme> => {
    return createSearchModelFromResponse({
        items: items?.map(createThemeFromResponse) ?? [],
        ...props,
    });
};
