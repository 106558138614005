import React from "react";
import { RGBAColors } from "styles/variables/rgba-colors";

export const CustomizedXAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
        <text
            x={0}
            y={0}
            dy={3}
            textAnchor="end"
            fill={RGBAColors.lightBlackWithOpacity(0.38)}
            fontSize={12}
            transform="rotate(-90)"
        >
            {payload.value}
        </text>
    </g>
);
