import React, { useMemo } from "react";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { Searchbar } from "modules/sw-admin/components/Searchbar";
import { useTranslation } from "core/hooks/use-translation.hook";
import { EntityType } from "core/enums/entity-type.enum";
import { Tabs } from "core/components/Tabs";
import { useTabs } from "../../../hooks/use-tabs.hook";
import { instructorService } from "core/services/instructor.service";
import { InstructorAccountType } from "../../enums/instructor-account-type.enum";
import { OfficialAccountsTable } from "./components/OfficialAccountsTable";
import { TrialPeriodTable } from "./components/TrialPeriodTable";
import { UnverifiedTable } from "./components/UnverifiedTable";
import { DeclinedTable } from "./components/DeclinedTable";

export const DrivingSchoolList = () => {
    const { t } = useTranslation();
    const {
        tabs,
        activeTab,
        setActiveTab,
    } = useTabs();

    const searchProps = useMemo(() => ({
        queryFunction: instructorService.getInstructors,
        queryKey: "instructorDrivingSchoolSearch",
        placeholder: t("search-driving-schools-placeholder"),
        loadingIndicator: t("loading-entity", { entityName: t("driving-schools") }),
        type: EntityType.INSTRUCTOR,
    }), []);

    const renderActiveTabTable = () => {
        switch (activeTab.id) {
            case InstructorAccountType.OFFICIAL_ACCOUNTS: return <OfficialAccountsTable />;
            case InstructorAccountType.TRIAL_PERIOD: return <TrialPeriodTable />;
            case InstructorAccountType.UNVERIFIED: return <UnverifiedTable />;
            case InstructorAccountType.DECLINED: return <DeclinedTable />;
            default: return <></>;
        }
    };

    return (
        <>
            <Searchbar
                title={t("driving-schools")}
                searchProps={searchProps}
            />
            <ContentContainer>
                <Tabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                {renderActiveTabTable()}
            </ContentContainer>
        </>
    );
}
