import React from "react";
import Box from "@material-ui/core/Box";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";

interface IProps {
    lessonName: string;
    videoDuration: string;
    quizAccessToken: string;
}

export const LessonVideoDetails = ({
    lessonName,
    videoDuration,
    quizAccessToken,
}: IProps) => {
    const { t } = useTranslation();

    const onQuestionTimeButtonClick = () => window.open(
        `${process.env.REACT_APP_FLUTTER_WEB_URL}/preview-quiz/${quizAccessToken}`,
        "_blank",
        "noreferrer",
    );

    return (
        <Box
            display="grid"
            flexDirection="column"
            gap={3}
        >
            <Text size="large">
                {lessonName}
            </Text>

            <Text color="gray">
                {videoDuration}
            </Text>

            <Button
                label={t("question-time")}
                disabled={!quizAccessToken}
                onClick={onQuestionTimeButtonClick}
            />
        </Box>
    );
}
