import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { StreamwaysLogoTextImage } from "assets/images";
import { useTranslation } from "core/hooks/use-translation.hook";
import Text from "core/components/Text";
import { ROUTES } from "modules/home/enums/routes.enum";

export const Header = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="60px"
            p="15px"
            gap={2}
        >
            <StreamwaysLogoTextImage />
            <Box
                display="flex"
                alignItems="center"
                gap="4px"
            >
                {pathname === ROUTES.SIGN_UP ? (
                    <>
                        <Text bold>{t("have-account")}</Text>
                        <Link to={ROUTES.SIGN_IN}>
                            <Text bold color="violet">{t("log-in")}</Text>
                        </Link>
                    </>
                ) : (
                    <>
                        <Text bold>{t("dont-have-account")}</Text>
                        <Link to={ROUTES.SIGN_UP}>
                            <Text bold color="violet">{t("register")}</Text>
                        </Link>
                    </>
                )}
            </Box>
        </Box>
    );
}
