import { formatISO, isValid, parse } from "date-fns";
import { DateFormat } from "core/enums/date-format.enum";
import { addLeadingZero } from "./number-leading-zero";

export const extractDate = (date: string) => {
    const dateObject = parse(
        date,
        DateFormat.ddMMyyyy,
        new Date(),
    );

    let [day, month, year] = ["0", "0", "0"];

    if (isValid(dateObject)) {
        day = addLeadingZero(dateObject.getDate());
        month = addLeadingZero(dateObject.getMonth() + 1); // getMonth() returns month from 0-11
        year = dateObject.getFullYear().toString();
    }

    return { day, month, year };
}

export const createDate = (day: string, month: string, year: string): Date | null => {
    const date = new Date(`${year}/${month}/${day}`);

    return isValid(date) ? date : null;
}

export const formatDateForBackend = (date: Date | string) => {
    if (!date) return "";

    let dateObject: Date;

    if (typeof date === "string") {
        dateObject = new Date(date);
    } else {
        dateObject = date;
    }

    return isValid(dateObject) ? formatISO(
        dateObject,
        { representation: "date" }
    ) : "";
}
