import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { getUserNameBasedOnRole, IUser } from "core/models/user/user.model";
import { InfoCard } from "modules/dashboard/components/InfoCard";
import { ROUTES } from "modules/home/enums/routes.enum";
import { StudentStatus } from "modules/students/enums/student-status.enum";
import colors from "styles/variables/colors.module.scss";

const buildStudentsQueryRoute = (status: StudentStatus) => `${ROUTES.STUDENTS}?status=${status}` as ROUTES;

interface IProps {
    user: IUser;
}

export const DrivingSchoolInfoCards = ({ user }: IProps) => {
    const { t } = useTranslation();

    const {
        userName,
        totalStudents,
        activeStudents,
        studentsPendingInvitation,
        studentsPassed,
        studentsReadyForExam,
    } = useMemo(() => {
        if (!user) {
            return {
                userName: "-",
                totalStudents: "-",
                activeStudents: "-",
                studentsPendingInvitation: "-",
                studentsPassed: "-",
                studentsReadyForExam: "-",
            };
        }

        return {
            userName: getUserNameBasedOnRole(user),
            totalStudents: user.drivingSchool?.totalStudents ?? "-",
            activeStudents: user.drivingSchool?.activeStudents ?? "-",
            studentsPendingInvitation: user.drivingSchool?.studentsPendingInvitation ?? "-",
            studentsPassed: user.drivingSchool?.studentsPassed ?? "-",
            studentsReadyForExam: user.drivingSchool?.studentsReadyForExam ?? "-",
        };
    }, [user]);

    return (
        <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            // gap={4}
            width={1}
        >
            <InfoCard
                title={t("total-students")}
                subTitle={t("total-driving-school-students", { drivingSchool: userName })}
                entityCount={totalStudents}
                goTo={ROUTES.STUDENTS}
                style={{
                    backgroundColor: colors.violet,
                    entityCountColor: "white",
                    titleColor: "white",
                }}
            />
            <InfoCard
                title={t("pending-invites")}
                subTitle={t("total-pending-invites")}
                entityCount={studentsPendingInvitation}
                goTo={buildStudentsQueryRoute(StudentStatus.INVITATION_PENDING)}
                style={{
                    entityCountColor: "orange",
                }}
            />
            <InfoCard
                title={t("active-students")}
                subTitle={t("total-active-students")}
                entityCount={activeStudents}
                goTo={buildStudentsQueryRoute(StudentStatus.ACTIVE)}
                style={{
                    entityCountColor: "green",
                }}
            />
            <InfoCard
                title={t("exam-ready-students")}
                subTitle={t("total-exam-ready-students")}
                entityCount={studentsReadyForExam}
                goTo={buildStudentsQueryRoute(StudentStatus.READY_FOR_EXAM)}
                style={{
                    entityCountColor: "blue",
                }}
            />
            <InfoCard
                title={t("passed-students")}
                subTitle={t("total-passed-students")}
                entityCount={studentsPassed}
                goTo={buildStudentsQueryRoute(StudentStatus.PASSED)}
                style={{
                    entityCountColor: "violet",
                }}
            />
        </Box>
    );
}
