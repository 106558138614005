import React from "react";
import { Box } from "@material-ui/core";
import { pathBuilder } from "core/utils/path-builder";
import { Toolbar } from "modules/home/components/Toolbar";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useParams } from "react-router-dom";
import { useTranslation } from "core/hooks/use-translation.hook";
import { SimulatorProgressItem } from "../SimulatorProgressItem";
import { useSimulations } from "modules/students/hooks/use-simulations.hook";
import InfiniteScroll from "core/components/InfiniteScroll";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { SimulationStatus } from "modules/students/enums/simulation-status.enum";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { IStudentParams } from "core/interfaces/student-params.interface";

export const SimulatorProgressList = () => {
    const { t } = useTranslation();
    const { studentId, studentToken } = useParams<IStudentParams>();
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useSimulations({
        studentId,
        studentToken,
    });

    return (
        <>
            <Box mb="24px">
                <Toolbar
                    title={t("simulator-progress")}
                    backToPath={(isSharedRoute && studentToken) ? pathBuilder(
                        SHARED_ROUTES.STUDENT_PROFILE,
                        ":studentToken",
                        studentToken,
                    ) as SHARED_ROUTES : pathBuilder(
                        ROUTES.STUDENT_PROFILE,
                        ":studentId",
                        studentId,
                    ) as ROUTES}
                />
            </Box>

            <InfiniteScroll
                hasMore={hasNextPage}
                isFetching={isFetchingNextPage}
                onFetchMore={fetchNextPage}
            >
                {isLoading ? (
                    <Loader />
                ) : data?.pages[0]?.totalResults > 0 ? (
                    <Box
                        display="grid"
                        flexDirection="column"
                        gap={2}
                    >
                        {data.pages.map((page, index) => (
                            <React.Fragment key={index}>
                                {page.items.map((item) => (
                                    <SimulatorProgressItem
                                        key={item.id}
                                        simulationId={item.id}
                                        studentId={studentId}
                                        studentToken={studentToken}
                                        startedAt={item.createdAt}
                                        isPassed={item.status === SimulationStatus.PASSED}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </Box>
                ) : (
                    <Text>{t("no-data-available")}</Text>
                )}
            </InfiniteScroll>
        </>
    );
}
