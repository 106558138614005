import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { InfoIcon } from "assets/icons";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import { useDialog } from "core/hooks/use-dialog.hook";
import { DialogType } from "core/enums/dialog-type.enum";
import { ButtonType } from "core/enums/button-type.enum";
import colors from "styles/variables/colors.module.scss";
import styles from "core/components/Dialog/styles.module.scss";
import { classNameFormatter } from "core/utils/css-classname-formatter";

export interface IUseConfirmationDialog {
    type: "success" | "error",
    title: string;
    description: string;
    actionButtonTitle: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export const useConfirmationDialog = ({
    type,
    title,
    description,
    actionButtonTitle,
    onConfirm,
    onCancel,
}: IUseConfirmationDialog) => {
    const { t } = useTranslation();
    const { clearDialogProps } = useDialog();

    const confirmationDialogProps = useMemo(() => ({
        open: true,
        title,
        type: DialogType.CONFIRMATION,
        header: (
            <Box
                width="1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb="24px"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classNameFormatter([
                        styles.confirmationDialogHeaderIcon,
                        type === "success" ? styles.success : styles.error,
                    ])}
                >
                    <InfoIcon
                        color={type === "success" ? colors.green : colors.lightRed}
                    />
                </Box>
            </Box>
        ),
        content: (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="8px"
                mb="32px"
            >
                <Text className={styles.confirmationDialogContentText}>
                    {description}
                </Text>
            </Box>
        ),
        actions: (
            <Box width="1">
                <Button
                    label={actionButtonTitle}
                    onClick={() => {
                        onConfirm && onConfirm();
                        clearDialogProps();
                    }}
                    className={classNameFormatter([
                        styles.confirmationDialogButton,
                        type === "success" ? styles.success : styles.error,
                    ])}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="43px"
                >
                    <Button
                        label={t("cancel")}
                        onClick={() => {
                            onCancel && onCancel();
                            clearDialogProps();
                        }}
                        type={ButtonType.TEXT}
                    />
                </Box>
            </Box>
        ),
    }), [
        type,
        title,
        description,
        actionButtonTitle,
        onConfirm,
        onCancel,
    ]);

    return confirmationDialogProps;
}
