import React, { FC } from "react";
import { Box } from "@material-ui/core";
import styles from "./styles.module.scss";
import Button, { IButtonProps } from "core/components/Button";
import Text from "core/components/Text";
import Loader from "core/components/Loader";
import { Toolbar, IToolbarProps } from "modules/home/components/Toolbar";

interface ISubmitButtonProps extends IButtonProps {
    isLoading: boolean;
    renderButtonDescription?: () => JSX.Element;
}

interface IFormWrapperProps extends IToolbarProps {
    submitButtonProps: ISubmitButtonProps;
    error?: string;
    subTitle?: string;
    elementRef?: React.RefObject<HTMLDivElement>;
}

export const FormWrapper: FC<IFormWrapperProps> = ({
    title,
    submitButtonProps,
    backToPath,
    error,
    subTitle,
    elementRef,
    actions,
    direction,
    children,
}) => {
    const {
        label,
        onClick,
        disabled,
        isLoading,
        renderButtonDescription,
    }: ISubmitButtonProps = submitButtonProps;

    return (
        <Box ref={elementRef} className={styles.formWrapper}>
            <Box mb="24px">
                <Toolbar
                    title={title}
                    backToPath={backToPath}
                    actions={actions}
                    direction={direction}
                />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                maxWidth="448px"
            >
                {subTitle && (
                    <Box mb="24px">
                        <Text bold>{subTitle}</Text>
                    </Box>
                )}

                {error && (
                    <Box display="flex" justifyContent="center" mb="16px">
                        <Text color="red" className={styles.errorText}>{error}</Text>
                    </Box>
                )}

                <form>
                    {children}
                </form>

                <Box
                    display="grid"
                    flexDirection="column"
                    alignItems="center"
                    mt="24px"
                    gap={2}
                >
                    {renderButtonDescription && renderButtonDescription()}
                    <Button
                        label={label}
                        onClick={onClick}
                        disabled={disabled}
                    />
                </Box>
            </Box>

            {isLoading && <Loader />}
        </Box>
    );
}
