import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { instructorService } from "core/services/instructor.service";
import { IStudentAccountsCreated } from "core/models/instructor/student-accounts-created.model";
import { DateGroupTypeEnum } from "../enums/date-group-type.enum";

interface IUseStudentAccountsCreated {
    instructorId: string;
    type: DateGroupTypeEnum;
    start: string;
    end: string;
}

export const useStudentAccountsCreated = ({
    instructorId,
    type,
    start,
    end,
}: IUseStudentAccountsCreated) => {
    const {
        data,
        isLoading,
    } = useQuery<Array<IStudentAccountsCreated>, Error>(
        ["student-accounts-created", instructorId, {
            type,
            start,
            end,
        }],
        () => instructorService.getStudentAccountsCreated(
            instructorId,
            {
                group_type: type,
                start,
                end,
            },
        ),
        {
            onError: (error: Error) => {
                console.log("Error on getting student accounts created data - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
            placeholderData: [], // Used to avoid loading state on type change
            enabled: !!instructorId,
        },
    );

    return {
        data,
        isLoading,
    };
}
