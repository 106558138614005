interface IException {
    message: string;
}

interface IDebug {
    exception: Array<IException>;
}

export interface IError extends Error {
    debug: IDebug;
}

export interface IErrorOptions {
    /**
     * Shows exception message if standard error message does not exist.
     *
     * Otherwise, default error message will be displayed.
     */
    showExceptionMessage: boolean;
}

export const defaultErrorOptions: IErrorOptions = {
    showExceptionMessage: false,
}
