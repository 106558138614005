import React from "react";
import {ValidTranslationKeys} from "react-i18next";
import {format} from "date-fns";
import {Box} from "@material-ui/core";
import Text from "core/components/Text";
import {useTranslation} from "core/hooks/use-translation.hook";
import styles from "./styles.module.scss";
import {FormInputType} from "core/enums/form-input-type.enum";
import FormInput from "core/components/FormInput";
import {CheckIcon, ExamIcon} from "assets/icons";
import {DateFormat} from "core/enums/date-format.enum";
import {AvatarPlaceholderImage} from "assets/images";
import {IStudent} from "core/models/student/student.model";
import Button from "core/components/Button";
import {useDialog} from "core/hooks/use-dialog.hook";
import {useConfirmationDialog} from "core/hooks/use-confirmation-dialog.hook";
import {StudentStatus} from "modules/students/enums/student-status.enum";
import {useChangeStudentStatus} from "modules/students/hooks/use-change-student-status.hook";
import {useReactivateStudent} from "../../../../hooks/use-reactivate-student.hook";
import {UserStatus} from "core/enums/user-status.enum";
import {useIsSharedRoute} from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IProps {
    student: IStudent;
}

export const StudentInfo = ({ student }: IProps) => {
    const {
        id,
        media,
        startedAt,
        email,
        examDate,
        code,
        status,
        active,
    } = student ?? {};

    const { isSharedRoute } = useIsSharedRoute();
    const { t } = useTranslation();
    const { changeStudentStatus } = useChangeStudentStatus({ studentId: id });
    const { reactivateStudent } = useReactivateStudent({ studentId: id });
    const { setDialogProps } = useDialog();
    const dialogProps = useConfirmationDialog({
        type: "success",
        title: t("mark-student-as-passed"),
        description: t("mark-student-as-passed-description"),
        actionButtonTitle: t("mark-student-as-passed"),
        onConfirm: () => changeStudentStatus(UserStatus.PASSED),
    });

    const reactivateDialogProps = useConfirmationDialog({
        type: "success",
        title: "Schüler reaktivieren",
        description: "Der Zugang dieses Schülers wurde automatisch deaktiviert. Möchtest du ihn wieder aktivieren?",
        actionButtonTitle: "Schüler wieder aktivieren",
        onConfirm: () => reactivateStudent(() => console.log()),
    });

    const day = student.startedAt.split('/')[0];
    const month = student.startedAt.split('/')[1];
    const year = student.startedAt.split('/')[2];
    const deactivatesAt = new Date(year + '-' + month + '-' + day);
    if (student.startedAt.valueOf() !== student.createdAt.valueOf()) {
        deactivatesAt.setMonth(deactivatesAt.getMonth() + 6);
    } else {
        deactivatesAt.setFullYear(deactivatesAt.getFullYear() + 1);
    }
console.log(student);
    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="35%"
            maxWidth="448px"
            mr="32px"
            ml="32px"
        >
            <Box
                display="grid"
                alignItems="center"
                flexDirection="column"
                gap={2}
                mb="24px"
            >
                {media ? (
                    <img
                        src={media}
                        alt="Student avatar"
                        className={styles.studentAvatar}
                    />
                ) : (
                    <AvatarPlaceholderImage className={styles.studentAvatar} />
                )}
                <Text bold size="medium">
                    {email}
                </Text>
            </Box>

            <Box
                display="grid"
                flexDirection="column"
                width={1}
                gap={2}
            >
                {(status === StudentStatus.PASSED || examDate) && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="12px"
                        className={styles.studentExamInfo}
                    >
                        {status === StudentStatus.PASSED ? (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    className={styles.iconWrapper}
                                >
                                    <CheckIcon />
                                </Box>
                                <Text color="white" bold>
                                    {t("student-passed-exam")}
                                </Text>
                            </>
                        ) : (
                            <>
                                <ExamIcon className={styles.iconSize} />
                                <Text color="white" bold>
                                    {t("student-should-be-ready-for-exam", {
                                        date: format(new Date(examDate), DateFormat.ddMMyyyy)
                                    })}
                                </Text>
                            </>
                        )}
                    </Box>
                )}
                {/*<FormInput*/}
                {/*    name="start_date"*/}
                {/*    label={t("start-date")}*/}
                {/*    value={startedAt ?? "-"}*/}
                {/*    type={FormInputType.TEXT}*/}
                {/*    onChange={() => null}*/}
                {/*    endAdornment={{*/}
                {/*        showAdornment: true,*/}
                {/*        AdornmentIcon: <CalendarIcon />,*/}
                {/*    }}*/}
                {/*    readOnly*/}
                {/*/>*/}
                <FormInput
                    name="student_id"
                    label={t("student-id")}
                    value={code ? code : "-"}
                    type={FormInputType.TEXT}
                    onChange={() => null}
                    readOnly
                />
                <FormInput
                    name="status"
                    label={t("status")}
                    value={t(status?.replaceAll("_", "-") as keyof ValidTranslationKeys) ?? "-"}
                    type={FormInputType.TEXT}
                    onChange={() => null}
                    readOnly
                />
                {student.status === "invitation_pending" ?
                        <a href={student.onboarding} target="_blank" rel={'noreferrer'} style={{width: '100%'}}>
                            <Button
                                label={t("download-onboarding")}
                                style={{ width: "fit-content" }}
                            />
                        </a>
                    : (status !== StudentStatus.PASSED && active && !isSharedRoute) && (
                        <Button
                            label={t("mark-student-as-passed")}
                            onClick={() => setDialogProps({ ...dialogProps })}
                        />
                )}
                {deactivatesAt.getTime() >= Date.now() && student.active && (
                    <Text size="small">Account wird am {deactivatesAt.toLocaleDateString()} automatisch deaktiviert</Text>
                )}
                {deactivatesAt.getTime() < Date.now() && (
                    <Text size="small">Account wurde automatisch deaktiviert.</Text>
                )}
                {
                    !student.active && student.status !== "invitation_pending" && (
                        <Button
                            label={"Schüler wieder aktivieren"}
                            onClick={() => setDialogProps({ ...reactivateDialogProps })}
                        />
                    )
                }
            </Box>
        </Box>
    );
}
