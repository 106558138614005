import qs from "qs";
import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { IInstructor } from "core/models/instructor/instructor.model";
import {
    createInstructorFromResponse,
    createInstructorsFromResponse,
    IInstructorResponse,
} from "core/models/instructor/instructor.response";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { InstructorAccountType } from "modules/sw-admin/driving-schools/enums/instructor-account-type.enum";
import { apiService } from "./api-service";
import { pathBuilder } from "core/utils/path-builder";
import { IStudentAccountsCreated } from "core/models/instructor/student-accounts-created.model";
import {
    createStudentAccountsCreatedFromResponse,
    IStudentAccountsCreatedResponse,
} from "core/models/instructor/student-accounts-created.response";
import { IEditInstructorCommand } from "core/models/instructor/instructor.command";
import { DateGroupTypeEnum } from "core/components/StudentAccountsCreatedChart/enums/date-group-type.enum";

class InstructorService {
    async getInstructor(
        instructorId: string,
    ): Promise<IInstructor> {
        const response = apiService.responseHandler(
            await apiService.get<IInstructorResponse>(
                pathBuilder(
                    API_ROUTES.INSTRUCTOR,
                    "{instructorId}",
                    instructorId
                ),
            )
        );

        return createInstructorFromResponse(response);
    }

    async editInstructor(
        instructorId: string,
        data: IEditInstructorCommand,
    ): Promise<IInstructor> {
        const response = apiService.responseHandler(
            await apiService.put<IInstructorResponse, IEditInstructorCommand>(
                pathBuilder(
                    API_ROUTES.INSTRUCTOR,
                    "{instructorId}",
                    instructorId
                ),
                data,
            )
        );

        return createInstructorFromResponse(response);
    }

    async getInstructors(
        searchParams?: ISearchParams,
        type?: InstructorAccountType,
    ): Promise<ISearch<IInstructor>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<IInstructorResponse>>(
                API_ROUTES.INSTRUCTORS,
                {
                    params: {
                        ...searchParams,
                        filters: { "status": type },
                    },
                    paramsSerializer: (params) => qs.stringify(params),
                }
            )
        );

        return createInstructorsFromResponse(response);
    }

    async getStudentAccountsCreated(
        instructorId: string,
        params: {
            group_type: DateGroupTypeEnum;
            start: string;
            end: string;
        },
    ): Promise<Array<IStudentAccountsCreated>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IStudentAccountsCreatedResponse>>(
                pathBuilder(
                    API_ROUTES.STUDENT_ACCOUNTS_CREATED,
                    "{instructorId}",
                    instructorId
                ),
                {
                    params: {...params},
                },
            )
        );

        return response?.map(createStudentAccountsCreatedFromResponse) ?? [];
    }
}

export const instructorService = new InstructorService();
