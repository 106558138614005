import React, { FC } from "react";
import { Box } from "@material-ui/core";

export const PageContainer: FC = ({ children }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100%"
        >
            {children}
        </Box>
    );
}
