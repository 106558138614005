import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import { CircularProgressBar } from "core/components/CircularProgressBar";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { CheckIcon, ChevronRightIcon, XIcon } from "assets/icons";
import { calculateStudentProgress } from "modules/students/utils/calculate-student-progress";
import { IStudent } from "core/models/student/student.model";
import { ROUTES } from "modules/home/enums/routes.enum";
import { pathBuilder } from "core/utils/path-builder";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { IStudentParams } from "core/interfaces/student-params.interface";

interface IProps {
    student: IStudent;
}

export const OverallProgressCard = ({ student }: IProps) => {
    const { t } = useTranslation();
    const { studentId, studentToken } = useParams<IStudentParams>();
    const { isSharedRoute } = useIsSharedRoute();

    const {
        overallTotal,
        overallCorrect,
        overallIncorrect,
    } = student?.userProgress ?? {
        overallTotal: 0,
        overallCorrect: 0,
        overallIncorrect: 0,
    };

    const studentOverallProgress = useMemo(
        () => calculateStudentProgress({
            total: overallTotal,
            correct: overallCorrect,
            incorrect: overallIncorrect,
        }),
        [overallTotal, overallCorrect, overallIncorrect]
    );

    return (
        <Link
            to={(isSharedRoute && studentToken) ? pathBuilder(
                SHARED_ROUTES.STUDENT_OVERALL_PROGRESS,
                ":studentToken",
                studentToken,
            ) : pathBuilder(
                ROUTES.STUDENT_OVERALL_PROGRESS,
                ":studentId",
                studentId,
            )}
            className={styles.overallProgressCardContainer}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                height={1}
                width={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    alignSelf="flex-start"
                    gap="12px"
                    height={1}
                >
                    <Text color="white" bold>
                        {t("overall-progress")}
                    </Text>
                    <CircularProgressBar
                        value={studentOverallProgress.totalAnswers}
                        size={60}
                        styles={{
                            backgroundColor: RGBAColors.whiteWithOpacity(0.12),
                            pathColor: colors.white,
                            trailColor: "transparent",
                        }}
                    >
                        <Text color="white">
                            {`${studentOverallProgress.totalAnswers}%`}
                        </Text>
                    </CircularProgressBar>
                    <Text color="white" size="small" className={styles.almostThereText}>
                        {t("almost-there")}
                    </Text>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    minWidth="105px"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                    >
                        <XIcon className={styles.iconWrapper} />
                        <Text color="white" size="small">
                            {`${studentOverallProgress.wrongAnswers}% ${t("wrong")}`}
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                    >
                        <CheckIcon className={styles.iconWrapper} />
                        <Text color="white" size="small">
                            {`${studentOverallProgress.correctAnswers}% ${t("right")}`}
                        </Text>
                    </Box>
                </Box>

                <ChevronRightIcon
                    color={colors.white}
                    className={styles.chevronRightIcon}
                />
            </Box>
        </Link>
    );
}
