import React, { useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { PlayIcon } from "assets/icons";
import { themePlaceholderImage } from "assets/images";

interface IProps {
    videoURL: string;
    posterURL: string;
}

export const LessonVideo = ({
    videoURL,
    posterURL,
}: IProps) => {
    const { t } = useTranslation();
    const [showPlayButton, setShowPlayButton] = useState(Boolean(videoURL));
    const videoRef = useRef<HTMLVideoElement>();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.videoContainer}
        >
            {showPlayButton && (
                <PlayIcon
                    className={styles.playIconButton}
                    onClick={() => {
                        videoRef.current?.play();
                        setShowPlayButton(false);
                    }}
                />
            )}
            {videoURL ? (
                <video
                    ref={videoRef}
                    poster={posterURL ?? themePlaceholderImage}
                    className={!showPlayButton ? styles.showVideoControls : styles.hideVideoControls}
                    controls={!showPlayButton}
                    controlsList="nodownload noplaybackrate"
                    disablePictureInPicture
                    disableRemotePlayback
                    onContextMenu={(event) => event.preventDefault()} // Disable right mouse click
                >
                    <source
                        src={videoURL}
                        type="video/mp4"
                    />
                    <Text>{t("no-video-support")}</Text>
                </video>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.noVideoPlaceholder}
                >
                    <Text>{t("video-not-available")}</Text>
                </Box>
            )}
        </Box>
    );
}
