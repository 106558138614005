import { useQuery } from "react-query";
import { drivingSchoolService } from "core/services/driving-school.service";
import { useDebounce } from "core/hooks/use-debounce.hook";
import { useState } from "react";

export const useDrivingSchools = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const {
        data,
    } = useQuery(
        ["driving_schools", debouncedSearchQuery],
        () => {
            return drivingSchoolService.getDrivingSchools({
                query: debouncedSearchQuery,
            });
        }, {
            onError: (error: Error) => {
                console.log("Error on getting driving schools - ", error);
            },
            enabled: debouncedSearchQuery !== "",
        }
    );

    return {
        drivingSchools: data,
        debouncedSearchQuery,
        setDrivingSchoolQuery: setSearchQuery,
    };
}
