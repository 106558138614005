import React, { FC, useMemo } from "react";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import styles from "./styles.module.scss";

export type TextSize = "small" | "normal" | "medium" | "large";
export type TextColor = "black" | "gray" | "white" | "red" | "green" | "violet" | "orange" | "blue";

export interface ITextProps {
    size?: TextSize;
    color?: TextColor;
    bold?: boolean;
    truncate?: boolean;
    tooltip?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

export const Text: FC<ITextProps> = ({
    size = "normal",
    color = "black",
    bold = false,
    truncate = false,
    tooltip,
    className: customClassName,
    children,
    ...props
}) => {
    const textSizeClassName: string = useMemo(() => getTextSizeClassName(size), [size]);
    const textColorClassName: string = useMemo(() => getTextColorClassName(color), [color]);

    return (
        <p
            className={classNameFormatter([
                styles.text,
                bold && styles.bold,
                truncate && styles.truncate,
                textSizeClassName,
                textColorClassName,
                customClassName,
            ])}
            title={tooltip}
            {...props}
        >
            {children}
        </p>
    );
}

function getTextSizeClassName(textSize: TextSize): string {
    switch (textSize) {
        case "small": return styles.smallText;
        case "normal": return styles.normalText;
        case "medium": return styles.mediumText;
        case "large": return styles.largeText;
        default: return "";
    }
}

function getTextColorClassName(textColor: TextColor): string {
    switch (textColor) {
        case "black": return styles.blackColor;
        case "gray": return styles.grayColor;
        case "white": return styles.whiteColor;
        case "red": return styles.redColor;
        case "green": return styles.greenColor;
        case "violet": return styles.violetColor;
        case "orange": return styles.orangeColor;
        case "blue": return styles.blueColor;
        default: return "";
    }
}
