import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import Loader from "core/components/Loader";
import PageContainer from "modules/home/components/PageContainer";
import { useUser } from "modules/home/hooks/use-user.hook";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useStudentAccountsCreatedTabs } from "core/components/StudentAccountsCreatedChart/hooks/use-chart-tabs.hook";
import { useStudentAccountsCreated } from "core/components/StudentAccountsCreatedChart/hooks/use-student-accounts-created.hook";
import { DrivingSchoolInfoCards } from "../components/DrivingSchoolInfoCards";
import { StudentAccountsCreatedChart } from "core/components/StudentAccountsCreatedChart";

export const DashboardPage = () => {
    const { t } = useTranslation();
    const {
        tabs,
        selectedTab,
        setSelectedTab,
    } = useStudentAccountsCreatedTabs();

    const { user, isLoading: isLoadingUser } = useUser();
    const {
        data: accountsCreatedData,
        isLoading: isLoadingChartData,
    } = useStudentAccountsCreated({
        instructorId: user?.id,
        type: selectedTab.type,
        start: selectedTab.start,
        end: selectedTab.end,
    });

    return (
        <PageContainer>
            <Box
                display="flex"
                flexDirection="column"
                gap="28px"
            >
                <Text size="large">
                    {t("welcome-back")}
                </Text>
                <DrivingSchoolInfoCards user={user} />
                <Box
                    mt="28px"
                    maxWidth="930px"
                >
                    <StudentAccountsCreatedChart
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        data={accountsCreatedData}
                    />
                </Box>
            </Box>
            {(isLoadingUser || isLoadingChartData) && <Loader />}
        </PageContainer>
    );
}
