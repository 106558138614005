import { useMutation, useQueryClient } from "react-query";
import { createAddStudentCommand, IAddStudentCredentialsCommand } from "core/models/student/student.command";
import { studentService } from "core/services/student.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { IAddStudentCredentials } from "../models/add-student.model";
import { useStudentsContext } from "./use-students-context.hook";

export const useAddStudent = () => {
    const queryClient = useQueryClient();
    const { activePage, setActivePage } = useStudentsContext();

    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (credentials: IAddStudentCredentials) => {
            const commandCredentials: IAddStudentCredentialsCommand = createAddStudentCommand(credentials);

            return studentService.addStudent(commandCredentials);
        }, {
            onError: (err: Error) => {
                console.log("Error on adding student - ", err);
            },
            onSuccess: () => {
                queryClient.removeQueries(["students"]);
            },
            onSettled: () => {
                activePage !== 1 && setActivePage(1);
            },
        },
    );

    return {
        addStudent: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
