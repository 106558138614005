import React from "react";
import { CardItem, ICardItemProps } from "../CardItem";
import styles from "./styles.module.scss";

export interface ICardListProps {
    items: Array<ICardItemProps>;
}

export const CardList = ({
    items,
}: ICardListProps) => (
    <div className={styles.cardListContainer}>
        {items.map((item: ICardItemProps, index: number) => (
            <CardItem
                key={item.path + index}
                title={item.title}
                imageURL={item.imageURL}
                description={item.description}
                path={item.path}
                size={item.size}
            />
        ))}
    </div>
);
