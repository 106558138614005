import { useQueries } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { studentService } from "core/services/student.service";
import { LearningActivityType } from "../enums/learning-activity-type.enum";
import { IStudent } from "core/models/student/student.model";
import { IStudentLearningActivity } from "core/models/student/student-learning-activity.model";
import { IStudentDifficultLesson } from "core/models/student/student-difficult-lesson.model";
import { sharedStudentService } from "core/pages/SharedStudentProfile/service/shared-student.service";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IUseStudentProfile {
    studentToken: string;
    studentId: string;
    learningActivityType: LearningActivityType;
    learningActivityPeriod: number;
}

export const useStudentProfile = ({
    studentToken,
    studentId,
    learningActivityType,
    learningActivityPeriod,
}: IUseStudentProfile) => {
    const { isSharedRoute } = useIsSharedRoute();

    const queries = useQueries([
        {
            queryKey: ["student", studentId ?? studentToken],
            queryFn: () => {
                return studentToken && isSharedRoute
                    ? sharedStudentService.getStudent(studentToken)
                    : studentService.getStudent(studentId);
            },
            onError: (error: any) => {
                console.log("Error on getting student - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        },
        {
            queryKey: ["student-difficult-lessons", studentId ?? studentToken],
            queryFn: () => {
                return studentToken && isSharedRoute
                    ? sharedStudentService.getDifficultLessons(studentToken, { limit: 3 })
                    : studentService.getStudentDifficultLessons(studentId, { limit: 3 });
            },
            onError: (error: any) => {
                console.log("Error on getting student difficult lessons - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        },
        {
            queryKey: ["student-learning-activity", studentId ?? studentToken, {
                type: learningActivityType,
                pastPeriod: learningActivityPeriod,
            }],
            queryFn: () => {
                return studentToken && isSharedRoute
                    ? sharedStudentService.getLearningActivityProgress(
                        studentToken,
                        learningActivityType,
                        learningActivityPeriod
                    ) : studentService.getStudentLearningActivityProgress(
                        studentId,
                        learningActivityType,
                        learningActivityPeriod
                    );
            },
            onError: (error: any) => {
                console.log("Error on getting student learning activity progress - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
            placeholderData: [], // Used to avoid loading state on learning activity chart, on type change
        },
    ]);

    return {
        isLoading: queries.some(({ isLoading }) => isLoading),
        student: (queries[0]?.data ?? null) as IStudent,
        difficultLessons: (queries[1]?.data ?? null) as Array<IStudentDifficultLesson>,
        learningActivity: (queries[2]?.data ?? null) as Array<IStudentLearningActivity>,
    };
}
