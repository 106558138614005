import { UserRole } from "core/enums/user-roles.enum";
import { IUserCredentials } from "core/interfaces/user-credentials.interface";
import { ISignUpCredentials } from "modules/login/models/sign-up.model";
import { IDrivingSchoolResponse } from "../driving-school/driving-school.response";

export interface IRegisterCredentials extends IUserCredentials {
    driving_school: Omit<IDrivingSchoolResponse, "id" | "partner" | "phone">;
    roles: Array<UserRole>;
}

export const createRegisterCredentialsCommand = (
    data: ISignUpCredentials,
    roles: Array<UserRole>,
): IRegisterCredentials => {
    return {
        driving_school: {
            name: data.driving_school_name,
            address: data.address,
            city: data.city,
            postal_code: data.zip_code as number,
        },
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
        roles: roles,
    };
}
