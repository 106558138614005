import React from "react";
import { Box, TextField, TextFieldProps } from "@material-ui/core";
import { StyledAutocomplete } from "./components/StyledAutocomplete";
import { PopperComponent } from "./components/PopperComponent";
import { PaperComponent } from "./components/PaperComponent";
import Text from "core/components/Text";

export interface IAutocompleteFormInput<T> {
    name: keyof T;
    value: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    elementRef: React.RefCallback<HTMLInputElement>;
    error: string | boolean;
    options: Array<string>;
    onInputChange: (
        event: React.SyntheticEvent<Element>,
        value: string,
        reason: string,
    ) => void;
}

export function AutocompleteFormInput<T>({
    label,
    placeholder,
    onChange,
    elementRef,
    error,
    options,
    onInputChange,
    ...restProps
}: IAutocompleteFormInput<T>) {
    const onOptionChange = (
        event: React.SyntheticEvent<Element>,
        value: any,
    ) => onChange(value ?? "");

    const renderInput = (params: TextFieldProps) => (
        <TextField
            {...params}
            fullWidth
            placeholder={placeholder}
            inputProps={{
                ...params.inputProps,
                autoComplete: "disabled",
            }}
            error={!!error}
            helperText={typeof error === "string" ? error : ""}
        />
    );

    return (
        <Box
            display="grid"
            flexDirection="column"
            width="1"
            gap="4px"
        >
            <Text size="small">{label}</Text>
            <StyledAutocomplete
                ref={elementRef}
                freeSolo
                options={options}
                onChange={onOptionChange}
                onInputChange={onInputChange}
                PopperComponent={PopperComponent}
                PaperComponent={PaperComponent}
                renderInput={renderInput}
                {...restProps}
            />
        </Box>
    );
}
