import { createDate, formatDateForBackend } from "core/utils/date-handler";
import { StudentStatus } from "modules/students/enums/student-status.enum"
import { IAddStudentCredentials } from "modules/students/models/add-student.model"
import { IEditStudentCredentials } from "modules/students/models/edit-student.model";
import { IStudentResponse } from "./student.response";

export type IAddStudentCredentialsCommand = Pick<
    IStudentResponse,
    "name" | "family_name" | "email" | "started_at" | "status" | "acquired_licenses" | 'aspired_licenses'
>;

export const createAddStudentCommand = (
    data: IAddStudentCredentials
): IAddStudentCredentialsCommand => {
    return {
        name: data.first_name,
        family_name: data.last_name,
        email: data.email,
        started_at: formatDateForBackend(Date()),
        status: StudentStatus.INVITATION_PENDING,
        aspired_licenses: (data.aspired_licenses ?? []).length > 0 ? data.aspired_licenses : null,
        acquired_licenses: (data.acquired_licenses ?? []).includes("Kein Führerschein vorhanden") ? [] : data.acquired_licenses
    };
}

export type IEditStudentCredentialsCommand = Pick<
    IStudentResponse,
    "name" | "family_name" | "gender" | "day_of_birth" | "acquired_licenses" | 'aspired_licenses'
>;

export const createEditStudentCommand = (
    data: IEditStudentCredentials
): any => {
    const returnData: any = {
        name: data.first_name,
        family_name: data.last_name,
        aspired_licenses: data.aspired_licenses.length > 0 ? data.aspired_licenses : ["B"],
        acquired_licenses: data.acquired_licenses
    };
    const dateOfBirth = createDate(
        data.day,
        data.month,
        data.year,
    );

    if (dateOfBirth) {
        returnData['day_of_birth'] = formatDateForBackend(dateOfBirth);
    }

    if (data.custom_gender) {
        returnData['gender'] = data.custom_gender;
    }

    return returnData;
}
