import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { lessonService } from "core/services/lesson.service";
import { handleErrorMessage } from "core/utils/handle-error-message";

export const useTotalLessonsNumber = () => {
    const {
        data: totalLessons,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["total-lessons"],
        () => lessonService.getTotalLessons(),
        {
            onError: (error: Error) => {
                console.log("Error on getting total lessons - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        }
    );

    return {
        totalLessons,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
