import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { ROUTES } from "modules/home/enums/routes.enum";
import { userService } from "core/services/user.service";

export const PrivateRoute: FC<RouteProps> = ({
    component: Component,
    children,
    ...rest
}) => {
    const { token } = userService.getToken();

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                const locationState: ILocationState = {
                    from: {
                        pathname: routeProps.location.pathname,
                    },
                };

                return token ? (
                    children ?? <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.SIGN_IN,
                            state: locationState,
                        }}
                    />
                );
            }}
        />
    );
};
