import React, { useMemo } from "react";
import { useTranslation } from "core/hooks/use-translation.hook";
import PageContainer from "modules/home/components/PageContainer";
import { PrivateRoute } from "core/components/Routes";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import { StudentsProvider } from "../context/StudentsProvider";
import { studentService } from "core/services/student.service";
import { EntityType } from "core/enums/entity-type.enum";
import { studentRoutes } from "../constants/student-routes";

export const StudentsPage = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const headerSearchProps = useMemo(() => {
        if (pathname !== ROUTES.STUDENTS) return;

        return {
            queryFunction: studentService.getStudents,
            queryKey: "studentSearch",
            placeholder: t("search-students-placeholder"),
            loadingIndicator: t("loading-entity", { entityName: t("students") }),
            type: EntityType.STUDENT,
        };
    }, [pathname]);

    return (
        <StudentsProvider>
            <PageContainer headerSearchProps={headerSearchProps}>
                <Switch>
                    {studentRoutes.map(({ path, exactPath, Component }) => (
                        <PrivateRoute
                            key={path}
                            exact={exactPath}
                            path={path}
                            component={Component}
                        />
                    ))}
                    <Redirect from="*" to={ROUTES.NOT_FOUND} />
                </Switch>
            </PageContainer>
        </StudentsProvider>
    );
}
