import { useContext } from "react";
import { DialogContext } from "core/context/DialogProvider";

export const useDialog = () => {
    const context = useContext(DialogContext);

    if (context === undefined) {
        throw new Error("DialogContext must be within DialogProvider");
    }

    return context;
};
