import { IUserProfileFormModel } from "modules/home/models/user-profile-form.model";

export type IEditInstructorFormModel = IUserProfileFormModel & { phone: string };

export const defaultEditInstructorFormValues: IEditInstructorFormModel = {
    driving_school_name: "",
    address: "",
    zip_code: "",
    city: "",
    phone: "",
    email: "",
}
