import { ISearch } from "../search/search.model";
import { createSearchModelFromResponse, ISearchResponse } from "../search/search.response";
import {
    IInstructorBillingInformationResponse,
    createInstructorBillingInformationFromResponse,
} from "./instructor-billing-information.response";
import {
    createInstructorDrivingSchoolFromResponse,
    IInstructorDrivingSchoolResponse,
} from "./instructor-driving-school.response";
import { createInstructor, IInstructor } from "./instructor.model";

export interface IInstructorResponse {
    id: number;
    email: string;
    status: string;
    started_trial_at: string;
    trial_ends_at: string;
    driving_school: IInstructorDrivingSchoolResponse;
    billing_information: IInstructorBillingInformationResponse;
    code: string;
    active: boolean;
}

export const createInstructorFromResponse = ({
    id,
    started_trial_at,
    trial_ends_at,
    driving_school,
    billing_information,
    ...props
}: IInstructorResponse): IInstructor => {
    return createInstructor({
        id: id?.toString(),
        startedTrialAt: started_trial_at,
        trialEndsAt: trial_ends_at,
        drivingSchool: driving_school ? createInstructorDrivingSchoolFromResponse(driving_school) : null,
        billingInformation: billing_information
            ? createInstructorBillingInformationFromResponse(billing_information)
            : null,
        ...props,
    });
};

export const createInstructorsFromResponse = ({
    items,
    ...props
}: ISearchResponse<IInstructorResponse>): ISearch<IInstructor> => {
    return createSearchModelFromResponse({
        items: items?.map(createInstructorFromResponse) ?? [],
        ...props,
    });
};
