import { useEffect } from "react";
import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { ISearchParams, SortDirection } from "core/interfaces/search-params.interface";
import { studentService } from "core/services/student.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { AppVersion } from "core/enums/app-version.enum";
import { setItemToLocalStorage } from "core/utils/local-storage-handler";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { StudentSortBy } from "../enums/student-sort-by.enum";
import { StudentStatus } from "../enums/student-status.enum";

type IUseStudents = ISearchParams & {
    queryKey: Array<any>;
    instructorId?: string;
    appVersion?: AppVersion;
    status?: StudentStatus;
    dashboardStatus?: StudentStatus;
};

export const useStudents = ({
    page = 1,
    limit = 10,
    direction = SortDirection.DESC,
    orderBy = StudentSortBy.ID,
    queryKey,
    instructorId,
    appVersion,
    status,
    dashboardStatus,
}: IUseStudents) => {
    useEffect(() => {
        setItemToLocalStorage(LocalStorageKeys.studentsQueryKey, queryKey);
    }, [queryKey]);

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
        isPreviousData,
    } = useQuery(
        queryKey,
        () => studentService.getStudents({
            page,
            limit,
            direction,
            orderBy,
            instructorId,
            appVersion,
            status,
            dashboardStatus,
        }),
        {
            onError: (error: Error) => {
                console.log("Error on getting students - ", error);
            },
            keepPreviousData: true,
            staleTime: TIME_DURATION.MINUTE,
        },
    );

    return {
        data,
        isLoading,
        isFetchingNextPage: isFetching && isPreviousData,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
