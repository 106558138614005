import { IUserCredentials } from "core/interfaces/user-credentials.interface";

export interface IAddStudentCredentials extends Pick<IUserCredentials, "email"> {
    first_name: string;
    last_name: string;
    start_date: string;
    gender: string;
    custom_gender: string;
    day: string;
    month: string;
    year: string;
    acquired_licenses: string[];
    aspired_licenses: string[];
}

export const defaultAddStudentValues: IAddStudentCredentials = {
    first_name: "",
    last_name: "",
    start_date: "",
    email: "",
    gender: "",
    custom_gender: "",
    day: "",
    month: "",
    year: "",
    acquired_licenses: null,
    aspired_licenses: null,
}
