import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import {
    supportedLanguages,
    defaultLanguage,
} from "core/constants/supported-language";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { getItemFromLocalStorage } from "./local-storage-handler";

const t = i18n.t.bind(i18n);
export { t };

export const getCurrentLanguage = () => i18n.language
    || getItemFromLocalStorage(LocalStorageKeys.appLang)
    || defaultLanguage;

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: getCurrentLanguage(),
        fallbackLng: defaultLanguage,
        whitelist: supportedLanguages,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
