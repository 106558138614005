interface IGroups<T> {
    key: keyof T;
    values: Array<T>;
}

export const groupBy = <T extends Record<string, any>>(
    array: Array<T>,
    key: keyof T,
): Array<IGroups<T>> => {
    const groups: Record<string, Array<T>> = [...array].reduce((
        result: Record<string, Array<T>>,
        currentItem: T,
    ) => {
        result[currentItem[key]] = [...result[currentItem[key]] || [], currentItem];

        return result;
    }, {});

    return Object.entries(groups).map(([key, values]) => ({ key, values }));
};
