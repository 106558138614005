import React from "react";
import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSignInValidationSchema } from "modules/login/hooks/use-form-validation-schema.hook";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { defaultSignInValues, ISignInCredentials } from "modules/login/models/sign-in.model";
import { ROUTES } from "modules/home/enums/routes.enum";
import { Layout } from "modules/login/components/Layout/Layout";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useLogin } from "modules/login/hooks/use-login.hook";
import FormWrapper from "core/components/FormWrapper";

export const SignInPage = () => {
    const { t } = useTranslation();
    const signInValidationSchema = useSignInValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<ISignInCredentials>({
        mode: "onChange",
        resolver: yupResolver(signInValidationSchema),
        defaultValues: defaultSignInValues,
    });

    const {
        login,
        isLoading,
        serverErrorMessage,
    } = useLogin();

    const onSubmit = async (data: ISignInCredentials) => {
        await login(data);
    };

    return (
        <Layout>
            <FormWrapper
                title={t("log-in")}
                backToPath={ROUTES.SIGN_UP}
                submitButtonProps={{
                    label: t("log-in"),
                    onClick: handleSubmit(onSubmit),
                    disabled: !isValid || isLoading,
                    isLoading: isLoading,
                }}
                error={serverErrorMessage}
                direction="column"
            > 
                <Box display="grid" flexDirection="column" gap={2}>
                    <Controller
                        name="email"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<ISignInCredentials>
                                inputRef={ref}
                                label={t("email-address")}
                                type={FormInputType.TEXT}
                                placeholder={t("email-address-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Box display="flex" flexDirection="column" gap="4px">
                        <Controller
                            name="password"
                            control={control}
                            render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                                <FormInput<ISignInCredentials>
                                    inputRef={ref}
                                    label={t("password")}
                                    type={FormInputType.PASSWORD}
                                    placeholder={t("login-password-placeholder")}
                                    error={error?.message || !!serverErrorMessage}
                                    {...restFieldProps}
                                />
                            )}
                        />
                        <Text size="small" className={styles.forgotPasswordText}>
                            <Link to={ROUTES.RESET_PASSWORD}>{t("forgot-password")}</Link>
                        </Text>
                    </Box>
                </Box>
            </FormWrapper>
        </Layout>
    );
}
