import React, { FC, ReactNode, useEffect, useState } from "react";
import {
    Box,
    Dialog as MUIDialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { DialogType } from "core/enums/dialog-type.enum";

export interface IDialogProps {
    open: boolean;
    title: string;
    content: ReactNode;
    actions?: ReactNode;
    header?: ReactNode;
    type?: DialogType;
    onClose?: () => void;
}

export const Dialog: FC<IDialogProps> = ({
    open,
    title,
    content,
    actions,
    header,
    type,
    onClose,
}) => {
    const [openDialog, setOpenDialog] = useState(open ?? false);

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const handleClose = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string,
    ) => {
        if (reason === "backdropClick"
            && type === DialogType.CONFIRMATION
        ) return;

        onClose && onClose();
        setOpenDialog(false);
    };

    return (
        <MUIDialog
            open={openDialog}
            onClose={handleClose}
            disableEscapeKeyDown={type === DialogType.CONFIRMATION}
            classes={{
                root: styles.root,
                paper: styles.paper,
            }}
            BackdropProps={{
                classes: { root: styles.backdrop },
            }}
        >
            {header && <>{header}</>}

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Text
                    size="large"
                    className={styles.title}
                >
                    {title}
                </Text>
            </Box>

            <DialogContent
                classes={{ root: styles.content }}
            >
                {content}
            </DialogContent>

            {actions && (
                <DialogActions
                    classes={{ root: styles.actions }}
                >
                    {actions}
                </DialogActions>
            )}
        </MUIDialog>
    );
}
