import React, { useMemo } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import { setMonth, format } from "date-fns";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { CustomizedXAxisTick } from "./CustomizedXAxisTick";
import { abbreviateNumber } from "core/utils/abbreviate-number";
import { IStudentAccountsCreated } from "core/models/instructor/student-accounts-created.model";
import { DateFormat } from "core/enums/date-format.enum";
import { DateGroupTypeEnum } from "../enums/date-group-type.enum";

interface IProps {
    data: Array<IStudentAccountsCreated>;
    type: DateGroupTypeEnum;
}

export const Chart = ({
    data,
    type,
}: IProps) => {
    const formattedData = useMemo(() => {
        if (!data?.length) return [
            {
                accounts: 0,
                date: "",
            },
        ];

        return data.map((item) => ({
            ...item,
            date: type === DateGroupTypeEnum.WEEK
                ? format(new Date(item.startOfWeek), DateFormat.mmmDD)
                : format(setMonth(new Date(`${item.year}`), +item.month - 1), DateFormat.mmm),
        }));
    }, [data]);

    return (
        <ResponsiveContainer width={"100%"} height={320}>
            <BarChart
                data={formattedData}
                margin={{
                    top: 10,
                    left: 5,
                    bottom: 25,
                }}
            >
                <CartesianGrid
                    strokeDasharray="3 6"
                    vertical={false}
                />
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    tick={<CustomizedXAxisTick />}
                    domain={["", ""]}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={abbreviateNumber}
                    tickMargin={10}
                    tick={{
                        fill: RGBAColors.lightBlackWithOpacity(0.38),
                        fontSize: 12,
                    }}
                    width={40}
                    allowDecimals={false}
                />
                <Bar
                    dataKey="accounts"
                    fill={colors.violet}
                    barSize={10}
                    radius={2}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}
