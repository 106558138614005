export interface IUserProgress {
    total: number;
    correct: number;
    incorrect: number;
    successRate: number;
}

export const createUserProgress = ({ ...props }: Partial<IUserProgress>) => {
    return {
        total: null,
        correct: null,
        incorrect: null,
        successRate: null,
        ...props,
    } as IUserProgress;
};
