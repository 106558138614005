export interface ISearch<T = any> {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    limit: number;
    offset: number;
    orderBy: string;
    direction: string;
    items: Array<T>;
}

export const createSearchModel = <T>(
    { ...props }: Partial<ISearch<T>>
) => {
    return {
        totalResults: null,
        totalPages: null,
        currentPage: null,
        limit: null,
        offset: null,
        orderBy: "",
        direction: "",
        items: [],
        ...props,
    } as ISearch<T>;
};
