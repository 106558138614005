import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import { useCreateNewPasswordValidationSchema } from "modules/login/hooks/use-form-validation-schema.hook";
import { ICreateNewPasswordCredentials, defaultCreateNewPasswordValues } from "modules/login/models/create-new-password.model";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useCreatePassword } from "modules/login/hooks/use-create-password.hook";
import FormWrapper from "core/components/FormWrapper";

interface IProps {
    code: string;
}

export const CreateNewPassword = ({ code }: IProps) => {
    const { t } = useTranslation();
    const createNewPasswordValidationSchema = useCreateNewPasswordValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<ICreateNewPasswordCredentials>({
        mode: "onChange",
        resolver: yupResolver(createNewPasswordValidationSchema),
        defaultValues: defaultCreateNewPasswordValues,
    });
    const { push } = useHistory();

    const {
        resetPassword,
        isLoading,
        serverErrorMessage,
    } = useCreatePassword();

    const onSubmit = async (data: ICreateNewPasswordCredentials) => {
        if (await resetPassword({
            code,
            credentials: data,
        })) {
            push(ROUTES.SIGN_IN);
        }
    };

    return (
        <FormWrapper
            title={t("create-new-password")}
            backToPath={ROUTES.RESET_PASSWORD}
            submitButtonProps={{
                label: t("submit"),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid || isLoading,
                isLoading: isLoading,
            }}
            error={serverErrorMessage}
            direction="column"
        >
            <Box display="flex" flexDirection="column" gap={2}>
                <Controller
                    name="email"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<ICreateNewPasswordCredentials>
                            inputRef={ref}
                            label={t("email")}
                            type={FormInputType.TEXT}
                            placeholder={t("reset-password-email-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<ICreateNewPasswordCredentials>
                            inputRef={ref}
                            label={t("new-password")}
                            type={FormInputType.PASSWORD}
                            placeholder={t("password-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="confirm_password"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<ICreateNewPasswordCredentials>
                            inputRef={ref}
                            label={t("repeat-password")}
                            type={FormInputType.PASSWORD}
                            placeholder={t("repeat-password-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
            </Box>
        </FormWrapper>
    );
}
