import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import { StatusIndicator } from "core/components/StatusIndicator";

interface IProps {
    message: string;
    isReadByUser: boolean;
}

export const Notification = ({
    message,
    isReadByUser,
}: IProps) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            className={styles.notificationContainer}
        >
            <Text style={{
                color: !isReadByUser
                    ? colors.lightBlack
                    : RGBAColors.lightBlackWithOpacity(0.6),
            }}>
                {message}
            </Text>
            {!isReadByUser && <StatusIndicator status="active" />}
        </Box>
    );
}
