import React, { useMemo } from "react";
import { useLocation, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "core/components/Routes";
import { useTranslation } from "core/hooks/use-translation.hook";
import { lessonService } from "core/services/lesson.service";
import PageContainer from "modules/home/components/PageContainer";
import { EntityType } from "core/enums/entity-type.enum";
import { ROUTES } from "modules/home/enums/routes.enum";
import LessonDetails from "./LessonDetails";
import LessonsList from "./LessonsList";
import ThemesList from "./ThemesList";

export const ThemesPage = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const headerSearchProps = useMemo(() => {
        // Hides header search for THEME_LESSON_DETAILS route
        if (pathname?.split("/").length > 3) return;

        return {
            queryFunction: lessonService.getLessonsByTag,
            queryKey: "lessonSearch",
            placeholder: t("search-lessons-placeholder"),
            loadingIndicator: t("loading-entity", { entityName: t("lessons") }),
            type: EntityType.LESSON,
        };
    }, [pathname]);

    return (
        <PageContainer headerSearchProps={headerSearchProps}>
            <Switch>
                <PrivateRoute
                    exact
                    path={ROUTES.THEME_LESSON_DETAILS}
                    component={LessonDetails}
                />
                <PrivateRoute
                    exact
                    path={ROUTES.THEME_LESSONS}
                    component={LessonsList}
                />
                <PrivateRoute
                    exact
                    path={ROUTES.THEMES}
                    component={ThemesList}
                />
                <Redirect from="*" to={ROUTES.NOT_FOUND} />
            </Switch>
        </PageContainer>
    );
}
