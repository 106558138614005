import { useEffect } from "react";

interface IUseIntersectionObserver {
    target: React.RefObject<HTMLDivElement>;
    enabled: boolean;
    onIntersect: () => void;
    root?: React.RefObject<HTMLElement>;
    rootMargin?: string;
    threshold?: number;
}

/**
 * Hook taken from: https://react-query.tanstack.com/examples/load-more-infinite-scroll
 */
export const useIntersectionObserver = ({
    target,
    enabled,
    onIntersect,
    root = null,
    rootMargin = "0px",
    threshold = 1.0,
}: IUseIntersectionObserver) => {
    useEffect(() => {
        if (!enabled) return;

        const observer = new IntersectionObserver(
            (entries) => entries.forEach((entry) => {
                entry.isIntersecting && onIntersect();
            }),
            {
                root: root && root.current,
                rootMargin,
                threshold,
            },
        );

        const targetElement = target && target.current;

        if (!targetElement) return;

        observer.observe(targetElement);

        return () => {
            observer.unobserve(targetElement);
        }
    }, [target.current, enabled]);
}
