import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { lessonService } from "core/services/lesson.service";
import { handleErrorMessage } from "core/utils/handle-error-message";

interface IUseLesson {
    lessonId: string;
}

export const useLesson = ({ lessonId }: IUseLesson) => {
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["lesson", lessonId],
        () => lessonService.getLesson(lessonId),
        {
            onError: (error: Error) => {
                console.log("Error on getting lesson - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
