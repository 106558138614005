import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { Table } from "core/components/Table";
import { useTabs } from "modules/sw-admin/hooks/use-tabs.hook";
import { useInstructors } from "modules/sw-admin/driving-schools/hooks/use-instructors.hook";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { InstructorAccountType } from "modules/sw-admin/driving-schools/enums/instructor-account-type.enum";
import { ITableHeadCell } from "core/components/Table/TableHead";
import { UnverifiedTableActions } from "./UnverifiedTableActions";

const unverifiedTableHead: Array<ITableHeadCell> = [
    { translateKey: "driving-school" },
    { translateKey: "email-address" },
    { translateKey: "phone-number" },
    { translateKey: "zip-code" },
    { translateKey: "city" },
];

interface IUnverifiedTableBody {
    id: string;
    drivingSchool: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
    city: string;
    actions: React.ReactNode;
}

export const UnverifiedTable = () => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const {
        tabActivePage,
        setTabActivePage,
    } = useTabs();

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useInstructors({
        type: InstructorAccountType.UNVERIFIED,
        searchParams: { page: tabActivePage }
    });

    const unverifiedTableBody: Array<IUnverifiedTableBody> = useMemo(() => {
        if (!data?.items?.length) return [];

        return data.items.map(({ drivingSchool, ...instructor }) => ({
            id: instructor.id,
            drivingSchool: drivingSchool?.name,
            emailAddress: instructor.email,
            phoneNumber: drivingSchool?.phone,
            zipCode: drivingSchool?.postalCode,
            city: drivingSchool?.city,
            actions: <UnverifiedTableActions instructorId={instructor.id} />,
        }));
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : data?.items?.length > 0 ? (
                <Table<IUnverifiedTableBody>
                    headProps={{
                        data: unverifiedTableHead,
                    }}
                    bodyProps={{
                        data: unverifiedTableBody,
                        onRowClick: (id: string) => {
                            push(
                                pathBuilder(
                                    ROUTES.DRIVING_SCHOOL,
                                    ":drivingSchoolId",
                                    id,
                                )
                            );
                        },
                    }}
                    paginationProps={{
                        page: tabActivePage,
                        lastActivePage: data.currentPage,
                        pageSize: data.items.length,
                        totalPages: data.totalPages,
                        totalResults: data.totalResults,
                        isFetchingNextPage,
                        setPage: setTabActivePage,
                    }}
                    tableLayout="auto"
                />
            ) : (
                <Box mt="32px">
                    <Text>{t("no-data-available")}</Text>
                </Box>
            )}
        </>
    );
}
