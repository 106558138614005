import {
    subYears,
    subMonths,
    startOfYear,
    endOfYear,
    startOfMonth,
    endOfMonth,
} from "date-fns";
import { formatDateForBackend } from "core/utils/date-handler";

export function getStartAndEndDate(
    dateType: "currentYear" | "previousYear" | "currentMonth" | "previousMonth"
) {
    const todayDate: Date = new Date();
    const previousYearDate: Date = subYears(todayDate, 1);
    const previousMonthDate: Date = subMonths(todayDate, 1);

    let start: Date | string = "";
    let end: Date | string = "";

    switch (dateType) {
        case "currentYear":
            start = startOfYear(todayDate);
            end = endOfYear(todayDate);
            break;
        case "previousYear":
            start = startOfYear(previousYearDate);
            end = endOfYear(previousYearDate);
            break;
        case "currentMonth":
            start = startOfMonth(todayDate);
            end = endOfMonth(todayDate);
            break;
        case "previousMonth":
            start = startOfMonth(previousMonthDate);
            end = endOfMonth(previousMonthDate);
            break;
    }

    return {
        start: formatDateForBackend(start),
        end: formatDateForBackend(end),
    };
}
