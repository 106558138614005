import React, { FC, useEffect, useState } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { AnimateContent } from "../AnimateContent";

export interface ICircularProgressBarStyles {
    rotation?: number;
    strokeLinecap?: any;
    textColor?: string;
    textSize?: string | number;
    pathColor?: string;
    pathTransition?: string;
    pathTransitionDuration?: number;
    trailColor?: string;
    backgroundColor?: string;
}

export interface ICircularProgressBarProps {
    value: number;
    size: number;
    duration?: TIME_DURATION | number;
    styles?: ICircularProgressBarStyles;
}

export const CircularProgressBar: FC<ICircularProgressBarProps> = ({
    value,
    size,
    duration = TIME_DURATION.SECOND,
    styles,
    children,
}) => {
    const [progressValue, setProgressValue] = useState(0);

    useEffect(() => {
        setProgressValue(value);
    }, [value]);

    return (
        <div style={{ width: `${size}px`, height: `${size}px` }}>
            <AnimateContent
                valueStart={0}
                valueEnd={progressValue}
                duration={duration}
            >
                {(value: number) => (
                    <CircularProgressbarWithChildren
                        value={value}
                        background={!!styles?.backgroundColor}
                        styles={buildStyles({
                            pathTransition: "none",
                            ...styles,
                        })}
                    >
                        {children}
                    </CircularProgressbarWithChildren>
                )}
            </AnimateContent>
        </div>
    );
}
