import React from "react";
import { Box } from "@material-ui/core";
import { Toolbar } from "modules/home/components/Toolbar";
import { ROUTES } from "modules/home/enums/routes.enum";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { HeaderContainer } from "modules/sw-admin/components/HeaderContainer";
import { StudentAccountsCreatedCard } from "./components/StudentAccountsCreatedCard";
import { ContactInformationsCard } from "./components/ContactInformationsCard";
import { BillingInformationsCard } from "./components/BillingInformationsCard";
import { DrivingStudentsInformations } from "./components/DrivingStudentsInformations";
import { useInstructorProfile } from "../../hooks/use-instructor-profile.hook";
import { useTranslation } from "core/hooks/use-translation.hook";
import { Link, useParams } from "react-router-dom";
import Text from "core/components/Text";
import Loader from "core/components/Loader";
import { pathBuilder } from "core/utils/path-builder";
import Button from "core/components/Button";
import { StudentAccountsCreatedChart } from "core/components/StudentAccountsCreatedChart";
import { useStudentAccountsCreatedTabs } from "core/components/StudentAccountsCreatedChart/hooks/use-chart-tabs.hook";

export const DrivingSchoolProfile = () => {
    const { t } = useTranslation();
    const { drivingSchoolId } = useParams<{ drivingSchoolId: string }>();
    const {
        tabs,
        selectedTab,
        setSelectedTab,
    } = useStudentAccountsCreatedTabs();

    const {
        isLoading,
        instructor,
        studentAccountsCreated,
    } = useInstructorProfile({
        instructorId: drivingSchoolId,
        studentAccountsCreatedParams: {
            type: selectedTab.type,
            start: selectedTab.start,
            end: selectedTab.end,
        },
    });

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : instructor ? (
                <>
                    <HeaderContainer>
                        <Toolbar
                            title={instructor.drivingSchool?.name}
                            backToPath={ROUTES.DRIVING_SCHOOLS}
                            actions={
                                <Link to={pathBuilder(
                                    ROUTES.EDIT_DRIVING_SCHOOL,
                                    ":drivingSchoolId",
                                    drivingSchoolId,
                                )}>
                                    <Button
                                        label={t("edit-entity", { entityName: t("driving-school") })}
                                        style={{ width: "fit-content" }}
                                    />
                                </Link>
                            }
                        />
                    </HeaderContainer>
                    <ContentContainer>
                        <Box
                            display="grid"
                            flexDirection="column"
                            justifyContent="space-between"
                            flex={1}
                            gap="65px"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                // gap={4}
                                width={1}
                                maxWidth="930px"
                            >
                                <StudentAccountsCreatedCard
                                    activeStudents={instructor.drivingSchool?.activeStudents}
                                />
                                <ContactInformationsCard
                                    email={instructor.email}
                                    phone={instructor.drivingSchool?.phone}
                                />
                                <BillingInformationsCard
                                    code={instructor.code}
                                    {...instructor.billingInformation}
                                />
                            </Box>
                            <Box maxWidth="930px">
                                <StudentAccountsCreatedChart
                                    tabs={tabs}
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    data={studentAccountsCreated}
                                />
                            </Box>
                            <DrivingStudentsInformations drivingSchoolId={drivingSchoolId} />
                        </Box>
                    </ContentContainer>
                </>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </>
    );
}
