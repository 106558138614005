import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { simulationService } from "core/services/simulation.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { sharedStudentService } from "core/pages/SharedStudentProfile/service/shared-student.service";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";

interface IUseSimulation {
    simulationId: string;
    studentToken: string;
}

export const useSimulation = ({ simulationId, studentToken }: IUseSimulation) => {
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["simulation", simulationId],
        () => {
            return studentToken && isSharedRoute
                ? sharedStudentService.getSimulation(simulationId, studentToken)
                : simulationService.getSimulation(simulationId);
        },
        {
            onError: (err: Error) => {
                console.log("Error on getting simulation - ", err);
            },
            staleTime: TIME_DURATION.MINUTE,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
