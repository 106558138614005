import { useMemo } from "react";
import { useUser } from "./use-user.hook";
import { getUserRole } from "core/models/user/user.model";
import { UserRole } from "core/enums/user-roles.enum";

export const useUserRole = () => {
    const { user } = useUser();
    const userRole: UserRole = useMemo(() => getUserRole(user), [user]);

    return { userRole };
}
