import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import { RGBAColors } from "styles/variables/rgba-colors";
import { XIcon } from "assets/icons";
import { getLessonName } from "core/models/lesson/lesson.model";
import { IStudentDifficultLesson } from "core/models/student/student-difficult-lesson.model";
import { roundNumber } from "core/utils/round-number";

interface IProps {
    difficultLessons: Array<IStudentDifficultLesson>;
}

export const DifficultLessonsCard = ({ difficultLessons }: IProps) => {
    const { t } = useTranslation();

    const formattedData = useMemo(
        () => {
            if (!difficultLessons) return;

            return difficultLessons.map((item) => ({
                ...item,
                incorrectQuestionsRate: roundNumber(item.incorrectQuestionsRate * 100),
            })).sort((a, b) => b.incorrectQuestionsRate - a.incorrectQuestionsRate);
        },
        [difficultLessons]
    );

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className={styles.difficultLessonsCardContainer}
        >
            <Text bold>
                {t("difficult-lessons")}
            </Text>

            {formattedData ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="9px"
                >
                    {!formattedData.length ? (
                        <Text>
                            {t("no-difficult-lessons")}
                        </Text>
                    ) : formattedData.map(({ incorrectQuestionsRate, lesson }) => (
                        <Box
                            key={lesson.id}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={1}
                            className={styles.lessonItem}
                        >
                            <Text truncate>
                                {getLessonName(lesson.translations).title}
                            </Text>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                minWidth="105px"
                            >
                                <XIcon />
                                <Text
                                    size="small"
                                    style={{ color: RGBAColors.lightBlackWithOpacity(0.6) }}
                                >
                                    {`${incorrectQuestionsRate}% ${t("wrong")}`}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </Box>
    );
}
