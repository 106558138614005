import { AvailableLanguageKeys } from "core/enums/available-language-keys.enum";
import { DeFlagIcon, EnFlagIcon } from "assets/icons";

export const appLanguages = [
    {
        languageKey: AvailableLanguageKeys.DE,
        title: "German",
        Icon: DeFlagIcon,
    },
    {
        languageKey: AvailableLanguageKeys.EN,
        title: "English",
        Icon: EnFlagIcon,
    },
];

export const supportedLanguages: string[] = [
    AvailableLanguageKeys.EN,
    AvailableLanguageKeys.DE,
];

export const defaultLanguage = AvailableLanguageKeys.DE;
