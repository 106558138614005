import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { Table } from "core/components/Table";
import { useTabs } from "modules/sw-admin/hooks/use-tabs.hook";
import { useInstructors } from "modules/sw-admin/driving-schools/hooks/use-instructors.hook";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { InstructorAccountType } from "modules/sw-admin/driving-schools/enums/instructor-account-type.enum";
import { ITableHeadCell } from "core/components/Table/TableHead";
import { DateFormat } from "core/enums/date-format.enum";
import { IUseConfirmationDialog } from "core/hooks/use-confirmation-dialog.hook";
import { SwitchAccountStatus } from "./SwitchAccountStatus";
import { UserStatus } from "core/enums/user-status.enum";

const trialPeriodTableHead: Array<ITableHeadCell> = [
    { translateKey: "driving-school" },
    { translateKey: "email-address" },
    { translateKey: "phone-number" },
    { translateKey: "start-date" },
    { translateKey: "end-date" },
    { translateKey: "number-of-students" },
    { translateKey: "state" },
    { translateKey: "premium-account" },
];

interface ITrialPeriodTableBody {
    id: string;
    drivingSchool: string;
    emailAddress: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
    numberOfStudents: number;
    state: string;
    premiumAccount: React.ReactNode;
}

export const TrialPeriodTable = () => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const {
        tabActivePage,
        setTabActivePage,
    } = useTabs();

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useInstructors({
        type: InstructorAccountType.TRIAL_PERIOD,
        searchParams: { page: tabActivePage }
    });

    const confirmationDialogProps: IUseConfirmationDialog = useMemo(() => ({
        type: "success",
        title: t("activate-account", { accountType: t("premium-account") }),
        description: t("activate-account-description", { accountType: t("premium-account").toLowerCase() }),
        actionButtonTitle: t("activate-account", { accountType: t("premium-account") }),
    }), []);

    const trialPeriodTableBody: Array<ITrialPeriodTableBody> = useMemo(() => {
        if (!data?.items?.length) return [];

        return data.items.map(({ drivingSchool, ...instructor }) => ({
            id: instructor.id,
            drivingSchool: drivingSchool?.name,
            emailAddress: instructor.email,
            phoneNumber: drivingSchool?.phone,
            startDate: instructor.startedTrialAt ? format(
                new Date(instructor.startedTrialAt),
                DateFormat.ddMMyyyy
            ) : "-",
            endDate: instructor.trialEndsAt ? format(
                new Date(instructor.trialEndsAt),
                DateFormat.ddMMyyyy
            ) : "-",
            numberOfStudents: drivingSchool?.totalStudents,
            state: drivingSchool?.state,
            premiumAccount: <SwitchAccountStatus
                instructorId={instructor.id}
                status={UserStatus.PREMIUM}
                checked={instructor.status === UserStatus.PREMIUM}
                confirmationDialogProps={confirmationDialogProps}
            />,
        }));
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : data?.items?.length > 0 ? (
                <Table<ITrialPeriodTableBody>
                    headProps={{
                        data: trialPeriodTableHead,
                    }}
                    bodyProps={{
                        data: trialPeriodTableBody,
                        onRowClick: (id: string) => {
                            push(
                                pathBuilder(
                                    ROUTES.DRIVING_SCHOOL,
                                    ":drivingSchoolId",
                                    id,
                                )
                            );
                        },
                    }}
                    paginationProps={{
                        page: tabActivePage,
                        lastActivePage: data.currentPage,
                        pageSize: data.items.length,
                        totalPages: data.totalPages,
                        totalResults: data.totalResults,
                        isFetchingNextPage,
                        setPage: setTabActivePage,
                    }}
                />
            ) : (
                <Box mt="32px">
                    <Text>{t("no-data-available")}</Text>
                </Box>
            )}
        </>
    );
}
