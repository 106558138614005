import { combineComponents } from "core/utils/combine-context-providers";
import { DialogProvider } from "./DialogProvider";
import { MultiLanguageSupportProvider } from "./MultiLanguageSupportProvider";
import { ReactQueryClientProvider } from "./ReactQueryClientProvider";
import { SnackbarProvider } from "./SnackbarProvider";

const providers = [
    ReactQueryClientProvider,
    MultiLanguageSupportProvider,
    SnackbarProvider,
    DialogProvider,
];

export const AppContextProvider = combineComponents(...providers);
