import React from "react";
import { Box, Pagination as MUIPagination, PaginationItem } from "@material-ui/core";
import styles from "./styles.module.scss";
import { useTranslation } from "core/hooks/use-translation.hook";
import Button from "core/components/Button";
import { ButtonType } from "core/enums/button-type.enum";
import { RGBAColors } from "styles/variables/rgba-colors";

export interface IPaginationProps {
    page: number;
    setPage: (page: ((previousPage: number) => number) | number) => void;
    totalPages: number;
    isFetchingNextPage: boolean;
}

export const Pagination = ({
    page,
    setPage,
    totalPages,
    isFetchingNextPage,
}: IPaginationProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            alignItems="center"
            gap="12px"
        >
            <Button
                label={t("prev")}
                onClick={() => {
                    setPage((prev: number) => prev - 1);
                }}
                disabled={page === 1 || isFetchingNextPage}
                type={ButtonType.TEXT}
                style={{ color: RGBAColors.lightBlackWithOpacity(0.6) }}
            />
            <MUIPagination
                page={page}
                count={totalPages}
                onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value)}
                renderItem={(item) => (
                    <PaginationItem
                        classes={{
                            selected: styles.paginationItemSelected,
                        }}
                        {...item}
                    />
                )}
                classes={{
                    ul: styles.paginationUl,
                }}
                hidePrevButton
                hideNextButton
                disabled={totalPages === 1 || isFetchingNextPage}
            />
            <Button
                label={t("next")}
                onClick={() => {
                    setPage((prev: number) => prev + 1);
                }}
                disabled={page >= totalPages || isFetchingNextPage}
                type={ButtonType.TEXT}
                style={{ color: RGBAColors.lightBlackWithOpacity(0.6) }}
            />
        </Box>
    );
}
