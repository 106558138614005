import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import styles from "./styles.module.scss";

interface IProps {
    clickable?: boolean;
    onClick?: () => void;
}

export const Adornment: FC<IProps> = ({
    clickable,
    onClick,
    children,
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classNameFormatter([
                styles.adornmentIconContainer,
                clickable ? styles.pointerCursor : styles.defaultCursor,
            ])}
            onClick={() => {
                if (!clickable) return;

                onClick && onClick();
            }}
        >
            {children}
        </Box>
    );
}
