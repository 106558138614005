import { IEditInstructorFormModel } from "modules/sw-admin/driving-schools/models/edit-instructor-form.model";
import { IDrivingSchoolResponse } from "../driving-school/driving-school.response";

export interface IEditInstructorCommand {
    driving_school: Omit<IDrivingSchoolResponse, "id" | "partner">;
}

export const createEditInstructorCommand = (
    data: IEditInstructorFormModel,
): IEditInstructorCommand => {
    return {
        driving_school: {
            name: data.driving_school_name,
            address: data.address,
            city: data.city,
            postal_code: data.zip_code as number,
            phone: data.phone,
        },
    };
}
