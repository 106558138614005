import React, { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Toolbar } from "modules/home/components/Toolbar";
import { ROUTES } from "modules/home/enums/routes.enum";
import { ContentContainer } from "modules/sw-admin/components/ContentContainer";
import { HeaderContainer } from "modules/sw-admin/components/HeaderContainer";
import { useTranslation } from "core/hooks/use-translation.hook";
import { pathBuilder } from "core/utils/path-builder";
import { ITableHeadCell } from "core/components/Table/TableHead";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { Table } from "core/components/Table";
import { calculateStudentProgress } from "modules/students/utils/calculate-student-progress";
import { getStudentFullName } from "core/models/student/student.model";
import { formatStudentActivity } from "modules/students/utils/format-student-activity";
import { StudentProgressBar } from "modules/students/components/StudentProgressBar";
import { useStudents } from "modules/students/hooks/use-students.hook";
import { useTotalLessonsNumber } from "modules/themes/hooks/use-total-lessons-number.hook";
import styles from "./styles.module.scss";

const drivingStudentsTableHead: Array<ITableHeadCell> = [
    { translateKey: "student" },
    { translateKey: "email-address" },
    { translateKey: "birth-date" },
    { translateKey: "gender" },
    { translateKey: "start-date" },
    { translateKey: "last-activity-date" },
    { translateKey: "videos-watched" },
    { translateKey: "total-progress" },
];

interface IDrivingStudentsTableBody {
    id: string;
    student: string;
    emailAddress: string;
    birthDate: string;
    gender: string;
    startDate: string;
    activity: string;
    videosWatched: string;
    totalProgress: React.ReactNode;
}

export const DrivingStudentsInformations = () => {
    const { t } = useTranslation();
    const { drivingSchoolId } = useParams<{ drivingSchoolId: string }>();
    const [activePage, setActivePage] = useState(1);
    const { push } = useHistory();
    const { pathname } = useLocation();

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useStudents({
        instructorId: drivingSchoolId,
        page: activePage,
        queryKey: ["driving-students", drivingSchoolId, { page: activePage }],
    });
    const { totalLessons } = useTotalLessonsNumber();

    const drivingStudentsTableBody: Array<IDrivingStudentsTableBody> = useMemo(() => {
        if (!data?.items?.length) return [];

        return data.items.map((student) => {
            const { overallTotal, overallCorrect, overallIncorrect } = student.userProgress ?? {};
            const calculatedStudentProgress = calculateStudentProgress({
                total: overallTotal,
                correct: overallCorrect,
                incorrect: overallIncorrect,
            });

            return {
                id: student.id,
                student: getStudentFullName(student),
                emailAddress: student.email,
                birthDate: student.dayOfBirth,
                gender: student.gender,
                startDate: student.startedAt,
                activity: formatStudentActivity(student.lastActive),
                videosWatched: totalLessons
                    ? [student.totalWatchedLessons, totalLessons].join("/")
                    : student.totalWatchedLessons?.toString(),
                totalProgress: <StudentProgressBar
                    correctAnswers={calculatedStudentProgress.correctAnswers}
                    wrongAnswers={calculatedStudentProgress.wrongAnswers}
                    showProgressCount
                    className={styles.studentProgressBar}
                />,
            };
        }
    )}, [data, totalLessons]);

    return (
        <>
            <HeaderContainer>
                <Toolbar
                    title={t("driving-students-informations")}
                    backToPath={pathBuilder(
                        ROUTES.DRIVING_SCHOOL,
                        ":drivingSchoolId",
                        drivingSchoolId
                    ) as ROUTES}
                />
            </HeaderContainer>
            <ContentContainer>
                {isLoading ? (
                    <Loader />
                ) : drivingStudentsTableBody.length > 0 ? (
                    <Table<IDrivingStudentsTableBody>
                        headProps={{
                            data: drivingStudentsTableHead,
                        }}
                        bodyProps={{
                            data: drivingStudentsTableBody,
                            onRowClick: (id: string) => {
                                push(
                                    pathBuilder(
                                        ROUTES.STUDENT_PROFILE,
                                        ":studentId",
                                        id,
                                    ),
                                    {from: {pathname}},
                                );
                            },
                        }}
                        paginationProps={{
                            page: activePage,
                            lastActivePage: data.currentPage,
                            pageSize: data.items.length,
                            totalPages: data.totalPages,
                            totalResults: data.totalResults,
                            isFetchingNextPage,
                            setPage: setActivePage,
                        }}
                    />
                ) : (
                    <Box mt="32px">
                        <Text>{t("no-data-available")}</Text>
                    </Box>
                )}
            </ContentContainer>
        </>
    );
}
