import { IInstructorBillingInformation } from "./instructor-billing-information.model";
import { IInstructorDrivingSchool } from "./instructor-driving-school.model";

export interface IInstructor {
    id: string;
    email: string;
    status: string;
    startedTrialAt: string;
    trialEndsAt: string;
    drivingSchool: IInstructorDrivingSchool;
    billingInformation: IInstructorBillingInformation;
    code: string;
    active: boolean;
}

export const createInstructor = ({ id, ...props }: Partial<IInstructor>) => {
    if (!id) {
        throw new Error('Unable to create instructor without an ID!');
    }

    return {
        id,
        email: "",
        status: "",
        startedTrialAt: "",
        trialEndsAt: "",
        drivingSchool: null,
        billingInformation: null,
        code: "",
        active: false,
        ...props,
    } as IInstructor;
};
