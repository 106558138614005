import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useResetPasswordValidationSchema } from "modules/login/hooks/use-form-validation-schema.hook";
import { IResetPasswordCredentials, defaultResetPasswordValues } from "modules/login/models/reset-password.model";
import styles from "./styles.module.scss";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useResetPassword } from "modules/login/hooks/use-reset-password.hook";
import { RGBAColors } from "styles/variables/rgba-colors";
import FormWrapper from "core/components/FormWrapper";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";

export const RequestResetPassword = () => {
    const { t } = useTranslation();
    const resetPasswordValidationSchema = useResetPasswordValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<IResetPasswordCredentials>({
        mode: "onChange",
        resolver: yupResolver(resetPasswordValidationSchema),
        defaultValues: defaultResetPasswordValues,
    });

    const {
        requestPasswordReset,
        isLoading,
        serverErrorMessage,
    } = useResetPassword();

    const onSubmit = async (data: IResetPasswordCredentials) => {
        if (await requestPasswordReset(data)) {
            reset(defaultResetPasswordValues);
        }
    };

    return (
        <FormWrapper
            title={t("forgot-password")}
            backToPath={ROUTES.SIGN_IN}
            submitButtonProps={{
                label: t("send-instructions"),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid || isLoading,
                isLoading: isLoading,
                renderButtonDescription: () => (
                    <Text style={{
                        textAlign: "center",
                        color: RGBAColors.lightBlackWithOpacity(0.6),
                        maxWidth: "288px",
                    }}>
                        {t("reset-password-description")}
                    </Text>
                ),
            }}
            error={serverErrorMessage}
            direction="column"
        >
            <Controller
                name="email"
                control={control}
                render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                    <FormInput<IResetPasswordCredentials>
                        inputRef={ref}
                        label={t("email")}
                        type={FormInputType.TEXT}
                        placeholder={t("reset-password-email-placeholder")}
                        error={error?.message || !!serverErrorMessage}
                        {...restFieldProps}
                    />
                )}
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="64px"
                mb="40px"
            >
                <div className={styles.imagePlaceholder} />
            </Box>
        </FormWrapper>
    );
}
