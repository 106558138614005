import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { userService } from "core/services/user.service";
import { ISignInCredentials } from "../models/sign-in.model";
import { ROUTES } from "modules/home/enums/routes.enum";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { isValidRoute } from "core/utils/route-validator";
import { TUser } from "core/models/user/user.model";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { setItemToLocalStorage } from "core/utils/local-storage-handler";

export const useLogin = () => {
    const { push } = useHistory();
    const { state } = useLocation<ILocationState>();

    const queryClient = useQueryClient();
    const {
        mutateAsync,
        isError,
        isLoading,
        error,
    } = useMutation(
        (data: ISignInCredentials) => userService.login(data),
        {
            onSuccess: (userData: TUser) => {
                const { token, refreshToken, user } = userData;

                setItemToLocalStorage(
                    LocalStorageKeys.user,
                    {
                        token,
                        refreshToken,
                        role: user.roles[0],
                    }
                );

                queryClient.setQueryData("user", user);

                push({
                    pathname: isValidRoute(state?.from?.pathname)
                        ? state.from.pathname
                        : ROUTES.HOME,
                });
            },
            onError: (error: Error) => {
                console.log("Error on login user - ", error);
            },
        },
    );

    return {
        login: mutateAsync,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
