import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useParams } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import { ILessonsParams } from "modules/themes/interfaces/lessons-params.interface";
import { useThemeLessons } from "modules/themes/hooks/use-theme-lessons.hook";
import { getThemeName } from "core/models/theme/theme.model";
import { getLessonName } from "core/models/lesson/lesson.model";
import Loader from "core/components/Loader";
import { Toolbar } from "modules/home/components/Toolbar";
import { CardList } from "modules/themes/components/CardList";
import { pathBuilder } from "core/utils/path-builder";
import { themePlaceholderImage } from "assets/images";
import { CardSize } from "modules/themes/components/CardItem/CardItem";

export const LessonsList = () => {
    const { t } = useTranslation();
    const { themeId } = useParams<Pick<ILessonsParams, "themeId">>();

    const {
        data,
        isLoading,
    } = useThemeLessons({ themeId });

    const themeName = useMemo(() => {
        if (!data?.translations) return "";

        return getThemeName(data.translations);
    }, [data]);

    const formattedLessonResults = useMemo(
        () => {
            if (!data?.lessons) return;

            return data.lessons.map((lesson) => ({
                title: getLessonName(lesson.translations).title,
                description: themeName,
                imageURL: lesson.media ?? themePlaceholderImage,
                path: pathBuilder(
                    ROUTES.THEME_LESSON_DETAILS,
                    ":themeId/:lessonId",
                    `${themeId}/${lesson.id}`
                ),
                size: "large" as CardSize,
            }));
        },
        [data]
    );

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : formattedLessonResults ? (
                <>
                    <Toolbar
                        title={themeName}
                        backToPath={ROUTES.THEMES}
                    />

                    <Box
                        mt="16px"
                        mb="24px"
                    >
                        <Text>
                            {t("you-will-find-count-of-lessons", {
                                lessonCount: t("lesson-count", {
                                    count: data.numberOfLessons,
                                }),
                            })}
                        </Text>
                    </Box>

                    <CardList items={formattedLessonResults} />
                </>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </>
    );
}
