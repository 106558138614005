import { UserRole } from "core/enums/user-roles.enum";
import { createDrivingSchoolFromResponse, IDrivingSchoolResponse } from "../driving-school/driving-school.response";
import { createUser, IUser } from "./user.model";

export interface IUserResponse {
    id: number;
    name: string;
    family_name: string;
    email: string;
    email_verified: boolean;
    active: boolean;
    sub: string;
    roles: Array<UserRole>;
    driving_school: IDrivingSchoolResponse;
    code: string;
}

export const createUserFromResponse = ({
    id,
    family_name,
    email_verified,
    driving_school,
    ...props
}: IUserResponse): IUser => {
    return createUser({
        id: id?.toString(),
        familyName: family_name ?? "",
        emailVerified: email_verified ?? false,
        drivingSchool: driving_school ? createDrivingSchoolFromResponse(driving_school) : null,
        ...props,
    });
};
