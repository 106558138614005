import { useMemo } from "react";
import { object } from "yup";
import { stringValidationSchema } from "core/utils/form-validation-schema";

export function useUserProfileFormValidationSchema() {
    return useMemo(
        () =>
            object().shape({
                driving_school_name: stringValidationSchema(),
                address: stringValidationSchema(),
                zip_code: stringValidationSchema(),
                city: stringValidationSchema(),
            }),
        []
    );
}
