import React from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import styles from "./styles.module.scss";

interface IProps {
    email: string;
    phone: string;
}

export const ContactInformationsCard = ({
    email,
    phone,
}: IProps) => {
    const { t } = useTranslation();

    return (
        <Box
            display="grid"
            flexDirection="column"
            justifyContent="space-between"
            gap={1}
            mr="32px"
            className={styles.cardContainer}
        >
            <Box
                display="grid"
                flexDirection="column"
                gap={1}
            >
                <Text size="medium" bold>
                    {t("contact-informations")}
                </Text>
                <Text
                    color={null}
                    className={styles.cardSubTitle}
                    truncate
                >
                    {email}
                </Text>
            </Box>
            <Text size="medium" bold>
                {phone}
            </Text>
        </Box>
    );
}
