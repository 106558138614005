import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import { PrivateRoute, PublicRoute } from "core/components/Routes";
import { HomePage } from "modules/home/pages/HomePage";
import { SignInPage } from "modules/login/pages/SignInPage";
import { SignUpPage } from "modules/login/pages/SignUpPage";
import { ResetPasswordPage } from "modules/login/pages/ResetPasswordPage";
import { PageNotFound } from "core/pages/PageNotFound";
import { SharedStudentProfile } from "core/pages/SharedStudentProfile";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import "./App.scss";

const App = () => (
    <Router>
        <Switch>
            <PublicRoute
                exact
                strict
                restricted
                path={ROUTES.SIGN_IN}
                component={SignInPage}
            />
            <PublicRoute
                exact
                strict
                restricted
                path={ROUTES.SIGN_UP}
                component={SignUpPage}
            />
            <PublicRoute
                exact
                strict
                restricted
                path={ROUTES.RESET_PASSWORD}
                component={ResetPasswordPage}
            />
            <PublicRoute
                exact
                strict
                path={ROUTES.NOT_FOUND}
                component={PageNotFound}
            />
            <PublicRoute
                path={SHARED_ROUTES.STUDENT_PROFILE}
                component={SharedStudentProfile}
            />
            <PrivateRoute
                path={ROUTES.HOME}
                component={HomePage}
            />
        </Switch>
    </Router>
);

export default App;
