import qs from "qs";
import { API_ROUTES } from "core/enums/api-routes.enum";
import { ISearchParams } from "core/interfaces/search-params.interface";
import { createLessonFromResponse } from "core/models/lesson/lesson.response";
import { ISearch } from "core/models/search/search.model";
import { ISearchResponse } from "core/models/search/search.response";
import { IStudentLearningActivity } from "core/models/student/student-learning-activity.model";
import { createStudentLearningActivityFromResponse, IStudentLearningActivityResponse } from "core/models/student/student-learning-activity.response";
import { IAddStudentCredentialsCommand, IEditStudentCredentialsCommand } from "core/models/student/student.command";
import { IStudent } from "core/models/student/student.model";
import { IStudentResponse, createStudentsFromResponse, createStudentFromResponse } from "core/models/student/student.response";
import { pathBuilder } from "core/utils/path-builder";
import { LearningActivityType } from "modules/students/enums/learning-activity-type.enum";
import { apiService } from "./api-service";
import { AppVersion } from "core/enums/app-version.enum";
import { StudentStatus } from "modules/students/enums/student-status.enum";
import { IStudentDifficultLesson } from "core/models/student/student-difficult-lesson.model";
import { IStudentDifficultLessonResponse } from "core/models/student/student-difficult-lesson.response";

class StudentService {
    async getStudents({
        instructorId,
        appVersion,
        status,
        dashboardStatus,
        ...searchParams
    }: ISearchParams & {
        instructorId?: string,
        appVersion?: AppVersion,
        status?: StudentStatus,
        dashboardStatus?: StudentStatus,
    }): Promise<ISearch<IStudent>> {
        const response = apiService.responseHandler(
            await apiService.get<ISearchResponse<IStudentResponse>>(
                API_ROUTES.STUDENTS,
                {
                    params: {
                        ...searchParams,
                        for_instructor: instructorId,
                        dashboardStatus: dashboardStatus,
                        filters: {
                            "appVersion": appVersion,
                            "status": status === StudentStatus.ACTIVE ? undefined : status,
                            "active": status === StudentStatus.ACTIVE ? true : undefined,
                        },
                    },
                    paramsSerializer: (params) => qs.stringify(params),
                }
            )
        );

        return createStudentsFromResponse(response);
    }

    async addStudent(
        credentials: IAddStudentCredentialsCommand,
    ): Promise<IStudent> {
        const response = apiService.responseHandler(
            await apiService.post<IStudentResponse, IAddStudentCredentialsCommand>(
                API_ROUTES.STUDENTS,
                credentials,
            )
        );

        return createStudentFromResponse(response);
    }

    async editStudent(
        studentId: string,
        credentials: IEditStudentCredentialsCommand,
    ): Promise<IStudent> {
        const response = apiService.responseHandler(
            await apiService.put<IStudentResponse, IEditStudentCredentialsCommand>(
                pathBuilder(
                    API_ROUTES.STUDENT,
                    "{studentId}",
                    studentId
                ),
                credentials,
            )
        );

        return createStudentFromResponse(response);
    }

    async getStudent(
        studentId: string,
    ): Promise<IStudent> {
        const response = apiService.responseHandler(
            await apiService.get<IStudentResponse>(
                pathBuilder(
                    API_ROUTES.STUDENT,
                    "{studentId}",
                    studentId
                ),
            )
        );

        return createStudentFromResponse(response);
    }

    async getStudentLearningActivityProgress(
        studentId: string,
        type: LearningActivityType,
        pastPeriod: number,
    ): Promise<Array<IStudentLearningActivity>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IStudentLearningActivityResponse>>(
                pathBuilder(
                    API_ROUTES.STUDENT_LEARNING_ACTIVITY_PROGRESS,
                    "{studentId}",
                    studentId
                ),
                {
                    params: {
                        type,
                        past_period: pastPeriod,
                    },
                },
            )
        );

        return response?.map(createStudentLearningActivityFromResponse) ?? [];
    }

    async getStudentDifficultLessons(
        studentId: string,
        searchParams?: ISearchParams,
    ): Promise<Array<IStudentDifficultLesson>> {
        const response = apiService.responseHandler(
            await apiService.get<Array<IStudentDifficultLessonResponse>>(
                pathBuilder(
                    API_ROUTES.STUDENT_DIFFICULT_LESSONS,
                    "{studentId}",
                    studentId
                ),
                {
                    params: { ...searchParams },
                },
            )
        );

        return response?.map(({ incorrect_questions_rate, lesson }) => ({
            incorrectQuestionsRate: incorrect_questions_rate,
            lesson: createLessonFromResponse(lesson),
        }));
    }
}

export const studentService = new StudentService();
