import React, { useEffect, useRef } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { ROUTES } from "modules/home/enums/routes.enum";
import NavigationBar from "../components/NavigationBar";
import styles from "./styles.module.scss";
import { PrivateRoute } from "core/components/Routes";
import Snackbar from "core/components/Snackbar";
import { useSnackbar } from "core/hooks/use-snackbar.hook";
import { Dialog } from "core/components/Dialog";
import { useDialog } from "core/hooks/use-dialog.hook";
import { useNavigationItems } from "../hooks/use-navigation-items.hook";
import { UserProfilePage } from "./UserProfilePage";
import { useUserRole } from "../hooks/use-user-role.hook";
import { UserRole } from "core/enums/user-roles.enum";
// import { AppLanguageListPage } from "./AppLanguageListPage";

export const HomePage = () => {
    const { snackbarProps } = useSnackbar();
    const { dialogProps } = useDialog();

    const { userRole } = useUserRole();
    const { navigationItems, enabledNavigationItems } = useNavigationItems();

    const pageContentWrapperRef = useRef<HTMLDivElement>();
    const { pathname } = useLocation();

    useEffect(() => {
        const pageContentWrapperElement = pageContentWrapperRef?.current;

        if (!pageContentWrapperElement) return;

        const {
            scrollHeight,
            clientHeight,
        } = pageContentWrapperElement;

        if (scrollHeight > clientHeight) {
            pageContentWrapperElement.scrollTo(0, 0);
        }
    }, [pathname]);

    return (
        <main className={styles.mainContainer}>
            <div className={styles.navigationBarWrapper}>
                <NavigationBar navigationItems={navigationItems} />
            </div>
            <div
                id="pageContentWrapperElement"
                ref={pageContentWrapperRef}
                className={styles.pageContentWrapper}
            >
                <Switch>
                    <Redirect
                        exact
                        from={ROUTES.HOME}
                        to={enabledNavigationItems[0]?.path ?? ROUTES.HOME}
                    />

                    {enabledNavigationItems.map(({ path, exactPath, Component }) => (
                        <PrivateRoute
                            key={path}
                            exact={exactPath}
                            path={path}
                            component={Component}
                        />
                    ))}

                    {userRole === UserRole.ROLE_INSTRUCTOR && (
                        <Switch>
                            <PrivateRoute
                                exact
                                path={ROUTES.USER_PROFILE}
                                component={UserProfilePage}
                            />
                            {/* <PrivateRoute
                                exact
                                path={ROUTES.USER_PROFILE_APP_LANGUAGE}
                                component={AppLanguageListPage}
                            /> */}
                            <Redirect from="*" to={ROUTES.NOT_FOUND} />
                        </Switch>
                    )}

                    <Redirect from="*" to={ROUTES.NOT_FOUND} />
                </Switch>
            </div>
            <Snackbar {...snackbarProps} />
            <Dialog {...dialogProps} />
        </main>
    );
}
