import React, { FC, useRef } from "react";
import { useIntersectionObserver } from "core/hooks/use-intersection-observer.hook";
import Loader from "../Loader";

export interface IInfiniteScroll {
    hasMore: boolean;
    isFetching: boolean;
    onFetchMore: () => void;
}

export const InfiniteScroll: FC<IInfiniteScroll> = ({
    hasMore,
    isFetching,
    onFetchMore,
    children,
}) => {
    const loadMoreElementRef = useRef();

    useIntersectionObserver({
        target: loadMoreElementRef,
        enabled: hasMore,
        onIntersect: onFetchMore,
    });

    return (
        <>
            {isFetching && hasMore && <Loader />}
            {children}
            <div
                id="loadMoreElement"
                ref={loadMoreElementRef}
                hidden={!hasMore}
            />
        </>
    );
}
