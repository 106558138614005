export enum ROUTES {
    NOT_FOUND = '/404',
    HOME = '/',
    SIGN_IN = '/sign-in',
    SIGN_UP = '/sign-up',
    RESET_PASSWORD = '/reset-password',
    DASHBOARD = '/dashboard',
    STUDENTS = '/students',
    ADD_STUDENT = '/students/add',
    EDIT_STUDENT = '/students/:studentId/edit',
    STUDENT_PROFILE = '/students/:studentId',
    STUDENT_OVERALL_PROGRESS = '/students/:studentId/overall-progress',
    STUDENT_SIMULATOR_PROGRESS = '/students/:studentId/simulator-progress',
    STUDENT_SIMULATOR_PROGRESS_DETAILS = '/students/:studentId/simulator-progress/:simulationId',
    THEMES = '/themes',
    THEME_LESSONS = '/themes/:themeId',
    THEME_LESSON_DETAILS = '/themes/:themeId/:lessonId',
    NOTIFICATIONS = '/notifications',
    DRIVING_SCHOOLS = '/driving-schools',
    DRIVING_SCHOOL = '/driving-schools/:drivingSchoolId',
    EDIT_DRIVING_SCHOOL = '/driving-schools/:drivingSchoolId/edit',
    DRIVING_SCHOOL_STUDENTS = '/driving-schools/:drivingSchoolId/students',
    USER_PROFILE = '/profile',
    USER_PROFILE_APP_LANGUAGE = '/profile/app-language',
}
