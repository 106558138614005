import { Box } from "@material-ui/core";
import React, { FC } from "react";

export const ContentContainer: FC = ({ children }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            flex={1}
            p="16px 24px"
            maxWidth="1664px" // 1920 - 256(width of side NavigationBar)
        >
            {children}
        </Box>
    );
}
