import { adminNavigationItems } from "modules/sw-admin/constants/navigation-items"
import { instructorNavigationItems } from "modules/home/constants/navigation-items"
import { UserRole } from "core/enums/user-roles.enum";
import { useUserRole } from "./use-user-role.hook";

export const useNavigationItems = () => {
    const { userRole } = useUserRole();

    const navigationItems = userRole === UserRole.ROLE_SW_ADMIN
        ? [...adminNavigationItems]
        : [...instructorNavigationItems];

    return {
        navigationItems,
        enabledNavigationItems: [...navigationItems].filter(({enabled}) => enabled),
    };
}
