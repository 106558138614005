import { t } from "./multi-language-support";
import { defaultErrorOptions, IError, IErrorOptions } from "core/interfaces/error.interface";

export const handleErrorMessage = (
    error: Partial<IError>,
    options?: IErrorOptions,
): string => {
    const defaultErrorMessage: string = t("error-occurred");
    const { showExceptionMessage }: IErrorOptions = options ?? { ...defaultErrorOptions };

    if (error?.message) {
        return error.message;
    }

    if (showExceptionMessage) {
        const exception = error?.debug?.exception;

        return exception?.length && exception[0]?.message
            ? exception[0].message
            : defaultErrorMessage;
    }

    return defaultErrorMessage;
}
