import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { StudentsIcon } from "assets/icons/navigation-bar";
import { ArrowForwardIcon } from "assets/icons";
import colors from "styles/variables/colors.module.scss";
import styles from "./styles.module.scss";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";

interface IProps {
    drivingSchoolId: string;
}

export const DrivingStudentsInformations = ({ drivingSchoolId }: IProps) => {
    const { t } = useTranslation();

    return (
        <Link
            to={pathBuilder(
                ROUTES.DRIVING_SCHOOL_STUDENTS,
                ":drivingSchoolId",
                drivingSchoolId
            )}
        > 
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                className={styles.container}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="20px"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={styles.studentIconContainer}
                    >
                        <StudentsIcon color={colors.orange} />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="4px"
                    >
                        <Text bold>{t("driving-students-informations")}</Text>
                        <Text size="small" color={null} className={styles.subTitle}>
                            {`${t("personal-informations")}, ${t("total-progress")}, ${t("videos-watched")}`}
                        </Text>
                    </Box>
                </Box>
                <ArrowForwardIcon />
            </Box>
        </Link>
    );
}
