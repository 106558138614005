import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useSignUpValidationSchema } from "modules/login/hooks/use-form-validation-schema.hook";
import { VerifyEmailModal } from "./VerifyEmailModal";
import {
    defaultSignUpValues,
    ISignUpCredentials,
} from "modules/login/models/sign-up.model";
import { Layout } from "modules/login/components/Layout";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { useRegister } from "modules/login/hooks/use-register.hook";
import AutocompleteFormInput from "core/components/AutocompleteFormInput";
import { useDrivingSchools } from "modules/login/hooks/use-driving-schools.hook";
import FormWrapper from "core/components/FormWrapper";
import Text from "core/components/Text";

const TERMS_OF_SERVICE_URL = "https://streamways.com/agb";
const PRIVACY_POLICY_URL = "https://streamways.com/datenschutz";

export function SignUpPage() {
    const { t } = useTranslation();
    const signUpValidationSchema = useSignUpValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        clearErrors,
        setValue,
        trigger,
    } = useForm<ISignUpCredentials>({
        mode: "onChange",
        resolver: yupResolver(signUpValidationSchema),
        defaultValues: defaultSignUpValues,
    });
    const { push } = useHistory();
    const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);
    const {
        register,
        isLoading,
        serverErrorMessage,
    } = useRegister();

    const {
        drivingSchools,
        debouncedSearchQuery,
        setDrivingSchoolQuery,
    } = useDrivingSchools();

    // Used to fill form fields if driving school is selected from dropdown
    useEffect(() => {
        const selectedDrivingSchool = drivingSchools?.find(
            (drivingSchool) => drivingSchool.name === debouncedSearchQuery
        ) ?? null;

        if (!selectedDrivingSchool) return;

        setValue("address", selectedDrivingSchool.address);
        setValue("city", selectedDrivingSchool.city);
        setValue("zip_code", selectedDrivingSchool.postalCode);

        trigger(["address", "city", "zip_code"]); // Revalidate fields
    }, [debouncedSearchQuery, drivingSchools]);

    const onSubmit = async (data: ISignUpCredentials) => {
        if (await register(data)) {
            setOpenVerifyEmailModal(true);
        }
    };

    const onVerifyEmailModalCTA = () => {
        setOpenVerifyEmailModal(false);
        push(ROUTES.SIGN_IN);
    }

    return (
        <Layout>
            <FormWrapper
                title={t("create-driving-school-account")}
                backToPath={ROUTES.SIGN_IN}
                submitButtonProps={{
                    label: t("create-driving-school-account"),
                    onClick: handleSubmit(onSubmit),
                    disabled: !isValid || isLoading,
                    isLoading: isLoading,
                    renderButtonDescription: () => (
                        <Text
                            size="small"
                            style={{
                                textAlign: "center",
                                maxWidth: "340px",
                            }}
                        >
                            {/* Numbers inside Trans component defines position of the translation text parts */}
                            <Trans i18nKey="terms-of-service-and-privacy-policy-agreement">
                                0
                                <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">1</a>
                                2
                                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">3</a>
                            </Trans>
                        </Text>
                    ),
                }}
                error={serverErrorMessage}
                direction="column"
            >
                <Box display="grid" flexDirection="column" gap={2}>
                    <Controller
                        name="driving_school_name"
                        control={control}
                        render={({
                            field: { ref, ...restFieldProps },
                            fieldState: { error },
                        }) => (
                            <AutocompleteFormInput<ISignUpCredentials>
                                elementRef={ref}
                                label={t("driving-school-name")}
                                placeholder={t("driving-school-name-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                // options={drivingSchools?.map((drivingSchool) => drivingSchool.name) ?? []}
                                options={[]}
                                onInputChange={
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    (event: React.SyntheticEvent<Element>, value: string, reason: string) => {
                                        // setDrivingSchoolQuery(value?.trim() ?? "");

                                        if (value?.trim()?.length) {
                                            error && clearErrors("driving_school_name");
                                            setValue("driving_school_name", value, { shouldValidate: true });
                                        }
                                    }
                                }
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="address"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<ISignUpCredentials>
                                inputRef={ref}
                                label={t("address")}
                                type={FormInputType.TEXT}
                                placeholder={t("address-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        <Controller
                            name="zip_code"
                            control={control}
                            render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                                <FormInput<ISignUpCredentials>
                                    inputRef={ref}
                                    label={t("zip-code")}
                                    type={FormInputType.NUMBER}
                                    placeholder={t("zip-code-placeholder")}
                                    error={error?.message || !!serverErrorMessage}
                                    style={{flex: 1, minWidth: "150px", marginRight: "32px" }}
                                    {...restFieldProps}
                                />
                            )}
                        />
                        <Controller
                            name="city"
                            control={control}
                            render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                                <FormInput<ISignUpCredentials>
                                    inputRef={ref}
                                    label={t("city")}
                                    type={FormInputType.TEXT}
                                    placeholder={t("city-placeholder")}
                                    error={error?.message || !!serverErrorMessage}
                                    style={{flex: 1, minWidth: "150px"}}
                                    {...restFieldProps}
                                />
                            )}
                        />
                    </Box>
                    <Controller
                        name="email"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<ISignUpCredentials>
                                inputRef={ref}
                                label={t("email-address")}
                                type={FormInputType.TEXT}
                                placeholder={t("email-address-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<ISignUpCredentials>
                                inputRef={ref}
                                label={t("password")}
                                type={FormInputType.PASSWORD}
                                placeholder={t("password-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                    <Controller
                        name="confirm_password"
                        control={control}
                        render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                            <FormInput<ISignUpCredentials>
                                inputRef={ref}
                                label={t("repeat-password")}
                                type={FormInputType.PASSWORD}
                                placeholder={t("repeat-password-placeholder")}
                                error={error?.message || !!serverErrorMessage}
                                {...restFieldProps}
                            />
                        )}
                    />
                </Box>
            </FormWrapper>
            <VerifyEmailModal
                openDialog={openVerifyEmailModal}
                onClose={onVerifyEmailModalCTA}
            />
        </Layout>
    );
}
