import React from "react";
import {Control, Controller} from "react-hook-form";
import { Box } from "@material-ui/core";
import { FormSelect } from "core/components/FormSelect";
import { useTranslation } from "core/hooks/use-translation.hook";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";

interface IProps {
    control: Control<any>;
    serverErrorMessage: string;
    type: "aspired-licenses" | "acquired-licenses";
    validate: (fieldName: string) => void;
}

export const LicenseClassController = ({
    control,
    serverErrorMessage,
    type,
    validate,
}: IProps) => {
    const { t } = useTranslation();

    const options = [
        { name: "A", value: "A" },
        { name: "A1", value: "A1" },
        { name: "A2", value: "A2" },
        { name: "AM", value: "AM" },
        { name: "B", value: "B" },
        { name: "C", value: "C" },
        { name: "C1", value: "C1" },
        { name: "CE", value: "CE" },
        { name: "D", value: "D" },
        { name: "D1", value: "D1" },
        { name: "L", value: "L" },
        { name: "T", value: "T" },
        { name: "MOFA", value: "MOFA" },
    ];

    if (type === "acquired-licenses") {
        options.unshift({ name: "Kein Führerschein vorhanden", value: '' });
    }

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Controller
                name={type.replace('-', '_')}
                control={control}
                render={({field: { ref, value, ...restFieldProps }, fieldState: { error }}) => {
                    const errorMessage = error?.message || !!serverErrorMessage;

                    return (
                        <FormSelect
                            selectRef={ref}
                            label={t(type)}
                            error={errorMessage}
                            options={options}
                            value={value ?? []}
                            onClose={() => validate(type)}
                            styledFormSelectProps={{
                                borderColor: errorMessage ? colors.red : "transparent",
                                textColor: !value?.length
                                    ? RGBAColors.lightBlackWithOpacity(0.38)
                                    : colors.lightBlack,
                            }}
                            multiple={true}
                            {...restFieldProps}
                        />
                    );
                }}
            />
        </Box>
    );
}
