import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { Table } from "core/components/Table";
import { useTabs } from "modules/sw-admin/hooks/use-tabs.hook";
import { useInstructors } from "modules/sw-admin/driving-schools/hooks/use-instructors.hook";
import Loader from "core/components/Loader";
import Text from "core/components/Text";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { InstructorAccountType } from "modules/sw-admin/driving-schools/enums/instructor-account-type.enum";
import { ITableHeadCell } from "core/components/Table/TableHead";
import { SwitchAccountStatus } from "./SwitchAccountStatus";
import { IUseConfirmationDialog } from "core/hooks/use-confirmation-dialog.hook";
import { UserStatus } from "core/enums/user-status.enum";

const officialAccountsTableHead: Array<ITableHeadCell> = [
    { translateKey: "driving-school" },
    { translateKey: "email-address" },
    { translateKey: "phone-number" },
    { translateKey: "state" },
    { translateKey: "profiles-last-month" },
    { translateKey: "profiles-this-month" },
    { translateKey: "premium-account" },
];

interface IOfficialAccountsTableBody {
    id: string;
    drivingSchool: string;
    emailAddress: string;
    phoneNumber: string;
    state: string;
    profilesLastMonth: number;
    profilesThisMonth: number;
    premiumAccount: React.ReactNode;
}

export const OfficialAccountsTable = () => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const {
        tabActivePage,
        setTabActivePage,
    } = useTabs();

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useInstructors({
        type: InstructorAccountType.OFFICIAL_ACCOUNTS,
        searchParams: { page: tabActivePage }
    });

    const deactivatePremiumDialogProps: IUseConfirmationDialog = useMemo(() => ({
        type: "error",
        title: t("deactivate-account", { accountType: t("premium-account") }),
        description: t("deactivate-account-description", { accountType: t("premium-account").toLowerCase() }),
        actionButtonTitle: t("deactivate-account", { accountType: t("premium-account") }),
    }), []);

    const activatePremiumDialogProps: IUseConfirmationDialog = useMemo(() => ({
        type: "success",
        title: t("activate-account", { accountType: t("premium-account") }),
        description: t("activate-account-description", { accountType: t("premium-account").toLowerCase() }),
        actionButtonTitle: t("activate-account", { accountType: t("premium-account") }),
    }), []);

    const officialAccountsTableBody: Array<IOfficialAccountsTableBody> = useMemo(() => {
        if (!data?.items?.length) return [];

        return data.items.map(({ drivingSchool, ...instructor }) => ({
            id: instructor.id,
            drivingSchool: drivingSchool?.name,
            emailAddress: instructor.email,
            phoneNumber: drivingSchool?.phone,
            state: drivingSchool?.state,
            profilesLastMonth: drivingSchool?.studentsLastMonth,
            profilesThisMonth: drivingSchool?.studentsThisMonth,
            premiumAccount: <SwitchAccountStatus
                instructorId={instructor.id}
                isActive={!instructor.active}
                checked={instructor.status === UserStatus.PREMIUM && instructor.active}
                confirmationDialogProps={instructor.active
                    ? deactivatePremiumDialogProps
                    : activatePremiumDialogProps
                }
            />,
        }));
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : data?.items?.length > 0 ? (
                <Table<IOfficialAccountsTableBody>
                    headProps={{
                        data: officialAccountsTableHead,
                    }}
                    bodyProps={{
                        data: officialAccountsTableBody,
                        onRowClick: (id: string) => {
                            push(
                                pathBuilder(
                                    ROUTES.DRIVING_SCHOOL,
                                    ":drivingSchoolId",
                                    id,
                                )
                            );
                        },
                    }}
                    paginationProps={{
                        page: tabActivePage,
                        lastActivePage: data.currentPage,
                        pageSize: data.items.length,
                        totalPages: data.totalPages,
                        totalResults: data.totalResults,
                        isFetchingNextPage,
                        setPage: setTabActivePage,
                    }}
                />
            ) : (
                <Box mt="32px">
                    <Text>{t("no-data-available")}</Text>
                </Box>
            )}
        </>
    );
}
