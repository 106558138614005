import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Text from "core/components/Text";
import { FormInputType } from "core/enums/form-input-type.enum";
import styles from "./styles.module.scss";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import { VisibilityOffIcon, VisibilityOnIcon } from "assets/icons";
import { Adornment } from "./Adornment";

interface IAdornmentProps {
    showAdornment: boolean;
    AdornmentIcon: React.ReactNode;
    onAdornmentClick?: () => void;
}

export interface IFormInputProps<T> {
    name: keyof T;
    value: string | number;
    type: FormInputType;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    placeholder?: string;
    error?: string | boolean;
    inputRef?: React.RefCallback<HTMLInputElement> | React.Ref<HTMLInputElement>;
    style?: React.CSSProperties;
    autoComplete?: string;
    startAdornment?: IAdornmentProps;
    endAdornment?: IAdornmentProps;
    maxLength?: number;
    readOnly?: boolean;
    inputClassName?: string;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function FormInput<T>({
    label,
    name,
    value,
    type,
    onChange,
    placeholder,
    error,
    inputRef,
    style,
    autoComplete,
    startAdornment,
    endAdornment,
    maxLength,
    readOnly,
    inputClassName,
    onFocus,
    onBlur,
}: IFormInputProps<T>) {
    const [inputType, setInputType] = useState<FormInputType>(type);

    const {
        showAdornment: showStartAdornment,
        AdornmentIcon: StartAdornmentIcon,
    } = startAdornment ?? {};

    const {
        showAdornment: showEndAdornment,
        AdornmentIcon: EndAdornmentIcon,
        onAdornmentClick: onEndAdornmentClick,
    } = endAdornment ?? {};

    return (
        <Box
            display="grid"
            flexDirection="column"
            width="1"
            gap="4px"
            style={style}
        >
            {label && <Text size="small">{label}</Text>}
            <Box
                display="flex"
                alignItems="center"
                className={classNameFormatter([
                    styles.formInputContainer,
                    error ? styles.error : "",
                    inputClassName,
                ])}
                gap={1}
            >
                {showStartAdornment && (
                    <Adornment>
                        <>{StartAdornmentIcon}</>
                    </Adornment>
                )}
                <input
                    ref={inputRef}
                    name={name as string}
                    value={value}
                    type={inputType}
                    placeholder={placeholder}
                    onChange={onChange}
                    spellCheck="false"
                    maxLength={maxLength}
                    readOnly={readOnly}
                    autoComplete={autoComplete}
                    className={classNameFormatter([
                        styles.formInput,
                        !readOnly ? styles.textCursor : styles.defaultCursor,
                    ])}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {(showEndAdornment || type === FormInputType.PASSWORD) && (
                    <Adornment
                        clickable={!readOnly}
                        onClick={() => {
                            if (type === FormInputType.PASSWORD) {
                                setInputType(inputType === FormInputType.PASSWORD
                                    ? FormInputType.TEXT
                                    : FormInputType.PASSWORD
                                );
                            } else {
                                onEndAdornmentClick && onEndAdornmentClick();
                            }
                        }}
                    >
                        {type === FormInputType.PASSWORD ? (
                            <>
                                {inputType === FormInputType.TEXT ? (
                                    <VisibilityOnIcon className={styles.adornmentIcon}/>
                                ) : (
                                    <VisibilityOffIcon className={styles.adornmentIcon}/>
                                )}
                            </>
                        ) : (
                            <>{EndAdornmentIcon}</>
                        )}
                    </Adornment>
                )}
            </Box>
            {(typeof error === "string") && (
                <Text size="small" color="red">{error}</Text>
            )}
        </Box>
    );
}
