import React, { useEffect, useState } from "react";
import MUISnackbar from '@material-ui/core/Snackbar';
import { TIME_DURATION } from "core/enums/time-duration.enum";
import Text from "core/components/Text";
import styles from "./styles.module.scss";
import { Alert, AlertType } from "./components/Alert";

export interface ISnackbarProps {
    open: boolean;
    message: string;
    autoHideDuration?: TIME_DURATION;
    alertType?: AlertType;
    onClose?: () => void;
}

export const Snackbar = ({
    open,
    message,
    autoHideDuration,
    alertType,
    onClose,
}: ISnackbarProps) => {
    const [openSnackbar, setOpenSnackbar] = useState(open ?? false);

    useEffect(() => {
        setOpenSnackbar(open);
    }, [open]);

    const handleClose = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string,
    ) => {
        if (reason === "clickaway") return;

        setOpenSnackbar(false);
        onClose && onClose();
    };

    return (
        <MUISnackbar
            key={message}
            open={openSnackbar}
            autoHideDuration={autoHideDuration}
            disableWindowBlurListener
            onClose={handleClose}
            anchorOrigin={{
                horizontal: "center",
                vertical: "top",
            }}
            classes={{
                root: styles.snackbarRoot,
            }}
        >
            <Alert
                icon={false}
                severity={alertType}
                classes={{
                    root: styles.alertRoot,
                    filledSuccess: styles.alertFilledSuccess,
                    filledError: styles.alertFilledError,
                }}
            >
                <Text color="white">
                    {message}
                </Text>
            </Alert>
        </MUISnackbar>
    );
}
