import { useMutation, useQueryClient } from "react-query";
import { ISearch } from "core/models/search/search.model";
import { IStudent } from "core/models/student/student.model";
import { userService } from "core/services/user.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { getItemFromLocalStorage } from "core/utils/local-storage-handler";
import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";

interface IUseToggleActiveStudent {
    studentId: string;
    isActive: boolean;
}

export const useToggleActiveStudent = () => {
    const studentsQueryKey = getItemFromLocalStorage(LocalStorageKeys.studentsQueryKey);

    const queryClient = useQueryClient();
    const {
        mutateAsync: toggleActiveStudent,
        isLoading,
        isError,
        error,
    } = useMutation(
        ({ studentId, isActive }: IUseToggleActiveStudent) => {
            return userService.toggleActiveUser(studentId, isActive);
        },
        {
            onMutate: async ({ studentId, isActive }: IUseToggleActiveStudent) => {
                await queryClient.cancelQueries(["student", studentId]);
                await queryClient.cancelQueries(studentsQueryKey);

                const previousStudent: IStudent = queryClient.getQueryData(["student", studentId]);
                if (previousStudent) {
                    queryClient.setQueryData(
                        ["student", studentId],
                        (previousData: IStudent) => {
                            return {
                                ...previousData,
                                active: isActive,
                            };
                        }
                    );
                }

                const previousStudents: ISearch<IStudent> = queryClient.getQueryData(studentsQueryKey);
                if (previousStudents) {
                    queryClient.setQueryData(
                        studentsQueryKey,
                        (previousData: ISearch<IStudent>) => {
                            const { items, ...restPreviousData } = previousData;

                            return {
                                ...restPreviousData,
                                items: items.map((item) => {
                                    if (item.id === studentId) {
                                        return {
                                            ...item,
                                            active: isActive,
                                        };
                                    }

                                    return item;
                                }),
                            };
                        }
                    );
                }

                return () => {
                    queryClient.setQueryData(["student", studentId], previousStudent);
                    queryClient.setQueryData(studentsQueryKey, previousStudents);
                };
            },
            onError: (error: Error, variables: IUseToggleActiveStudent, rollback: CallableFunction) => {
                console.log("Error on toggle active student - ", error);

                rollback && rollback();
            },
            onSettled: (data, error, variables: IUseToggleActiveStudent) => {
                queryClient.invalidateQueries(["student", variables.studentId]);
                queryClient.invalidateQueries(studentsQueryKey);
            },
        }
    )

    return {
        toggleActiveStudent,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
