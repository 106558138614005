import { createQuestion, IQuestion } from "./lesson-question.model";

export interface IQuestionResponse {
    id: number;
    position: number;
}

export const createQuestionFromResponse = ({
    id,
    ...props
}: IQuestionResponse): IQuestion => {
    return createQuestion({
        id: id?.toString(),
        ...props,
    });
};
