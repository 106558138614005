import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useTranslation } from "core/hooks/use-translation.hook";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useConfirmationDialog } from "core/hooks/use-confirmation-dialog.hook";
import Button from "core/components/Button";
import colors from "styles/variables/colors.module.scss";
import { useDialog } from "core/hooks/use-dialog.hook";
import { ILocationState } from "core/interfaces/route-location-state.interface";
import { useChangeInstructorStatus } from "modules/sw-admin/driving-schools/hooks/use-change-instructor-status.hook";
import { UserStatus } from "core/enums/user-status.enum";

interface IProps {
    instructorId: string;
}

export const UnverifiedTableActions = ({ instructorId }: IProps) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const locationState: ILocationState = useMemo(() => ({
        from: { pathname },
    }), [pathname]);

    const { changeInstructorStatus } = useChangeInstructorStatus();
    const { setDialogProps } = useDialog();

    const verifyDialogProps = useConfirmationDialog({
        type: "success",
        title: t("verify-driving-school"),
        description: t("verify-description"),
        actionButtonTitle: t("verify"),
        onConfirm: () => changeInstructorStatus({
            instructorId,
            status: UserStatus.TRIAL,
        }),
    });

    const declineDialogProps = useConfirmationDialog({
        type: "error",
        title: t("decline-driving-school"),
        description: t("decline-description"),
        actionButtonTitle: t("decline"),
        onConfirm: () => changeInstructorStatus({
            instructorId,
            status: UserStatus.DECLINED,
        }),
    });

    return (
        <Box
            display="flex"
            alignItems="center"
            // gap={1}
            onClick={(event) => event.stopPropagation()}
        >
            <Button
                label={t("verify")}
                onClick={() => setDialogProps(verifyDialogProps)}
                style={{
                    background: colors.green,
                    height: "35px",
                    minWidth: "80px",
                    maxWidth: "80px",
                }}
            />
            <Button
                label={t("decline")}
                onClick={() => setDialogProps(declineDialogProps)}
                style={{
                    background: colors.lightRed,
                    height: "35px",
                    minWidth: "80px",
                    maxWidth: "80px",
                }}
            />
            <Link to={{
                pathname: pathBuilder(
                    ROUTES.EDIT_DRIVING_SCHOOL,
                    ":drivingSchoolId",
                    instructorId,
                ),
                state: locationState,
            }}>
                <Button
                    label={t("edit")}
                    textColor="black"
                    style={{
                        background: colors.white,
                        border: `1px solid ${colors.inputGray}`,
                        height: "34px",
                        minWidth: "80px",
                        maxWidth: "80px",
                    }}
                />
            </Link>
        </Box>
    );
}
