import { IAddStudentCredentials } from "./add-student.model";

export type IEditStudentCredentials = Omit<IAddStudentCredentials, "email">;

export const defaultEditStudentValues: IEditStudentCredentials = {
    first_name: "",
    last_name: "",
    start_date: "",
    gender: "",
    custom_gender: "",
    day: "",
    month: "",
    year: "",
    acquired_licenses: null,
    aspired_licenses: null,
}
