export interface IDrivingSchool {
    id: string;
    name: string;
    address: string;
    city: string;
    postalCode: string;
    partner: boolean;
    phone: string;
    activeStudents?: number;
    studentsPendingInvitation?: number;
    studentsPassed?: number;
    studentsReadyForExam?: number;
    totalStudents?: number;
}

export const createDrivingSchool = ({ id, ...props }: Partial<IDrivingSchool>) => {
    if (!id) {
        throw new Error('Unable to create a driving school without an ID!');
    }

    return {
        id,
        name: "",
        address: "",
        city: "",
        postalCode: "",
        partner: null,
        phone: "",
        activeStudents: null,
        studentsPendingInvitation: null,
        studentsPassed: null,
        studentsReadyForExam: null,
        totalStudents: null,
        ...props,
    } as IDrivingSchool;
};
