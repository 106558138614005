import { SimulationStatus } from "modules/students/enums/simulation-status.enum";

export interface ISimulation {
    id: string;
    status: SimulationStatus;
    createdAt: string;
    completedAt: string;
    result: Map<string, {
        total: number;
        correct: number;
        errorPoints: number;
    }>;
}

export const createSimulation = ({ id, ...props }: Partial<ISimulation>) => {
    if (!id) {
        throw new Error('Unable to create a simulation without an ID!');
    }

    return {
        id,
        status: "",
        createdAt: "",
        completedAt: "",
        result: null,
        ...props,
    } as ISimulation;
};
