import React, { useMemo } from "react";
import Text from "core/components/Text";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import { classNameFormatter } from "core/utils/css-classname-formatter";
import { roundNumber } from "core/utils/round-number";

export interface IStudentProgressBarProps {
    correctAnswers: number;
    wrongAnswers: number;
    showProgressCount?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const StudentProgressBar = ({
    correctAnswers,
    wrongAnswers,
    showProgressCount,
    className,
    style,
}: IStudentProgressBarProps) => {
    const totalAnswers: number = useMemo(
        () => roundNumber(correctAnswers + wrongAnswers),
        [correctAnswers, wrongAnswers]
    );

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            {showProgressCount && (
                <Text color="black" style={{ minWidth: "40px" }}>
                    {`${totalAnswers}%`}
                </Text>
            )}
            <div
                className={classNameFormatter([
                    styles.progressContainer,
                    className,
                ])}
                style={style}
            >
                <div
                    className={styles.totalProgress}
                    style={{ width: `${totalAnswers}%` }}
                />
                <div
                    className={styles.correctProgress}
                    style={{ width: `${correctAnswers}%` }}
                />
            </div>
        </Box>
    );
}
