import React from "react";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";
import { CheckIcon, XIcon } from "assets/icons";
import Text from "core/components/Text";
import { RGBAColors } from "styles/variables/rgba-colors";
import { StudentProgressBar } from "modules/students/components/StudentProgressBar";

interface IProps {
    themeName: string;
    correctAnswers: number;
    wrongAnswers: number;
}

export const OverallProgressItem = ({
    themeName,
    correctAnswers,
    wrongAnswers,
}: IProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            // gap={2}
            className={styles.overallProgressItemContainer}
        >
            <Text>{themeName}</Text>

            <StudentProgressBar
                correctAnswers={correctAnswers}
                wrongAnswers={wrongAnswers}
                className={styles.studentProgressBar}
            />

            <Box
                display="flex"
                alignItems="center"
                gap={2}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    // gap={1}
                >
                    <CheckIcon />
                    <Text style={{ color: RGBAColors.lightBlackWithOpacity(0.6) , marginLeft: "8px"}}>
                        {`${correctAnswers}%`}
                    </Text>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    // gap={1}
                >
                    <XIcon />
                    <Text style={{ color: RGBAColors.lightBlackWithOpacity(0.6), marginLeft: "8px" }}>
                        {`${wrongAnswers}%`}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}
