import { createStudentProgress, IStudentProgress } from "./student-progress.model";

export interface IStudentProgressResponse {
    overall_total: number;
    overall_correct: number;
    overall_incorrect: number;
    overall_success_rate: number;
    simulation_failed: number;
    simulation_max: number;
    simulation_passed: number;
    simulation_success_rate: number;
    simulation_total: number;
}

export const createStudentProgressFromResponse = ({
    overall_total,
    overall_correct,
    overall_incorrect,
    overall_success_rate,
    simulation_failed,
    simulation_max,
    simulation_passed,
    simulation_success_rate,
    simulation_total,
    ...props
}: IStudentProgressResponse): IStudentProgress => {
    return createStudentProgress({
        overallTotal: overall_total ?? null,
        overallCorrect: overall_correct ?? null,
        overallIncorrect: overall_incorrect ?? null,
        overallSuccessRate: overall_success_rate ?? null,
        simulationFailed: simulation_failed ?? null,
        simulationMax: simulation_max ?? null,
        simulationPassed: simulation_passed ?? null,
        simulationSuccessRate: simulation_success_rate ?? null,
        simulationTotal: simulation_total ?? null,
        ...props,
    });
};
