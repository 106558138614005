import { useQuery } from "react-query"
import { notificationService } from "core/services/notification.service";
import { handleErrorMessage } from "core/utils/handle-error-message";
import { SortDirection } from "core/interfaces/search-params.interface";
import { TIME_DURATION } from "core/enums/time-duration.enum";

interface IUseNotifications {
    enabled: boolean;
}

export const useNotifications = (props?: IUseNotifications) => {
    const { enabled } = props ?? { enabled: true };

    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["notifications"],
        () => notificationService.getNotifications({
            orderBy: "createdAt",
            direction: SortDirection.DESC,
        }),
        {
            onError: (err: Error) => {
                console.log("Error on getting notifications - ", err);
            },
            // Refetch data in background every 15 minutes
            refetchInterval: TIME_DURATION.MINUTE * 15,
            enabled,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
