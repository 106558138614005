import { useQuery } from "react-query";
import { TIME_DURATION } from "core/enums/time-duration.enum";
import { lessonService } from "core/services/lesson.service";
import { handleErrorMessage } from "core/utils/handle-error-message";

export const useLessonQuizAccessData = (lessonId: string) => {
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(
        ["lesson-quiz-access-data", lessonId],
        () => lessonService.getLessonQuizAccessData(lessonId),
        {
            onError: (error: Error) => {
                console.log("Error on getting lesson quiz access data - ", error);
            },
            staleTime: TIME_DURATION.MINUTE,
            enabled: !!lessonId,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError && handleErrorMessage(error),
    };
}
