import React, { useEffect, useMemo, useState } from "react";
import { Control, Controller, UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { Box } from "@material-ui/core";
import { FormSelect, ISelectOption } from "core/components/FormSelect";
import { useTranslation } from "core/hooks/use-translation.hook";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";
import FormInput from "core/components/FormInput";
import { FormInputType } from "core/enums/form-input-type.enum";

interface IProps {
    control: Control<any>;
    serverErrorMessage: string;
    validate: (fieldName: string) => void;
    getFormValues: () => any;
    setValue: UseFormSetValue<any>;
    clearErrors: UseFormClearErrors<any>;
    defaultValue?: string;
}

export const GenderController = ({
    control,
    serverErrorMessage,
    validate,
    getFormValues,
    setValue,
    clearErrors,
    defaultValue,
}: IProps) => {
    const { t } = useTranslation();

    const genderOptions: Array<ISelectOption & { type: string; }> = useMemo(
        () => {
            const options = [
                { name: t("male"), type: "predefined" },
                { name: t("female"), type: "predefined" },
                { name: t("let-me-type"), type: "custom" },
                { name: t("id-rather-not-say"), type: "predefined" },
            ];

            if (!defaultValue) return options;

            const defaultValueExist = options.some(({ name }) => name === defaultValue);
            if (!defaultValueExist) {
                return [
                    { name: defaultValue, type: "predefined" },
                    ...options,
                ];
            }

            return options;
        },
        [defaultValue]
    );

    const { gender } = getFormValues();
    const [useCustomGender, setUseCustomGender] = useState(false);

    useEffect(() => {
        const selectedGender = genderOptions.find(({ name }) => name === gender);

        if (!selectedGender) return;

        clearErrors("custom_gender");
        setValue(
            "custom_gender",
            selectedGender.type === "predefined" ? selectedGender.name : ""
        );
        setUseCustomGender(selectedGender.type === "custom");

        validate("gender");
    }, [gender]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Controller
                name="gender"
                control={control}
                render={({field: { ref, value, ...restFieldProps }, fieldState: { error }}) => {
                    const errorMessage = error?.message || !!serverErrorMessage;

                    return (
                        <FormSelect
                            selectRef={ref}
                            label={t("gender")}
                            error={errorMessage}
                            options={genderOptions}
                            value={value}
                            onClose={() => validate("gender")}
                            styledFormSelectProps={{
                                borderColor: errorMessage ? colors.red : "transparent",
                                textColor: !value?.length
                                    ? RGBAColors.lightBlackWithOpacity(0.38)
                                    : colors.lightBlack,
                            }}
                            {...restFieldProps}
                        />
                    );
                }}
            />
            {useCustomGender && (
                <Controller
                    name="custom_gender"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput
                            inputRef={ref}
                            type={FormInputType.TEXT}
                            placeholder={t("enter-gender")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
            )}
        </Box>
    );
}
