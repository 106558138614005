import React, { useState } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ChevronDownIcon, ChevronUpIcon } from "assets/icons";
import { IStyledFormSelectProps, StyledFormSelect } from "./components/StyledFormSelect";
import Text from "core/components/Text";
import { Box } from "@material-ui/core";

export interface ISelectOption {
    name: string;
}

export interface ISelectChangeEvent {
    name?: string;
    value: string | string[];
    event: Event | React.SyntheticEvent;
}

export interface IFormSelectProps {
    value: string | string[];
    options: Array<ISelectOption>;
    onChange: (event: React.ChangeEvent<ISelectChangeEvent>) => void;
    name?: string;
    selectRef?: React.RefCallback<HTMLSelectElement> | React.Ref<HTMLSelectElement>;
    label?: string;
    error?: string | boolean;
    styledFormSelectProps?: IStyledFormSelectProps;
    onOpen?: () => void;
    onClose?: () => void;
    multiple?: boolean;
}

export const FormSelect = ({
    value,
    options,
    onChange,
    name,
    selectRef,
    label,
    error,
    styledFormSelectProps,
    onOpen,
    onClose,
    multiple = false,
}: IFormSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onSelectOpen = () => {
        setIsOpen(true);
        onOpen && onOpen();
    }

    const onSelectClose = () => {
        setIsOpen(false);
        onClose && onClose();
    }

    const renderValue = (value: string | string[]) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return 'Fahrschüler wählen lassen';
            }
            return value.join(', ');
        } else {
            if (!value?.length) {
                return null;
            }
        }

        return value;
    }


    return (
        <Box
            display="grid"
            flexDirection="column"
            gap="4px"
            width={1}
        >
            {label && <Text size="small">{label}</Text>}
            <StyledFormSelect {...styledFormSelectProps} >
                <Select
                    ref={selectRef}
                    value={value}
                    name={name}
                    onChange={onChange}
                    displayEmpty
                    IconComponent={isOpen ? ChevronUpIcon : ChevronDownIcon}
                    onOpen={onSelectOpen}
                    onClose={onSelectClose}
                    renderValue={renderValue}
                    multiple={multiple}
                >
                    {options.map(({ name }: ISelectOption, index) => (
                        <MenuItem
                            key={name + index}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </StyledFormSelect>
            {(typeof error === "string") && (
                <Text size="small" color="red">{error}</Text>
            )}
        </Box>
    );
}
